@import '../../styles/index.scss';

.auth {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 90vh;
    flex-grow: 1;
    width: 100vw;
    

    &-wrapper{
        height: 90vh;
    }
    
    &__content {
        z-index: 10;
    }
    &__tile {
        width: calc(var(--auth__body-width) *.65) !important;
        // background-color: gold;
        // display: flex;
    }

    &__error {
        display: flex;
        color: $invalid;
        align-items: center;
        justify-content: center;
    }

    &__btn-wrapper{
        margin: $margin-02 $margin-00;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
    }

    &__body {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content:space-around;
        margin: $margin-02;
    }

    &__form {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    &__btn-styles {
        // border-radius: 1px;
    }
}

.auth-ins {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 90vh;
    flex-grow: 1;
    width: 100vw;
    

    &-wrapper{
        height: 90vh;
    }
    
    &__content {
        z-index: 10;
        width: 35vw;
    }
    &__tile {
        width: calc(var(--auth__body-width) *.65) !important;
        // background-color: gold;
        // display: flex;
    }

    &__error {
        display: flex;
        color: $invalid;
        align-items: center;
        justify-content: center;
    }

    &__btn-wrapper{
        margin: $margin-02 $margin-00;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
    }

    &__body {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content:space-around;
        margin: $margin-02;
    }

    &__form {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    &__btn-styles {
        // border-radius: 1px;
    }
}
.animation{
    width: var(--auth__anim-width);
    height: var(--auth__anim-height);
    position: absolute;
    left: -8vh;
    top: 10vh;
    z-index: 0;
}


@media screen and (max-width: 767px)
  {
    .auth {
        // background-color: rgb(170, 0, 255);
        
        &__tile {
            width: var(--auth__body-width) !important; /* Update the width to your desired value */
          }
    }
    

  }

  @media only screen and (min-device-width: 320px) and (max-device-width: 812px) and (-webkit-device-pixel-ratio: 3) {
    /* Styles for iPhones */
    .auth {

        justify-content: flex-start;
        
        // &__content {

        // }
        
        &__tile {
            width: var(--auth__body-width) !important; /* Update the width to your desired value */
            // height: var(--auth__body-height) !important; /* Update the width to your desired value */
          }
    }
    .animation{
        width: var(--auth__anim-width) ;
        height: var(--auth__anim-height) ;
        position: absolute;
        left: -2vh;
        top: 30vh;
        z-index: 0;
    }
    
  }

:root {
    --auth__body-width: 80vw;
    --auth__body-height: 40vh;
    --auth__anim-width: 90vw;
    --auth__anim-height: 90vw;
}