@import '../../../styles/index.scss';

.de-identified-patient {


    margin: 10px 0;

    &__body{
        padding: 10px 0;
    }

    &__cohorts-wrapper{
        display: flex;
    }

}