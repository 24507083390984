@import '../../../styles/index.scss';

.reviewer-participant-task {

    width: 100%;
    margin: 0 3em;
    overflow: scroll;

    &::-webkit-scrollbar{
        display: none;
    }
}