@import '../../../styles/index.scss';

.protocol-file-loader {
    // background-color: aqua;
    max-height: 600px;
    overflow: scroll;
    padding: 2px;


    &__list-wrapper{
        display: grid;
        grid-gap: 1rem;
        grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    }

    &__breadcrumb-wrapper{
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }

    &__icon{
        color: $primary !important;
        margin-right: 5px;
        
        &:hover{
            color:  $secondary;
        }
    }

    &__item{
        height: 100px;
        // inline-size: 150px;
        overflow-wrap: break-word;
    }

}
