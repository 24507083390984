@import '../../../styles/index.scss';

.news-item {
    // padding: 5px 2px;
    overflow: hidden;

    &__tile {
        padding: 1px 2px;
        margin: 5px;
        min-height: 40px;
    }

    &__tile-grid{
        margin-top: 0.25em !important;
        height: 100%;
    }

    &__action-header {
        border-bottom: 1px solid $gray-10;
    }

    &__no-overflow{
        overflow: hidden;
    }
    &__icon {

        &:hover {
            color: $secondary !important;
        }
    }
}