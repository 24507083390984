@import '../../styles/index.scss';

.icon-button {

    cursor: pointer !important;
    margin-left: $space-00;
    margin-right: $space-00;
    background-color: transparent !important;
    border: none !important;
    pointer-events: auto;

    &__ghost {
        color: $gray;
        &:hover{
            color: $primary !important;
        }
    }

    &__primary{
        color: $primary;
        &:hover{
            color: $secondary !important;
        }
    }

    &__secondary{
        color: $secondary;
        &:hover{
            color: $primary !important;
        }
    }




}