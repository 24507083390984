@import '../../../styles/index.scss';

.app-bar {

  &__logo {
    color: $primary !important;
  }
}

.hsg-menu{
  justify-content: flex-end !important;
}

.MuiAppBar-colorDefault {
  background-color: aqua;
}

.MuiAppBar-colorPrimary {
  background-color: rgb(209, 255, 24);
}


.css-1cjrmu8-MuiPaper-root-MuiAppBar-root {
  background-color: $background !important;
  box-shadow: $shadow-light !important;
  border-bottom: 0px solid $gray-20;
  // margin-bottom: 10px;
}

.css-s4tmk9 {
  background-color: $background !important;
  box-shadow: $shadow-light !important;
  border-bottom: 0px solid $gray-20;
  // margin-bottom: 10px;
}

.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root {
  padding: 0px 6px !important;
}

a {
  color: $text-col !important;
  text-decoration: none !important;
  transition: color 300ms ease !important;
  padding: 6px 10px;
  margin: 0 !important;
  // font-size: 1em;
  font-size: 15px;
  font-family: $font-primary;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 2px;

  &:hover {
    color: darken($primary, 10%) !important;
  }
}

.nav-link {
  background: transparent;
  border: none;
  color: $text-col !important;
  text-decoration: none !important;
  transition: color 300ms ease !important;
  padding: 10px;
  margin: 0 !important;
  // font-size: 1em;
  font-size: 15px;
  font-family: $font-primary;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 2px;
  cursor: pointer;

  &__active{
    color: $primary !important;
  }

  &:hover {
    color: darken($primary, 10%) !important;
  }
}

.css-1t6c9ts a {
  color: $text-col ;
  text-decoration: none !important;
  transition: color 300ms ease !important;
  padding: 6px 10px;
  margin: 0 !important;
  // font-size: 1em;
  font-size: 15px;
  font-family: $font-primary;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 2px;


  &:hover {
    color: darken($primary, 10%) !important;
  }
}

.underline {
  color: black !important;
}

.selected {
  color: $primary !important;
}


.appbar-links {
  max-width: 70vw;
  overflow: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
}

@media (max-width: 768px) {

  .header__end-profile{
    display: none;
  }

}