@import '../../../../../styles/index.scss';

.dropdown-menu-item {
    list-style: none;
    font-size: large;
    z-index: 9999;
    min-width: 10rem;
    padding: 0.5rem 0;
    list-style: none;
    background-color: $background;
    border-radius: 0.5rem;
    display: none;

}

.dropdown-menu-item.show {
    display: block;
  }