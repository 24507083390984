@import '../../styles/index.scss';

.multi-panels {
    display: flex;
    flex-direction: row;

    .panel {
        display:flex;
        flex:1;
        flex-basis:0;
        max-width: 100% !important
    }
    
    .left-panel {
        flex-basis: 0;
        flex:1;
    }
    
    .right-panel {
       flex-basis: 0;
       flex:0 !important;
       
       &.active {
        flex: 1 !important;
        display: flex;
        }
       
        
       &-content{
            // display: inherit;
            flex-direction: inherit;
            flex:1;
            display: none;

            &.active {
                flex: 1;
                display: flex;
            }
            &.hidden {
                flex: 0;
                display: none;
    
            }
       }
      }
}

.multi-panels__toggle-button {
    background-color: transparent;
    border: none;
    padding: 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #333;
    font-size: 24px;
    transition: transform 0.3s, opacity 0.3s;

    &:hover {
      opacity: 0.8;
    }

    &.active {
      transform: rotate(180deg);
    }
}