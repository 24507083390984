@import '../../../styles/index.scss';

.audits {
    padding: 1em 8%;
    flex-direction: column;
    display: flex;
    flex: 1;
    
    &__header{
        &-title{
            display: flex;
            min-width: fit-content;
        }
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        padding: 0 1rem;
    }


    &__empty{
        height: 50vh;
        display: grid;
        place-content: center;
        width: 100%;
    }

}