@import '../../styles/index.scss';

.patient-survey-switch {

    &__item{
        display: flex;
        align-items: center;
    }

    &__btn {
        padding: 1em;
        background-color: $white ;
        box-shadow: $shadow;
        margin-bottom: .5em;
        width: 100%;
        cursor: pointer;
    }

}