@import '../../../styles/index.scss';

.care-pathway-tracker {
    width: 90vw;
    margin: 0 auto;
    height: 80vh;
    overflow: scroll;
    &::-webkit-scrollbar {
        display: none;
    }

    &__header {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__empty {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    &__table-container {
        width: 100%;
        margin: 0 auto;
        overflow: scroll;
        height: 68vh;

        &::-webkit-scrollbar {
            display: none;
        }
    }

    &__table {
        // overflow: scroll;
        background: white;
        // width: max-content;
        width: 100%;
        border-spacing: 0px;
        table-layout: auto;

        &::-webkit-scrollbar {
            display: none;
        }

        th {
            height: 100%;
            font-family: $font-primary;
            font-size: $text-00;
            text-transform: uppercase !important;
            font-weight: 400;
            letter-spacing: 1px;
            border-bottom: 3px solid $primary-20;
        }

        td {
            cursor: pointer;
            min-height: 80px;
            padding: 10px 0px;
            border-bottom: 1px solid $primary-20;
        }

        tr {
            background: white;
            // margin: 10px 0px;

        }

        &__row:hover {
            background-color: $primary-20;
        }

        &__th-small {
            width: 20%;
        }

        &__th-large {
            width: 50%;
        }
    }
}

// .care-pathway-tracker {
//     padding: 1em;

//     &__header {
//         width: 100%;
//         display: flex;
//         align-items: center;
//         justify-content: space-between;
//     }

//     &__empty {
//         display: flex;
//         flex-direction: column;
//         align-items: center;
//         justify-content: center;
//     }

//     &__table-container {
//         overflow-x: auto; /* Add horizontal scrollbar when table width exceeds container */
//     }

//     &__table {
//         border-spacing: 0px;
//         table-layout: auto; /* Allow table to determine column widths based on content */
//         width: 100%; /* Set table width to 100% */
//         padding: 0 $space-03;
//         padding-bottom: 10px;

//         &::-webkit-scrollbar{
//             display: none;
//         }

//         th {
//             height: 100%;
//             font-family: $font-primary;
//             font-size: $text-00;
//             text-transform: uppercase !important;
//             font-weight: 400;
//             letter-spacing: 1px;
//             border-bottom: 3px solid $primary-20;
//         }

//         td {
//             cursor: pointer;
//             word-wrap: break-word; /* Allow long content to break into multiple lines */
//             min-height: 80px;
//             padding: 10px 0px;
//             border-bottom: 1px solid $primary-20;
//         }

//        tr {
//             background: white;
//             // margin: 10px 0px;

//         }

//         &__row:hover {
//             background-color: $primary-20;
//         }

//         &__th-small {
//             width: 20%; /* Set width for smaller columns */
//         }

//         &__th-large {
//             width: 50%; /* Set width for larger columns */
//         }

//         &__table-body {
//             display: block; /* Enable vertical scrolling for the table body */
//             overflow-y: auto; /* Add vertical scrollbar when content exceeds container height */
//             max-height: 700px; /* Set max height for the table body */

//             &::-webkit-scrollbar{
//                 display: none;
//             }
//         }
//     }
// }