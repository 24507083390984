@import '../../styles/index.scss';

.epilepsy-table {

    height: 77vh;
    overflow: scroll;

    &::-webkit-scrollbar {
        display: none;
    }

    &__btn {
        padding: 1em;
        background-color: $white ;
        box-shadow: $shadow;
        margin-bottom: .5em;
        width: 100%;
        cursor: pointer;
    }

    &__header {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__wrapper {
        // height: 67vh;
        height: 95%;
        overflow: scroll;

        &::-webkit-scrollbar{
            display: none;
        }
    }

    &__table {
        background: white;
        border-spacing: 0px;
        table-layout: fixed;
        min-width: 100%;
        padding: 0 $space-03;
        padding-bottom: 10px;

        tbody {
            overflow-y: auto;
            height: 200px;
        }

        td,
        th {
            overflow: hidden;
            padding: .75rem .5rem;
            text-align: center;
        }

        &__row:hover {
            background-color: $primary-20;
        }

        th {
            height: 100%;
            font-family: $font-primary;
            font-size: $text-00;
            text-transform: uppercase !important;
            font-weight: 400;
            letter-spacing: 1px;
            border: 1px solid $primary-20;

        }

        td {
            font-family: $font-secondary;
            font-size: .875em;
            font-weight: 300;
            letter-spacing: 1px;
            cursor: pointer;
            border: 1px solid $primary-20;
        }
    }

    // &__table {
    //     background: white;
    //     border-spacing: 0px;
    //     table-layout: fixed;
    //     min-width: 100%;
    //     padding: 0 $space-03;
    //     padding-bottom: 10px;

    //     tbody {
    //         display: block; /* Add this to make tbody a block container */
    //         flex: 1; /* Allow it to grow and fill remaining space */
    //         border-collapse: collapse;
    //         border-spacing: 0;
    //         table-layout: fixed;
    //         background: white;
    //     }

    //     td,
    //     th {
    //         overflow: hidden;
    //         padding: .75rem .5rem;
    //         text-align: center;
    //     }

    //     .epilepsy-table__row:hover {
    //         background-color: $primary-20;
    //     }

    //     th:first-child {
    //         flex: 0 0 auto; /* Don't allow resizing */
    //         width: 25vw; /* Set the desired width */
    //         overflow: hidden; /* Hide content that overflows */
    //         position: sticky;
    //         left: 0;
    //         z-index: 2;
    //     }

    //     th {
    //         height: 100%;
    //         font-family: $font-primary;
    //         font-size: $text-00;
    //         text-transform: uppercase !important;
    //         font-weight: 400;
    //         letter-spacing: 1px;
    //         border: 1px solid $primary-20;
    //     }

    //     td {
    //         font-family: $font-secondary;
    //         font-size: .875em;
    //         font-weight: 300;
    //         letter-spacing: 1px;
    //         cursor: pointer;
    //         border: 1px solid $primary-20;
    //     }
    // }


}

.left {
    text-align: left !important;
}

.diagnosis-row__checkbox {
    display: flex;
    align-items: center;
}

.good {
    background-color: rgba(27, 195, 133, 0.25) !important;
}

.medium {
    background-color: rgba(242, 159, 76, 0.25) !important;
}

.bad {
    background-color: rgba(179, 20, 76, 0.25) !important;
}

.exclamation {
    color: red;
}

.seizure-th {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    justify-content: space-between;
}


.exclamation {
    color: red;
}

.survey-scores-info {
    background-color: $primary-10;
    padding: 1em;
}

.localization-row {
    display: flex;
    align-items: center;
}