@import '../../styles/index.scss';

.dropdown {
    width: 100%;
}

.WithStyles\(ForwardRef\(InputBase\)\)-input-2 {
    margin-left: 0px !important;
    margin-right: 0px !important;
}

.css-pe3ddf.css-pe3ddf.css-pe3ddf {
    background-color: white !important;
}

// .css-kbarkp-MuiInputBase-root{
//     box-shadow: $shadow !important;
// }

// .css-us04y8-MuiInputBase-root{
//     box-shadow: $shadow !important;
// }

// .css-13t4mpd-MuiInputBase-root{
//     box-shadow: $shadow !important;
//     background-color: transparent !important;
// }