@import '../../../styles/index.scss';

.drop-zone {
    width: 100%;
    // margin: 0 10px 0 0 ;

    &-wrapper{
		// display: grid;
        // grid-template-rows: 1fr auto;
        background-color: $filedrop-disabled-col;
        // position: relative;
        // border: 2px dashed $secondary-40;
        // padding: 1rem;
        // border-radius: 16px;
        // max-height: 850px;        
        // min-height: 350px;
        // overflow: scroll;
        // overflow-y: auto;
        // overflow-x: hidden;
        // overflow-wrap: break-word;

        position: relative;
        border: 0px dashed rgba(160, 159, 128, 0.4);
        padding: 1rem;
        border-radius: 16px;
        max-height: 350px;        
        min-height: 350px;
        overflow: scroll;
        overflow-y: auto;
        overflow-x: hidden;
        overflow-wrap: break-word;

        &:hover {
            outline: none;
            box-shadow: $inset-shadow-light;
        }
	}

    // &-active{
    //     background-color: $filedrop-active-col;
    // }

   

    &:focus {
		outline: none;
        
	}

    &:hover {
		outline: none;
        
	}
}
