@import '../../styles/index.scss';

.audit-dicom {

    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: normal;
    align-items: stretch;
    align-content: stretch;
    max-height: 100%;

    &__pannel1 {
        width: 30vw;
        max-height: 60vh;
    }

    &__pannel2 {
        flex: 1;
        margin-left: 10px;
        border-radius: 5px;
        // border: 1px solid $primary-40;
    }

    &__top-panel {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: normal;
        align-items: stretch;
        align-content: stretch;
        max-height: 60vh;
        flex-grow: 1;
        flex-shrink: 0;
        // width: 100%;
        padding: 0 !important;
    }

    &__action-row {
        // background-color: $primary-10;
        // border: 2px solid $primary;
        // background-color: $secondary-05;
        // margin-top: 10px;
        // width: 100%;
        // height: 100%;

        // display: block;
        flex-grow: 0;
        flex-shrink: 1;
        align-self: auto;
        order: 0;
        height: 100%;
        overflow: hidden;
        flex-basis: 100%;
    }

    &__img-action{
        display: flex;
        align-items: center;
        margin: 10px ;
    }

    &__text{
        font-family: $font-primary;
        font-size: $text-01;
        text-transform: uppercase !important;
        font-weight: 600;
        letter-spacing: 1px;
    }


}


.radio-list {
    display: flex;
    flex-direction: column;
    // height: 90%;
    // justify-content: space-evenly;
}

.radio-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    cursor: pointer;

    &:hover {
        font-weight: 600;
    }
}

.radio-icon {
    margin-right: 5px;
}

.radio-item input[type="radio"] {
    margin-right: 5px;
}

.radio-item label {
    font-weight: normal;
}

.audit-dicom__action-row-box::-webkit-scrollbar {
    display: none;
}

.audit-dicom__action-row-reco::-webkit-scrollbar {
    display: none;
}