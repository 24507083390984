@import '../../../styles/index.scss';



.parent {
  display: grid;
grid-template-columns: repeat(12, 1fr);
grid-template-rows: repeat(3, max-content);
  grid-column-gap: 0px;
  grid-row-gap: 15px;
  grid-auto-rows: max-content;
}

// .div1 {
//   // grid-area: 1 / 1 / 2 / 4;
//   // grid-area: 1 / 1 / 2 / 4;
//   grid-area: 1 / 1 / 2 / 7;
// }

// .div2 {
//   // grid-area: 2 / 1 / 3 / 4;
//   grid-area: 1 / 7 / 2 / 9;
// }

// .div3 {
//   // grid-area: 1 / 4 / 2 / 6;
//   // grid-area: 1 / 4 / 2 / 7;
//   grid-area: 1 / 9 / 2 / 11;
// }

// .div4 {
//   // grid-area: 1 / 6 / 2 / 8;
//   // grid-area: 1 / 7 / 2 / 10;
//   grid-area: 1 / 11 / 2 / 13;
// }

// .div5 {
//   // grid-area: 1 / 8 / 2 / 10;
//   grid-area: 1 / 10 / 2 / 13;
// }

// .div6 {
//   grid-area: 1 / 10 / 2 / 12;
// }

// .div7 {
//   grid-area: 2 / 4 / 3 / 8;
// }

// .div8 {
//   grid-area: 2 / 8 / 3 / 12;
// }

// .div9 {
//   grid-area: 3 / 1 / 4 / 12;
// }

// .div1 { grid-area: 1 / 1 / 2 / 7; }
// .div2 { grid-area: 1 / 7 / 2 / 9; }
// .div3 { grid-area: 1 / 9 / 2 / 11; }
// .div4 { grid-area: 1 / 11 / 2 / 13; }
// .div5 { grid-area: 2 / 1 / 3 / 5; }
// .div6 { grid-area: 2 / 5 / 3 / 9; }
// .div7 { grid-area: 2 / 9 / 3 / 13; }
// .div8 { grid-area: 3 / 1 / 4 / 13; }

.div1 { grid-area: 1 / 1 / 2 / 7; }
.div2 { grid-area: 1 / 7 / 2 / 9; }
.div3 { grid-area: 1 / 9 / 2 / 11; }
.div4 { grid-area: 1 / 11 / 2 / 13; }
.div5 { grid-area: 2 / 1 / 3 / 4; }
.div6 { grid-area: 2 / 4 / 3 / 8; }
.div7 { grid-area: 2 / 8 / 3 / 13; }
.div8 { grid-area: 3 / 1 / 4 / 13; }

.study-dash {

  &__img-info {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    border-bottom: 1px solid #e5e7eb;
  }

  padding: 0 4em;

  &__info {
    // margin: auto;
    width: 100%;
    // display: flex;
    // flex-direction: column;
    // align-items: center;
    // justify-content: center;
    height: 100%;
    display: grid;
    place-content: center;
  }

  &__graph {
    height: 100%;
    width: 100%;
    display: grid;
    place-content: center;
  }

  &__tile {
    min-height: 800px;
  }

  &__data-tiles {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__data-tile {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__site-item {
    border-bottom: 1px solid #e5e7eb;
    margin: 0 1em !important;
  }

  &__center {
    display: grid;
    place-content: center;
  }

}

.study-dashboard {
  width: 90vw;
  min-height: 82vh;
  margin: 0 auto;
  align-self: center;
  justify-content: center;

  &__header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &__layout {
    min-height: 78vh;
  }

  &__loader {
    width: 450px;
    height: 70vh;
    opacity: 90%;
    flex: 1 1;
    align-self: center;
    display: flex;
    justify-content: center;

  }
}

.study-tile {
  min-height: 100px;
  height: 100%;
  margin-bottom: 10px;

  &__id {
    background-color: $secondary;
    height: 200px;
  }

  &__1 {
    // background-color: $secondary;  
  }

  &__2 {
    // background-color: $tertiary-20;

  }

  &__feed {
    height: 230px;

  }
}

.study-metric {}

.chart-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-gap: 20px;
}

.chart-item {
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.chart-item-large {
  grid-column: 1 / -1;
  grid-row: 1 / span 2;
}

.chart-item-medium {
  grid-row: 3 / span 1;
}

.chart-item-medium:nth-child(2n) {
  grid-column: 2 / -1;
}

.chart-item-small {
  grid-row: 4 / span 1;
}

.chart-item-small.chart-item-square {
  padding-top: 100%;
  position: relative;
}

.chart-item-small.chart-item-square .recharts-responsive-container,
.chart-item-small.chart-item-square .recharts-surface {
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
}
