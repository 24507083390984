@import '../../styles/index.scss';

// .conference-info-column {

// }

.open-conference {

    margin: 10px;
    border-radius: 5px;
    background-color: white;
    box-shadow: $shadow-light;
    width: 100%;
    padding: 0 10px;
    overflow: scroll;

    &::-webkit-scrollbar {
        display: none;
    }

    &__header {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
    }

    &__cases-list {
        height: 40vh;
        overflow: scroll;
        background-color: $primary-10;
        border: 1px dashed $primary-20;

        &::-webkit-scrollbar {
            display: none;
        }

        .empty {
            height: 100%;
            width: 100%;
            display: grid;
            place-content: center;
        }
    }

    &__patient {
        padding: 10px;
        margin: 5px;
        background-color: #fff;
        box-shadow: $shadow-light;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    &__patient-pending {
        padding: 10px;
        margin: 5px;
        background-color: $gray-10;
        border: 1px dashed $gray-60;
        box-shadow: $shadow-light;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
}