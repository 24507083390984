@import '../../../styles/index.scss';

.log-item {

    margin: 10px 0;

    &__body{
        padding: 10px 0;
    }


}