body {
  font-family: 'Lato', sans-serif;
  color: $black;
  font-size: 16px;
  margin: 0;
}

a {
  color: $blue;
  text-decoration: none;
  transition: color 300ms ease;

  &:hover {
    color: darken($blue, 10%);
  }
}

footer{
  background-color: $background;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}
.text-left {
  text-align: left;
}

.h-100 {
  height: 100%;
}

.w-100 {
  width: 100%;
}

.error-wrapper{
  width: 70vw;
  height: 50vw;
  display: grid;
  place-content: center;
}

.icon-btn{
  color: $primary;
  cursor: pointer !important;
  // margin-left: $space-00;
  // margin-right: $space-00;
  margin: 0 !important;
  background-color: transparent !important;
  border: none !important;
  z-index: 100;
  pointer-events: auto;
}

.icon-btn:hover {
  color: $secondary;
  z-index: 100;
}
.border-heading{
  border-bottom: .5px solid $gray;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.margin-auto {
  margin-left: auto;
  margin-right: auto;
}

.m-0{
  margin: 0 !important;
}
.p-0{
  padding: 0 !important;
}

.d-flex{
  display: flex;
}

.flex-center{
  justify-content: center;
}

.d-flex-row{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.d-flex-col{
  display: flex;
  flex-direction: column;
}

.d-flex-row-center{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.no-overflow{
  overflow: hidden;
}

.grow{
  flex-grow: 1;
}

.super-centered{
  width: 100%;
  display: grid;
  place-content: center;
}

.indent {
  margin-left: $space-04;
}

._center{
  display: flex;
  justify-content: center;
}
._left{
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

._right{
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
._make-row{
  display:flex;
  flex-direction: row;
  align-items: center;
}

._apart {
  display: flex;
  justify-content: space-between;
}
._align-item {
  display: flex;
  align-items: center;
}

.btn-row{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.d-flex{
  display: flex;
  align-items: center;
}

.flex-row{
  flex-direction: row;
}

.align_right{
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.yellow-text{
  color: $yellow
}

.green-text{
  color: $green !important
}

.blue-text{
  color: $blue !important
}

.gray-text{
  color: $gray !important
}

.red-text{
  color: $red !important
}

.orange-text{
  color: $orange !important
}

.danger-btn{
  background-color: transparent;
  border: none;
  color: $red;
  margin: 0;
}