@import '../../styles/index.scss';

.session-survey-item {
    border: 1px dotted $gray-60;
    box-shadow: none;
    padding: 0.75em;
    border-radius: 5px;
    flex: 1 1;
    margin: 0.5em 1px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    cursor: pointer;
}

.session-survey-item-active {
    border: 1px dotted $gray-60;
    background-color: $primary-20;
    box-shadow: none;
    padding: 0.75em;
    border-radius: 5px;
    flex: 1 1;
    margin: 0.5em 1px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;

}