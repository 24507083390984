@import '../../../styles/index.scss';

.study-item {
  &__root {
    margin: 15px;
    margin-bottom: 1em;
    padding: 0px;
    // background-color: rgb(180, 23, 247);
  }

  &__wrapper {
    // background-color: rgb(23, 247, 120);
    display: flex;
    justify-content: space-between;
    padding: $space-03;
    // flex-direction: 'column';
  }

  &__body {
    width: 100%;
    padding: $margin-01;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &__title {
      font-weight: $semibold;
      font-size: $text-01;
      padding-bottom: $space-02;
    }

    &__description {
      padding-left: $space-04;
      font-weight: $thin;
      font-size: $text-005;
    }
  }

  &__actions {
    //background-color: rgb(247, 23, 210);
    // width: '50%';
    padding: $margin-02;
    display: flex;
    flex-direction: column;
  }
}

.bold {
  font-weight: 600 !important;
}

.h-space-20{
  margin-right: 20px;
}

.pause-wrapper{
  height: 100%;
  display: grid;
  place-content: center;
}