@import '../../styles/index.scss';

.response-table {

    // overflow: scroll;
    background: white;
    border-spacing: 0px;
    // width: max-content;
    table-layout: fixed;
    min-width: 100%;
    padding-bottom: 10px;

    // tbody {
    //     overflow-y: auto;
    //     height: 200px;
    // }

    thead {
        // background-color: $gray-10;
        background-color: $primary;
        padding: 10px 0;
    }

    // &__row:hover {
    //     background-color: $primary-20;
    // }

    th {
        text-align: left;
        overflow: hidden;
        padding: .75rem .5rem;
        text-align: left;
    }

    td {
        border-bottom: 1px solid $primary;
        // border-bottom: 1px solid $gray-10;
        padding: 10px;
    }



}