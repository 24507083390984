@import '../../styles/index.scss';

.star {
    cursor: pointer;
    color: #ccc;
    font-size: 1rem;
    cursor: pointer;

    &__filled {
        cursor: pointer;
        font-size: 1rem;
        color: $secondary;
    }
}

.star-lg {
    font-size: 2rem;
    cursor: pointer;
    color: #ccc;
    cursor: pointer;

    &__filled {
        cursor: pointer;
        font-size: 2rem;
        color: $secondary;
    }
}

.image-rating {

    &__header {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__toggle-btn {
        font-size: $text-01;
        margin: 0 5px;
        border-radius: 2px;
        font-family: $font-primary;
        border-style: none;
        cursor: pointer;
        transition: background-color 300ms ease;
        font-weight: 600;
        display: flex;
        align-items: center;
        padding: $space-01 $space-04;
        letter-spacing: 1px;
        background-color: $light-gray;

        &:hover {
            background-color: $secondary;
            border-color: $secondary;
        }
    }

    &__toggle-btn-active {
        font-size: $text-01;
        border-radius: 2px;
        margin: 0 5px;
        font-family: $font-primary;
        border-style: none;
        cursor: pointer;
        transition: background-color 300ms ease;
        font-weight: 600;
        display: flex;
        align-items: center;
        padding: $space-01 $space-04;
        letter-spacing: 1px;
        color: white;
        background-color: $secondary;
    }
}