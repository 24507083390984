@import '../../../styles/index.scss';

.protocol-file-display {

    &__wrapper{
        display: grid;
        grid-gap: 1rem;
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
        max-height: 600px;
        overflow: auto;
    }
    &__item {
        // height: 150px;
        inline-size: 300px;
        overflow-wrap: break-word;
    }

}