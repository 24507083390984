@import '../../../styles/index.scss';

.treatment-plan {

    &__item {
        display: flex;
        // background-color: $primary-05;
        // margin: 1em;
        height: 100%;
        margin-bottom: .5em;

        &-title {
            width: 200px;
            display: flex;
            height: auto;
            justify-content: center;
            align-items: center;
        }
    }

}

.table-container {
    overflow-x: auto;
}

.treatment-plan__table {
    border-collapse: collapse;
    width: 100%;
}

.treatment-plan__table th {
    padding: 12px 16px;
    min-width: 100px;
    white-space: nowrap;
    text-align: left;
    border: none;
    // background-color: $gray-10;
}

.treatment-plan__table td {
    padding: 12px 25px;
    min-width: 200px;
    white-space: nowrap;
    text-align: left;
    border: none;
}