@import '../../styles/index.scss';

.badge {

    border-radius: 16px;
    display: grid;
    place-content: center;
    padding: 5px 16px;
    width: 100%;
    // min-width: fit-content;
    // max-width: fit-content;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &-blue{
        background-color: $secondary;
    }

    &-dark-blue{
        background-color: $primary;
    }

    &-gray{
        background-color: $gray;
    }

    &-green {
        background-color: $green;
    }

    &-yellow {
        background-color: $yellow;
    }

    &-red {
        background-color: $red;
    }

    &-orange {
        background-color: $orange;
    }

}