@import '../../../styles/index.scss';

.cohort-item {
    
    display: flex;
    align-items: center;
    min-height: 125px;

    &__wrapper{
        // justify-content: flex-start;
        // flex-direction: column;
    }
    
    &__footer{
        display: flex;
    }

    &__trash-wrapper{
        padding: 5px;
    }

    &__outer-wrapper{
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-between;
    }

    &__body{
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
        padding: 5px;
        width: 100%;
    }

    &__icon-wrapper{
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: fit-content;
        margin: 1em;
    }

    &__icon{
        color: $primary !important;
    }

    &__star{
        color: $yellow !important;
    }
    

}

.col-1{
    color: $secondary !important;
}
.col-2{
    color: $primary !important;
}
.col-3{
    color: $tertiary !important;
}