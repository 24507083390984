@import '../../styles/index.scss';

.patient-info-popper {

    &__btn {
        background-color: transparent;
        border: none;
        display: flex;
        align-items: center;
        cursor: pointer;
    }

    &__icon-active {
        color: $primary !important;
    }

    &__body{
        width: 300px;
        padding: 10px;
    }

}

.patient-label .icon-btn {
    font-size: 1.4em;
    margin: auto 0;
    margin-left: 10px;
    cursor: pointer;
}

.poppover-li {
    list-style-type: none;
    line-height: 30px;
    letter-spacing: .5px;
    text-transform: uppercase;
    font-weight: 500;
    font-size: $text-00;
    color: $gray;
  }

.patient-info {
    text-transform: capitalize;
    font-weight: 300;
    color: $gray;
}
