@import '../../../styles/index.scss';

.participant_wrapper {
    // background-color: yellow;
    flex:1;
    display: flex;
    flex-direction: column;
}

.participantflex {
    display: flex;
    flex-direction: row;
    transition: flex-basis 5s ease;
    overflow: hidden;
    width: 100vw;
    // height: $workspace_vh;
    flex:1;
    // background-color: beige;
    
    &.show-panel3 {
        .panel1 {
            flex: 2;
        }

        .panel2 {
            display: none;
            
        }

        .panel3 {
            flex: 1;
            display: flex;
        }
    }

    .panel1 {
        flex: 1;
        flex-basis: 0;
        /* Styles for the left column */
        // background-color:bisque;
    }

    .panel2 {
        flex: 1;
        flex-basis: 0;
        /* Styles for the right column */
        // background-color:rgb(156, 255, 229);
        margin: 10px !important;
        display: flex;
        flex-direction: column;
    }

    .panel3 {
        display: none;
        flex: 0;
        flex-basis: 0;
        /* Styles for the right column */
        // background-color:rgb(255, 156, 230)
    }

}

.participant {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 5px;
    grid-row-gap: 20px;
    overflow: hidden;
    transition: grid-template-columns 0.5s ease-in-out;
    margin: 0 1em;

    &__scan-menu {
        position: absolute;
        right: 27px;
        top: 78px;
        display: flex;
        align-items: center;
    }

    &__scan-btn {
        font-size: $text-01;
        font-family: $font-primary;
        border-style: none;
        cursor: pointer;
        transition: background-color 300ms ease;
        font-weight: 600;
        background: transparent;
        border: none;
        color: $primary !important;
        display: flex;
        align-items: center;
    }

    &__scan-icon {
        color: $primary !important;
    }

    &__protocol-wrapper {
        height: 78vh;
        overflow: scroll;
        transition: width 0.3s ease-in-out;
        // background-color: yellow;
    }

    &__label-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__btn-crumbs {
        cursor: pointer;
        // display: flex;
        // align-items: center;
        display: -webkit-box;
    }

    &__breadcrumb {
        padding-bottom: 3px;
        text-decoration: underline;
        text-transform: uppercase;
        letter-spacing: 2px;
        color: $gray-80;
        font-family: $font-primary;
        // font-size: $text-00;
        font-size: x-small;
    }

    &__breadcrumb-wrapper {
        margin-bottom: 12px;
        display: flow-root;
    }

    &__breadcrumb-divider {
        text-transform: uppercase;
        letter-spacing: 2px;
        color: $gray-80;
        font-family: $font-primary;
        font-size: $text-00;
    }

    &__protocol-scroll-section {
        height: 75vh;
        overflow: auto;

        &-header {
            border-bottom: 1px solid $primary-40;
            background-color: $primary-10;
            padding: 0 5px;
            margin: 5px;
        }
    }

}

.participant-panel-1 {
    grid-area: 1 / 1 / 2 / 7;
    /* background-color: aqua; */
}

.participant-panel-2 {
    grid-area: 1 / 7 / 2 / 13;
    /* background-color: pink; */
}

.participant-panel-3 {
    opacity: 0;
    grid-area: 1 / 7 / 2 / 13;
    /* background-color: greenyellow; */
    transform: translateX(100%);
    transition: transform 1s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.participant.condensed .participant-panel-1 {
    grid-area: 1 / 1 / 2 / 3;
}

.participant.condensed .participant-panel-2 {
    grid-area: 1 / 3 / 2 / 13;
}

.participant.panelOpen .participant-panel-1 {
    grid-area: 1 / 1 / 2 / 7;
}

.participant.panelOpen .participant-panel-2 {
    grid-area: 1 / 7 / 2 / 10;
    opacity: 0;
}

.participant.panelOpen .participant-panel-3 {
    grid-area: 1 / 7 / 2 / 13;
    transform: translateX(0);
    opacity: 1;
}

.participant.sliding .participant-panel-2,
.participant.sliding.panelOpen .participant-panel-3 {
    transform: translateX(100%);
    transition: transform 1s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.participant.sliding.condensed .participant-panel-2,
.participant.sliding.panelOpen.condensed .participant-panel-3 {
    transform: translateX(0);
}


// .participant-panel-2,
// .participant-panel-3 {
//     transition: transform 0.5s ease-in-out;
// }





// .participant {
//     // background-color: red;
//     margin: 0 1em;
//     overflow: hidden;
//     width: 99vw;

//     &__header {
//         display: flex;
//         flex-direction: row;
//         justify-content: flex-end;
//     }
//     &__body {
//         // background-color: rgb(140, 255, 87);
//         height: 85vh;
//     }

//     &__btn-tile {
//         padding: .75em;
//         border-radius: $radius-sm;
//         box-shadow: $shadow-light;
//         cursor: pointer;
//     }

//     &__scan-menu {
//         position: absolute;
//         right: 10px;
//         top: 98px;
//         display: flex;
//         align-items: center;
//     }

//     &__protocol-wrapper {
//         height: 81vh;
//         overflow: scroll;
//         transition: width 0.3s ease-in-out;
//         // background-color: yellow;
//     }
//     &__items-wrapper {
//         display: flex;
//         flex-direction: row;
//     }
//     &__small_item-wrapper {
//         height: 81vh;
//         overflow: scroll;
//         width: 98%;
//         // background-color: antiquewhite;
//     }
//     &__panel_item-wrapper {
//         display: none;
//         height: 81vh;
//         overflow: scroll;
//         width: 40%;
//         // background-color: rgb(73, 78, 223);
//     }

//     &__item-wrapper {
//         height: 81vh;
//         overflow: scroll;
//         // background-color: antiquewhite;
//     }

//     &__browser_wrapper {
//         height: 83vh;
//         overflow: hidden;
//         background-color: rgb(255, 221, 0);
//         display: flex;
//     }

//     &__app {
//         display: flex;
//         flex-direction: column;
//         align-items: center;
//         height: 83vh;
//         overflow: hidden;
//         // position: relative;
//         // padding: 10px;
//         background-color: rgb(0, 221, 255);
//     }


//     &__drive-wrapper {
//         height: 62vh;
//         overflow: scroll;
//     }

//     &__scan-btn {
//         font-size: $text-01;
//         font-family: $font-primary;
//         border-style: none;
//         cursor: pointer;
//         transition: background-color 300ms ease;
//         font-weight: 600;
//         background: transparent;
//         border: none;
//         color: $primary !important;
//         display: flex;
//         align-items: center;
//     }

//     &__scan-icon {
//         color: $primary !important;
//     }







//     &__protocol-scroll-section {
//         height: 75vh;
//         overflow: auto;

//         &-header {
//             border-bottom: 1px solid $primary-40;
//             background-color: $primary-10;
//             padding: 0 5px;
//             margin: 5px;
//         }
//     }

//     &__label-wrapper {
//         display: flex;
//         align-items: center;
//         justify-content: space-between;
//     }

//     &__btn-crumbs {
//         cursor: pointer;
//         // display: flex;
//         // align-items: center;
//         display: -webkit-box;
//     }

//     &__breadcrumb {
//         padding-bottom: 3px;
//         text-decoration: underline;
//         text-transform: uppercase;
//         letter-spacing: 2px;
//         color: $gray-80;
//         font-family: $font-primary;
//         // font-size: $text-00;
//         font-size: x-small;
//     }

//     &__breadcrumb-wrapper {
//         margin-bottom: 12px;
//         display: flow-root;
//     }

//     &__breadcrumb-divider {
//         text-transform: uppercase;
//         letter-spacing: 2px;
//         color: $gray-80;
//         font-family: $font-primary;
//         font-size: $text-00;
//     }

// }

// .visible {
//     visibility: visible;
// }

// .not-visible {
//     visibility: collapse;
// }

.participant__protocol-scroll-section::-webkit-scrollbar {
    display: none;
}

.participant__item-wrapper::-webkit-scrollbar {
    display: none;
}

.participant__drive-wrapper::-webkit-scrollbar {
    display: none;
}

// .full-screen-load {
//     height: 100vh;
//     width: 100vw;
//     display: grid;
//     place-content: center;
// }


// .slide {
//     position: absolute;
//     top: 0;
//     left: 0;
//     width: 100%;
//     height: 100%;
//     background-color: #f1f1f1;
//     transition: transform 0.3s ease;
//   }

//   .hidden {
//     transform: translateX(-100%);
//   }

// .panel {
//     // display: flex;
//     // flex-direction: column;
//     // justify-content: center;
//     // align-items: center;
//     z-index: 101;
//     height: 81vh;
//     width: 57%;
//     background-color: gold;

//     transition: transform 0.5s ease-in-out;
//     box-sizing: border-box;
//     position: absolute;
//     top: 116px;
//     // box-shadow: $shadow;
//     border-radius: 2px;
//     margin: 16px 20 0 0;
// }

// .panel.active {
//     transform: translateX(0%) !important;
//     display: block !important;
// }