@import '../../styles/index.scss';

.viewer-tool-bar {

}
.toolbar {
  // display: flex;
  // align-items: center;
  // padding: 4px;
  // margin-right: 4px;
  // width: 300px;
  // overflow: scroll;

  // display: flex;
  // align-items: center;
  // padding: 4px;


  align-items: center;
  display: flex;
  flex-wrap: wrap;
  margin-right: 4px;
  padding: 4px;


  &::-webkit-scrollbar{
    display: none;
  }
}

.icon-primary{
  color: $primary !important;
}
.icon-secondary{
  color: $disabled !important;
}