@import '../../styles/index.scss';

.study-consent-form {

    &__download-btn{
        width: 100%;
        display: flex;
        align-items: center;
        color: $secondary !important;
        padding: 0px !important;
        font-style: oblique !important;

    }

    &__download-icon{
        color: $secondary !important;
        margin-right: 5px;
    }

    &__section-title{
        font-size: $text-01;
        font-weight: 900;
        font-family: $font-secondary;
        margin-bottom: $space-00;
        text-align: left !important;
    }

    &__section-body{
        font-family: $font-secondary;
        font-size: $text-00;
        margin-top: $space-00;
        margin-bottom: $space-06;
        line-height: $space-05;
        text-align: left !important;

    }

    &__checkbox{
        display: flex;
        align-items: flex-start;
    }

    &__checkbox-text{
        margin-top: 10px;
    }
}

@media (max-width: $tablet) {
    .protocol-print-form__signature{
        max-width: 70vw !important;
    }
}