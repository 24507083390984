@import '../../styles/index.scss';

.new-med-form {

    .row{
        display: flex;
        width: 100%;
    }

    .col-30{
        width: 33.33%;
    }

    .chechbox-wrapper{
        display: flex;
        align-items: center;
        margin-right: 5px;
    }
}

.styled-autocomplete,
input {
    color: $gray-80;
    font-size: $text-00;
    border-radius: $radius/2;
    border-color: $gray-20;
    border-style: solid;
    cursor: text;
    transition: background-color 300ms ease;
    padding: $space-03 $space-02;
    margin: $space-03 0;
    border-width: 1px;
    letter-spacing: .8px;
    width: 100%;
    background-color: white;
    box-shadow: $shadow;
};

.styled-autocomplete,
input:focus {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}