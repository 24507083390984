@import '../../styles/index.scss';

.epilepsy-alerts {

    flex-grow: 1;

    &__tile{
        height: 90%;
        overflow: scroll;
        &::-webkit-scrollbar{
            display: none;
        }
    }

}