@import '../../../styles/index.scss';

.met-manager {
    flex: inherit;
    display: inherit;
    flex-direction: inherit;

    &__panels_wrapper {
        // background-color: rebeccapurple;
        flex: 1;
        // overflow: hidden;
        max-width: inherit;

        & .met_panel_1 {
            flex: 1;
            // background-color: yellow;
        }

        & .met_panel_2 {
            flex: 1;
            height: 68vh;
            // background-color: greenyellow;
        }
    }

    &__assessment-header {
        border-bottom: 1px solid $gray-10;
    }

    &__refresh {
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }

    &__coords-td {
        // display: flex;
        // flex-direction: column;
        // align-items: center;
        // justify-content: center;
        text-align: center;
        padding: 1em;
        font-size: $text-01;
        letter-spacing: 1px;
        font-family: $font-secondary;
        text-transform: uppercase;
        font-weight: 400;
        overflow: hidden;
        white-space: nowrap;
    }

    &__table {

        background: white;
        border-spacing: 0px;
        table-layout: fixed;
        min-width: 100%;
        padding-bottom: 10px;

        // tbody {
        //     overflow-y: auto;
        //     height: 200px;
        // }

        &-thead {
            background-color: $gray-10;
        }

        td,
        th {
            // width: 7rem;
            // border: 2px solid white;
            text-align: center;
            overflow: hidden;
            padding: .75rem .5rem;
            text-align: left;
        }

        &__row:hover {
            background-color: $primary-20;
        }

        th {
            height: 100%;
            // font-family: $font-primary;
            // font-size: $text-00;
            // text-transform: uppercase !important;
            // font-weight: 400;
            // letter-spacing: 1px;
            text-align: center;
        }

        td {
            text-align: center;
            // font-family: $font-secondary;
            // font-size: .875em;
            // font-weight: 300;
            // letter-spacing: 1px;
            cursor: pointer;
            border-bottom: 1px solid $gray-10;
        }

        // width: 100%;

        // &-th{
        //     text-align: center;
        //     font-size: $text-00;
        //     letter-spacing: 1px;
        //     font-family: $font-secondary;
        //     text-transform: uppercase;
        //     font-weight: 300;
        //     padding: .5em;
        // }

        // &-td{
        //     text-align: center;
        //     padding: .5em;
        //     font-size: $text-01;
        //     letter-spacing: 1px;
        //     font-family: $font-secondary;
        //     text-transform: uppercase;
        //     font-weight: 400;
        //     overflow: hidden;
        //     white-space: nowrap;
        //     // vertical-align: middle;
        //     // display: inline-block;
        //     // height: 100%;
        // }

        // &-thead{
        //     // border-bottom: .5px solid $gray;
        //     background-color: $gray-10;
        // }

        &-open {
            color: $primary !important;
        }
    }

    &__alert {
        color: $red;
    }

    &__met-item-wrapper {
        width: 100%;
        border-bottom: 1px solid $gray-10;
    }

    &__met-item-wrapper-active {
        background-color: $primary-10;
        width: 100%;
        border-bottom: 1px solid $gray-10;
    }

    &__met-item-1 {
        // background-color: yellow;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__met-item-2 {
        // background-color: palevioletred;
        display: flex;
        align-items: center;
    }

}