@import '../../../styles/index.scss';

.search-box {
  width: 100%;
  max-width: 500px;
  min-width: 300px;

  &__icon {
    color: $gray-60;
    margin: $space-01;
  }

  form {
    display: flex;
    flex-direction: row;
    border-radius: $radius-pill;
    background: none;

    button {
      background: none;
      border: none;
      // box-shadow: $inset-shadow;
      // border-radius: $radius-pill;
    }

    input {
      width: 100%;
      padding: 7px 15px;
      background-color: white;
      border-radius: $radius-pill;
      border-color: transparent;
      box-shadow: $inset-shadow;

      &::placeholder {
        color: $gray-60;
        font-family: $font-primary;
        font-weight: 300;
        letter-spacing: 0.8px;
        margin-left: 5px;
        font-size: 12px;
      }
    }

    input:focus,
    input:active {
      box-shadow: $shadow;
      border: 1px solid $light-gray;
      outline: none;
    }
  }

  // form {
  //   display: flex;
  //   flex-direction: row;
  //   border-radius: $radius-pill;
  //   background: none;

  //   button {
  //     background: none;
  //     border: 0.5px solid $light-gray;
  //     // box-shadow: $inset-shadow;
  //     // border-radius: $radius-pill;

  //   }

  //   input {
  //     width: 100%;
  //     // padding: $space-02;
  //     background-color: white;
  //     border-radius: $radius-pill;
  //     border-color: transparent;
  //     box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1); // Update the box shadow properties here
  //     padding: 2px 10px;

  //     &::placeholder {
  //       color: $gray-60;
  //       font-family: $font-secondary;
  //       font-weight: 600;
  //       letter-spacing: 0.8px;
  //       margin-left: 5px;
  //       font-size: 10px;
  //     }
  //   }

  //   input:focus,
  //   input:active {
  //     // box-shadow: $shadow;
  //     border: 1px solid $light-gray;
  //     outline: none;
  //   }
  // }

}

.icon-btn {
  color: $secondary;
}

.icon-btn .icon {
  vertical-align: middle;
}