@import '../../../styles/index.scss';

.site-status {
    width: 90vw;
    margin: 0 auto;
    // overflow: scroll;
    // overflow-y: hidden;


    &__header {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    &__table-wrapper {
        width: 100%;
        margin: 0 auto;
        overflow: scroll;
        // overflow: hidden;
        // height: 58vh;
        // min-height: 58vh;
        height: 70vh;
    }

    &__tablepagination {
        width: 100%;
        margin: 0 auto;
        display: grid;
        place-content: center;
        // overflow: scroll;
        // overflow-y: hidden;
        // height: 58vh;
    }

    &__settings {
        padding: .25em .5em;
    }


    &__protocol-wrapper {
        height: 70vh;
        overflow: scroll;
    }


    &__btn-row {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 100%;
        padding-right: 10px;
    }


}

.cd-tracker-table {
    border-spacing: 0.5rem;
    overflow: scroll;
    background: white;
    // table-layout: fixed;
    width: max-content;

    td,
    th {
        // width: 4rem;
        // max-height: 2rem;
        text-align: center;
        // min-width: 25px;
        overflow: hidden;
        // display: inline-block;
        white-space: nowrap;
    }

    th {
        border: 1px solid #ccc;
        height: 25px !important;
        background: $primary-05;
        border-color: white;
        height: 100%;
        font-family: $font-primary;
        font-size: $text-00;
        text-transform: uppercase !important;
        font-weight: 400;
        letter-spacing: 1px;
    }

    td {
        background: transparent;
        font-family: $font-primary;
        font-size: $text-00;
        text-transform: uppercase !important;
        font-weight: 400;
        letter-spacing: 1px;
        cursor: pointer;
        // border-color: white;
    }

    &__patient-label {
        min-width: 96px;
        white-space: nowrap;
        cursor: pointer;
    }

    &__td {
        display: flex;
        align-items: center;
        justify-content: center;
    }

}


.tracker-table {
    overflow: scroll;
    background: white;
    border-spacing: 0.5rem;
    // width: 90vw;
    width: max-content;
    table-layout: fixed;


    td,
    th {
        width: 7rem;
        border: 2px solid white;
        text-align: center;
        overflow: hidden;
    }

    th {
        height: 25px !important;
        background: $primary-05;
        border-color: white;
        height: 100%;
        font-family: $font-primary;
        font-size: $text-00;
        text-transform: uppercase !important;
        font-weight: 400;
        letter-spacing: 1px;
    }

    td {
        font-family: $font-primary;
        font-size: $text-00;
        text-transform: uppercase !important;
        font-weight: 400;
        letter-spacing: 1px;
        cursor: pointer;
    }

    &__td-green {
        background-color: $green;
    }

    &__td-orange {
        background-color: orange;
    }

    &__td-red {
        background-color: $red;
    }

    &__td-grey {
        background-color: $gray-40;
    }

    &__td-blue {
        background-color: $blue-60;
    }

}

.circle-green {
    color: $green !important;
}

.circle-grey {
    color: $gray-40 !important;
}

.circle-red {
    color: $red !important;
}

.circle-blue {
    color: $blue-60 !important;
}

.circle-orange {
    color: orange !important;
}

.bk-green {
    height: 25px;
    width: 25px;
    border: .5px solid white;
    background-color: $green !important;
}
.bk-green:hover {
    background-color: darken($green, 10%) !important;
}

.bk-gray {
    height: 25px;
    width: 25px;
    // flex: 1 1;
    border: .5px solid white;
    background-color: $gray-40 !important;
}
.bk-gray:hover {
    background-color: $gray !important;
}

.bk-red {
    height: 25px;
    width: 25px;
    border: .5px solid white;
    background-color: $red !important;
}
.bk-red:hover {
    background-color: darken($red, 10%) !important;
}

.bk-blue {
    height: 25px;
    width: 25px;
    border: .5px solid white;
    background-color: $blue-60 !important;
}

.bk-blue:hover {
    background-color: $blue !important;
}

.bk-orange {
    height: 25px;
    width: 25px;
    border: .5px solid white;
    background-color: orange !important;
}
.bk-orange:hover {
    background-color: darken($orange, 10%) !important;
}

// .bk-green {
//     height: 25px;
//     min-width: 25px;
//     width: 100%;    border: .5px solid white;
//     background-color: $green !important;
// }

// .bk-gray {
//     height: 25px;
//     min-width: 25px;
//     width: 100%;
//     // flex: 1 1;
//     border: .5px solid white;
//     background-color: $gray-40 !important;
// }

// .bk-red {
//     height: 25px;
//     min-width: 25px;
//     width: 100%;
//         border: .5px solid white;
//     background-color: $red !important;
// }

// .bk-orange {
//     height: 25px;
//     min-width: 25px;
//     width: 100%;
//         border: .5px solid white;
//     background-color: orange !important;
// }

.tracker-guide {
    display: flex;
    align-items: center;
    justify-content: center;

    &__accepted {
        background-color: $green;
        height: 15px;
        width: 15px;
        margin: .5em;
    }

    &__started {
        background-color: $blue-60;
        height: 15px;
        width: 15px;
        margin: .5em;
    }

    &__rejected {
        background-color: orange;
        height: 15px;
        width: 15px;
        margin: .5em;
    }

    &__deviation {
        background-color: $red;
        height: 15px;
        width: 15px;
        margin: .5em;
    }

    &__incomplete {
        background-color: $gray-60;
        height: 15px;
        width: 15px;
        margin: .5em;
    }

}

// .tracker-guide {
//     display: flex;

//     &__accepted {
//         background-color: $green;
//         padding: .25em .5em;
//         margin-right: 1em;
//     }

//     &__started {
//         background-color: orange;
//         padding: .25em .5em;
//         margin-right: 1em;
//     }

//     &__rejected {
//         background-color: $red;
//         padding: .25em .5em;
//         margin-right: 1em;
//     }

//     &__incomplete {
//         background-color: $gray;
//         padding: .25em .5em;
//         margin-right: 1em;
//     }

// }


.stepper {

    display: flex;
    flex-direction: column;
    margin: 1em;

    &__label {
        display: flex;
        align-items: center;
        // justify-content: center;
    }

    &__divider {
        display: flex;
        flex-direction: column;
        border-color: #bdbdbd;
        border-left-style: solid;
        border-left-width: 1px;
        min-height: 40px;
    }

    &__wrapper {
        flex: 1 1 auto;
        margin-left: 8px;
    }

    &__description {
        flex: 1 1 auto;
        margin-left: 19px;
        font-size: x-small;
        color: $gray-60
    }

}

// .site-status_table-wrapper::-webkit-scrollbar-y {
//     display: none;
//   }