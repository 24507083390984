@import '../../styles/index.scss';


.survey {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.survey-header {
    width: 100%;
    margin-bottom: $space-03;
    color: $text-col;
}

.survey-with-header{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    padding: $space-01;
}

.survey-no-header{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    justify-content: flex-end;
}

.survey-content-container {
    width: 100%;
    margin-bottom: 3em;
    // min-height: 75vh;
}

.survey-nav-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.survey-finished-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

// @media only screen and ($laptop-up) {
//     .survey-content-container,
//     .survey-header {
//         width: 40%;
//     }
// }

.survey-view-all-item{
    min-height: fit-content;
}

