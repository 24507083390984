@import '../../styles/index.scss';

.smart-viewer {
  // height: 68vh;
  // max-height: 68vh;
  height: inherit;
  display: flex;
  flex-direction: column;
  margin-right: 2px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  // width: 100%;
  // height: 100%;

  .top-part {
    width: 100%;
    flex: 75% !important;
    flex-grow: 1;
    // background-color: rgb(38, 255, 0);
  }

  &-v {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    // width: 100%;
    height: 100%;
  }

  .bottom-part {
    flex: 25%;
    justify-content: flex-start;
    align-content: flex-start;
    // background-color: rgb(38, 255, 0);
    margin-top: 5px;
    height: 20vh;
    display: flex;
    width: 100%;

    &::-webkit-scrollbar {
      display: none;
    }

    .panel {
      // Styles for the scrolling panel
      // Add your custom styles here
      height: 100%;

      .item-list {
        // Styles for the list of items inside the scrolling panel
        // Add your custom styles here
        overflow-y: scroll;
        height: 100%;
        width: 100%;

        div {
          // Styles for each list item
          // Add your custom styles here
          // background-color: rgb(255, 230, 0);
          margin-top: 1px;
          margin-bottom: 1px;
        }
      }
    }
  }

}