@import '../../../styles/index.scss';

.registry-settings {
    flex: 1;
    display: flex;

}

.registry-settings {
    display: flex;
    flex-direction: column;
    height: 100%;

    &__active-tab {
        width: 100%;
        min-width: fit-content;
        // padding: 2px 7px;
        padding: 2px 12px;
        background-color: $primary-20;
        border-radius: 2px;
        margin-bottom: 1em;
        cursor: pointer;
    }

    &__tab {
        width: 100%;
        min-width: fit-content;
        // padding: 2px 7px;
        padding: 2px 12px;
        // background-color: $gray-20;
        border-radius: 2px;
        margin-bottom: 1em;
        cursor: pointer;
    }


    &__header {
        padding: 2em 15%;
        // border-bottom: 1px solid $gray-10;
    }

    &__body {
        // padding: 1em 5%;
        padding: 2em 15%;
        flex: 1 1 auto;
        overflow-y: auto;

        &::-webkit-scrollbar{
            display: none;
        }
    }

    // &__smallscreen{
    //     padding: 1em 5%;
    // }

    &__heading {
        border-bottom: 1px solid $gray-10;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__row-item {
        display: flex;
        align-items: flex-start;
    }

    &__icon-btn {
        // background: $gray-10;
        // border: none;
        // font-family: $font-primary;
        color: $white !important;
        // padding: 0 .5em ;
        // font-size: 20px;
        // font-weight: 600;
        // margin-bottom: 2px;
    }

    @media (max-width: 786px) {
        &__body {
            padding: 1em 5%;
            // background-color: lightblue;
        }

        &__header {
            padding: 1em 5%;
            // border-bottom: 1px solid $gray-10;
        }
    }
}

.txt-blue {
    color: $primary !important;
    margin-left: 3px;
}


@media screen and (min-width: 768px) {

    /* Adjust the breakpoint (768px) to your desired screen size */
    .egistry-settings {

        &__body {
            padding: 1em calc(var(--registry-padding-horizontal) * 2);

        }
    }

}

@media screen and (min-width: 1024px) {

    /* Adjust the breakpoint (1024px) to your desired screen size */
    .egistry-settings {

        &__body {
            padding: 1em calc(var(--registry-padding-horizontal) * 3);

        }
    }
}

@media screen and (max-width: 480px) {

    /* Adjust the breakpoint (480px) to target mobile devices like iPhones */
    .egistry-settings {


        &__body {
            padding: 1em var(--registry-padding-horizontal-mobile);
        }
    }
}

:root {
    --registry-padding-horizontal: 4%;
    --registry-padding-horizontal-mobile: 1%;
}