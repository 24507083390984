@import '../../styles/index.scss';

.question__text {
    width: 100%;

    &-icon {
        padding: .5em .75em !important
    }

    &__listening-container {
        width: 100%;
        display: flex;
        justify-content: center;
    }
}


.question__list {
    width: 100%;
    display: flex;
    align-items: center;
    background: inherit;
    border: 1px solid $black-20;
    color: $black-60;
    margin-bottom: 0.6em;
    min-height: 3em;
    height: 100%;
    padding: 0px 1em;
    border-radius: $radius;
    cursor: pointer;
    transition: 0.2s;
    word-break: normal;
    margin-bottom: 10px;

    &-icon {
        width: fit-content;
        flex-shrink: 0;
    }


    &--no-check {
        min-height: 5em;

        .question__list-label {
            width: 100%;
            text-align: center;
        }
    }

    &--with-check {
        .question__list-label {
            margin-left: 15px;
        }
    }

    &--true {
        background: $primary-40;
        border-color: $primary-60;
        color: darken($primary, 20%);
        // color: $white
    }

    &:hover {
        box-shadow: $shadow;
    }

    .selected-icon {
        transform: scale(1.2);
    }
}



.question__scale {
    color: $text-col;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.question__scale-container {
    display: flex;
    width: 100%;

    &--vertical {
        display: block;
    }
}

.question__scale-value {
    cursor: pointer;

    &-selected {
        transform: scale(1.5);
        transition: 0.2s;
        color: $primary !important;
        padding: 0 3px;
    }
}

.question__scale-item {
    display: flex;
    align-items: center;
    width: 100%;

    &--vertical {
        flex-direction: column;

        .question__scale-dash {
            width: 10px;
            height: 15px;
        }
    }
}

.question__scale-dash {
    height: 10px;
    width: 100%;
    border-radius: $radius;
    margin: 0px 5px;
}

.question__scale-label-container {
    display: flex;
    justify-content: space-between;
    width: 100%;

    &--vertical {
        position: absolute;
        flex-direction: column;
        left: calc(50% + 25px);
        height: 22.3em;
        width: min-content;
    }
}

.question__scale-selected-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}


.question__microphone {
    padding: .5em !important;
}

.question__height {
    width: 100%;
    display: flex;
    align-items: center;
    // justify-content: space-between;
    // padding: 4em;
}