@import '../../../styles/index.scss';

.hive {

    padding: 1em 3%;
    flex-direction: column;
    display: flex;
    flex: 1;

    &__title {
        display: flex;
        align-items: center;
        // width: 100%;
        justify-content: space-between;
        padding: 0 1rem;
    }

    &__title-actions {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        // width: 100%;
    }

    &__split {
        display: flex;
        flex-direction: row;
    }

    &__title-item {
        margin: 10px 20px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__stats-row {
        display: grid;
        grid-gap: 1rem;
        grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
        padding: 0 1rem;
    }

    &__body {
        width: 100%;
        display: flex;
        flex: 1;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        flex-wrap: nowrap;
        justify-content: normal;
        align-items: normal;
        align-content: normal;

        &-graph-wrapper{
            display: flex;
            height: 100%;
            align-items: center;
            justify-content: center;
        }

        &-empty{
            flex-grow: 1;
            height: 100%;
            overflow: scroll;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &-right {
            // background-color: aqua;
            display: flex;
            flex-grow: 0;
            flex-shrink: 1;
            flex-basis: auto;
            align-self: auto;
            order: 0;
            width: 100%;
            flex-direction: column;
        }

        &-left {
            width: 100%;
            // background-color: pink;
            flex-direction: column;
            display: flex;
            flex-grow: 0;
            flex-shrink: 1;
            flex-basis: auto;
            align-self: auto;
            order: 0;
        }

        &-minis {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: repeat(2, 1fr);
            height: 50%;
            gap: 15px;
            // flex-direction: column;
        }

        &-mini {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: lightgray;
            border-radius: $radius-sm;
            padding: $space-02 $space-03;
            background: $light;
            box-shadow: $shadow;
        }

        &-title {
            display: flex;
            width: 100%;
            align-items: flex-start;
            justify-content: space-between;
        }

        &-tile {
            flex-grow: 1;
            max-height: 50%;
            overflow: scroll;
            &::-webkit-scrollbar {
                display: none;
            }

            &-title{
                margin-left: 15px;
                padding-left: .5rem;
                max-width: fit-content !important;
            }
        }
    }

    &__study-item {
        padding: 1em;

        &-body {
            display: flex;
        }
    }

}

.pause-btn {
    color: $red !important;
}

.box-orange {
    background-color: $primary-20;
    color: $primary !important;
    border-radius: $radius-sm;
    padding: $space-02 $space-03;
    box-shadow: $shadow;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.green {
    color: $green !important;
}

.orange {
    color: $orange !important;
}

.blue {
    color: $blue !important;
}

.box-blue {
    background-color: $blue-20;
    border-radius: $radius-sm;
    padding: $space-02 $space-03;
    box-shadow: $shadow;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.box-green {
    background-color: $green-20;
    border-radius: $radius-sm;
    padding: $space-02 $space-03;
    box-shadow: $shadow;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.box-red {
    background-color: $orange-20;
    border-radius: $radius-sm;
    padding: $space-02 $space-03;
    box-shadow: $shadow;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


// .hive {

//     padding: 1em 3%;
//     flex-direction: column;
//     display: flex;
//     flex: 1;

//     &__title {
//         display: flex;
//         align-items: center;
//         width: 100%;
//         justify-content: space-between;
//         padding: 0 1rem;
//     }

//     &__split {
//         display: flex;
//         flex-direction: row;
//     }

//     &__stats-row {
//         display: grid;
//         grid-gap: 1rem;
//         grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
//         padding: 0 1rem;
//         // min-height: 100px;
//     }

// }


.d-flex-center {
    display: flex;
    align-items: center;
    // justify-content: center;
}