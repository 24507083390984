@import '../../styles/index.scss';

.language-switcher {
    position: relative;
    
}

.language-toggle {
    /* Your other styles for the toggle button */
    background-color: white;
    border: 0px solid #ccc;
    
    /* Use the CSS variable value for the language label */
    content: var(--language-label);
  }
  
  .language-button {
    display: flex;
    align-items:flex-start;
    background-color: aqua;
    padding: 2px;
}

.language-button span {
    display: inline-block;
    white-space: nowrap;
  }

.language-list {
  position: absolute;
  right:-10px;
  top: 40px;
  
  background-color: #ffffff;
  border: .5px solid #ccc;
  list-style: none;
  padding: 0;
  margin: 0;
  z-index: 999;
}

.language-list li {
  padding: 1px;
  align-content: flex-start;
}

.language-list button {
  background-color: transparent;
  border: none;
  cursor: pointer;
}