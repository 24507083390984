@import '../../styles/index.scss';

.audit-action-bar {

    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: normal;
    align-content: stretch;
    height: 100%;

    &__header {
        padding: 0px 15px;
        border-bottom: 1px solid $gray-10;
    }

    &__panel {
        padding: 0px 10px;
        max-height: 100%;
        overflow: hidden;

        &-body {
            max-height: 100%;
            overflow: scroll;

            &::-webkit-scrollbar {
                display: none;
            }
        }

        &-body-audit {
            max-height: 100%;
            height: 100%;
            overflow: scroll;
            display: flex;
            align-items: center;
            justify-content: center;
            // height: 100%;
            flex-direction: column;

            &::-webkit-scrollbar {
                display: none;
            }
        }
    }

    &__panel:nth-child(1) {
        display: block;
        flex-grow: 1;
        flex-shrink: 1;
        flex-basis: 30vw;
        align-self: auto;
        order: 0;
        max-width: 30vw;
        min-width: 30vw;
    }

    &__panel:nth-child(2) {
        display: block;
        flex-grow: 0;
        flex-shrink: 1;
        flex-basis: 40%;
        align-self: auto;
        order: 0;
    }

    &__panel:nth-child(3) {
        display: block;
        flex-grow: 0;
        flex-shrink: 1;
        flex-basis: 30%;
        align-self: auto;
        order: 0;
    }

    &__ops-wrapper{
        display: flex;
        flex-direction: column;

    }
    &__ops-item {
        margin: 5px 0;
        padding: 10px;
        width: 100%;
        border-radius: 5px;
        border-radius: 1px solid $gray-20;
        background-color: $secondary-20;
        font-family: $font-primary;
        font-size: $text-00;
        text-transform: uppercase !important;
        font-weight: 400;
        letter-spacing: 1px;
    }
    &__ops-item-selected {
        margin: 5px 0;
        padding: 10px;
        width: 100%;
        border-radius: 5px;
        border-radius: 1px solid $gray-20;
        color: white;
        background-color: $primary;
        font-family: $font-primary;
        font-size: $text-00;
        text-transform: uppercase !important;
        font-weight: 400;
        letter-spacing: 1px;
    }
}


.finding {

    &__wrapper {
        display: flex;
        justify-content: space-between;

    }

    &__badge {
        display: flex;
        align-items: center;
        flex-direction: column;
    }
}

.finding-badge {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    font-size: 20px;
    font-weight: bold;
    color: white;
    font-family: $font-secondary;

    &__green {
        background-color: $green;
    }

    &__yellow {
        background-color: $yellow;
    }

    &__red {
        background-color: $red;
    }

    &__badge-number {
        margin: 0;
        padding: 0;
    }

}



// .audit-dicom {

//     &__action-bar {
//         display: flex;
//         flex-direction: row;
//     }

//     &__action-label {
//         align-self: center;
//         justify-content: center;
//     }

//     &__action-row-submit {
//         padding-top: 10px;
//         justify-content: center;
//     }

//     &__action-row {

//         &-findings {
//             // border-color: lightgray;
//             // border-width: 1px;
//             // border-style: solid;
//             border-radius: 5px;
//             width: 50%;
//             padding: 5px 0px;
//             // height: 100%;
//             // overflow: scroll;


//         }

//         &-findings-header {
//             padding: 0px 15px;
//             border-bottom: 1px solid $gray-10;
//         }

//         &-box {
//             // width: 100%;
//             height: 90%;
//             overflow: scroll;
//         }

//         &-reco {
//             // border-color: lightgray;
//             // border-width: 1px;
//             // border-style: solid;
//             border-radius: 5px;
//             width: 30%;
//             // padding: 10px;
//             overflow: scroll;

//         }

//         &-submit {
//             width: 20%;
//             display: flex;
//             flex-direction: column;
//             align-items: center;
//             // justify-content: center;
//             // height: 100%;
//         }

//         &-submitbtn {
//             height: 100%;
//             align-items: center;
//             justify-self: center;
//         }
//     }

// }