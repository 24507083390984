@import '../../styles/index.scss';

.epilepsy-dashboard {

    display: flex;
    flex-direction: row;
    transition: flex-basis 5s ease;
    overflow: hidden;

    &__panel1 {
        flex: 1 1;
        flex-basis: 0;
        display: flex;
        flex-direction: column;
    }

    &__panel2 {
        flex: 2 1;
        flex-basis: 0;
    }

    &__list-item {
        display: flex;
        margin: 5px 0px;
    }

    &__table-col {}
}