@import '../../styles/index.scss';

.progress-bar--danger {
    .MuiLinearProgress-colorPrimary{
        background-color: $gray-20 !important;
        border-radius: 2px !important;
    }
    
    .MuiLinearProgress-barColorPrimary{
        background-color: $red !important;

    }
}

.progress-bar--primary {
    .MuiLinearProgress-colorPrimary{
        background-color:$gray-20 !important;
        border-radius: 2px !important;
    }
    
    .MuiLinearProgress-barColorPrimary{
        background-color: $primary !important;

    }
}

.progress-bar--warning {
    .MuiLinearProgress-colorPrimary{
        background-color: $gray-20!important;
        border-radius: 2px !important;
    }
    
    .MuiLinearProgress-barColorPrimary{
        background-color: $yellow !important;

    }
}

.progress-bar--success {
    .MuiLinearProgress-colorPrimary{
        background-color:$gray-20 !important;
        border-radius: 2px !important;
    }
    
    .MuiLinearProgress-barColorPrimary{
        background-color: $green !important;

    }
}
