@import '../../styles/index.scss';

.emr-item {
    border: 1px dotted $gray-60;
    box-shadow: none;
    padding: 0.75em;
    border-radius: 5px;
    flex: 1 1;
    margin: 0.5em 1px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    // align-items: center;
    // justify-content: space-between;
    width: 100%;

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }

}