@import '../../styles/index.scss';

.study-details {

    &__header {
        // background-color: white;
        // border: 2px solid $primary-10;
        background-color: $background;
        box-shadow: $shadow-light;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        padding: 5em 20%;
        margin-top: -5px;
        gap: 1em;

        .aside {
            flex-grow: 1;
            white-space: nowrap;
            display: flex;
            justify-content: flex-end;
        }
    }

    &__body {
        padding: 0.75rem calc(20% - 15px);
    }

    &__btn{
        padding-left: 0px !important;
        font-style: oblique !important;
        text-transform: capitalize !important;
        color: $secondary !important;

        &:hover{
            background-color: transparent !important;
            color: $primary;
        }
    }

    &__icon {
        margin-left: 3px;
    }

}

@media (max-width: $tablet) {
    .study-details  {
        &__header {
            padding: 5em;
            flex-direction: column;
            align-items: flex-start;
        }

        &__body {
            padding: 10px;
        }

    }
}