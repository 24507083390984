@import '../../styles/index.scss';

.seizure-type-form {


    .view-toggle-btn-active {
        background-color: $primary-20;
        padding: $space-02 $space-03;
        cursor: pointer;
    }

    .view-toggle-btn {
        background-color: $gray-05;
        padding: $space-02 $space-03;
        cursor: pointer;
    }

    .left-toggle-btn {
        border-top-left-radius: $radius;
        border-bottom-left-radius: $radius;
        border-top: 1px solid $primary-20;
        border-bottom: 1px solid $primary-20;
        border-left: 1px solid $primary-20;

    }

    .right-toggle-btn {
        border-top-right-radius: $radius;
        border-top: 1px solid $primary-20;
        border-bottom: 1px solid $gray-20;
        border-right: 1px solid $primary-20;
        border-bottom-right-radius: $radius;
    }

    .mid-toggle-btn {
        border-top: 1px solid $primary-20;
        border-bottom: 1px solid $gray-20;
    }

    ._make-row{
        display: flex;
        width: 100%;
    }

    .col-50{
        width: 50%;
    }

}