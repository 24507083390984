@import '../../../styles/index.scss';

.site-list-item {

    // margin-top: .5em;
    overflow: hidden;
    
    &__tile{
        padding: 1em;
        position: relative; 
        margin: 10px 5px !important;
    }

    &__grid{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__status {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
    }

    &__shield{
        position: absolute;
        top: 0;
        right: 10px; 
        z-index: 50;
        
    }

}