@import '../../styles/index.scss';

.dicom-table {

    &__header {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        justify-content: space-evenly;
        background-color: $secondary-20;
        padding: .7em;
        // border-radius: $radius;
    }

    &__body {
        overflow-y: hidden;
        height: 100%;
        overflow: scroll;
        border: 1px solid $gray-20;
    }

    &__table {
        &.header {
            background-color: $secondary-20;
            font-family: $font-primary;
            font-size: $text-00;
            text-transform: uppercase !important;
            font-weight: 400;
            letter-spacing: 1px;
        }

        &.body {
            overflow-y: scroll;
            // height: 200px;
        }

        td {
            max-width: 50px;
            font-family: $font-primary;
            font-size: $text-00;
            text-transform: uppercase !important;
            font-weight: 400;
            letter-spacing: 1px;
        }
    }

    &__btn-row {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
    }

    &__toggle-btn {
        font-size: $text-01;
        margin: 0 5px;
        border-radius: 2px;
        font-family: $font-primary;
        border-style: none;
        cursor: pointer;
        transition: background-color 300ms ease;
        font-weight: 600;
        display: flex;
        align-items: center;
        padding: $space-02 $space-05;
        letter-spacing: 1px;
        background-color: $light-gray;
        margin: 5px;

        &:hover {
            background-color: $primary;
            color: white;
            border-color: $primary;
        }
    }

    &__toggle-btn-active {
        font-size: $text-01;
        border-radius: 2px;
        margin: 0 5px;
        font-family: $font-primary;
        border-style: none;
        cursor: pointer;
        transition: background-color 300ms ease;
        font-weight: 600;
        display: flex;
        align-items: center;
        padding: $space-02 $space-05;
        letter-spacing: 1px;
        color: white;
        background-color: $primary;
        margin: 5px;
    }
}

.MuiRadio-root {
    padding: 0 !important;
}

.radio-buttons {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

// .radio-buttons span {
//     padding: 0px 1px !important;
// }

.dicom-table__body::-webkit-scrollbar {
    display: none;
}



.image-data-table {
    width: 100%;
    border-collapse: collapse;
}

.image-data-table th,
.image-data-table td {
    padding: 8px 4px;
    padding-top: 8px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    text-align: left;
    border-bottom: 1px solid #ccc;
}

.image-data-table td.long-text {
    white-space: normal;
    word-wrap: break-word;
}

.image-data-table .wide-column {
    min-width: 100px;
    /* Adjust the width as per your requirements */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.image-data-table .td-right {
    display: flex;
    justify-content: flex-end;
    width: 100%;
}

.image-data-table .td-green {
    background-color: $green-20;
}

.image-data-table .td-red {
    background-color: $red-20;
}