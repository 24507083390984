@import '../../../styles/index.scss';

.patients {
    flex-direction: column;
    display: flex;
    flex: 1;

    &__header {
        // background-color: $primary-10;
        // background-image: linear-gradient(360deg, #fdfbfb 0%, #ebedee 100%); 
        // background-image: linear-gradient(170deg, $secondary 0%, $quaternary 100%);     
        // background-image: linear-gradient(120deg, $secondary 0%, #f5576c 100%);
        // background-color: $secondary;
        border-bottom: 1px solid $gray-10;
        padding: 2em 10%;
    }

    &__body {
        padding: 0px 10% 2em 10%;
        max-height: 100%;
        overflow-y: scroll;
    }

    &__all-checkbox {
        padding-left: 8px;
    }

    &__control {
        align-items: flex-end;
        display: flex;
        min-height: 40px;
    }

    &__loading {
        display: grid;
        place-content: center;
        margin-top: 300px;
    }

    &__fit {
        display: flex;
        width: fit-content;
    }
}