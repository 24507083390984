@import '../../../styles/index.scss';

.conference {
    flex-direction: column;
    display: flex;
    flex: 1;
    padding: 10px 15px;


    &__body {
        display: flex;
        flex-direction: row;
        flex: 1;
    }

    &__panel1 {
        flex: 1;
        flex-basis: 0;
        height: 80vh;
        overflow: hidden;
    }

    &__panel2 {
        flex: 2;
        flex-basis: 0;
        height: 80vh;
        overflow: hidden;
    }

    &__tile {
        height: 92%;

        &-body{
            height: 95%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }
    }

    &__header{
        padding: 0px 15px;
        display: flex;
        align-items: center;
    }


    &__patient {
        display: flex;
        flex-direction: row;
        align-items: center;
        border: 1px solid $primary-20;
        box-shadow: none;
        padding: 0.75em;
        border-radius: 5px;
        margin: 0.5em 1px;
        width: 100%;

        &__indicator {
            flex: 0 1;
            padding-right: 0.75em;
        }
    }

    &__patient-selected {
        display: flex;
        flex-direction: row;
        align-items: center;
        border: 1px solid $primary-20;
        box-shadow: none;
        padding: 0.75em;
        border-radius: 5px;
        margin: 0.5em 1px;
        width: 100%;
    
        &__indicator {
            flex: 0 1;
            padding-right: 0.75em;
        }
    
        &__icon {
            color: $primary !important;
            margin: 2px;
        }
    
        &__wrapper {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
        }
    
        &:hover{
            box-shadow: $shadow;
        }
    }

}