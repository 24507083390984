@import '../../../styles/index.scss';

.study-drive {
    // background-color: red;
    margin: $space-04;

    &-tab-wrapper {
        background-color: red;
        display: flex;
        flex-direction: row;
        min-height: 80%;
        max-height: 90%;
    }

    &-tab-default {
        // background-color: greenyellow;
        display: flex;
        flex-direction: row;
        list-style: disc outside none;
    }

    &-tabs-header {
        // background-color: blueviolet;
        list-style: none;
    }

    &-tabs-tab {

    }
}

awsui-tabs {
    padding: 1em 10%;

}