@import '../../styles/index.scss';

.protocol-browser {
    // background-color: aqua ;
    flex: .75 !important;
    margin: 10px !important;
    transition: flex-basis 5s ease;
    display: flex;
    flex-direction: column;

    &.condensed {
        flex: 0.25 !important;
    }
    
    .header {
        // background-color: beige;
        display: flex;
        flex-direction: column;
        flex-basis: 0;
    
        .headerbar{
            flex:1;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }
    }
    .body {
        flex:1;
        flex-grow: 1;
        // background-color: rgb(9, 255, 0) ;
        display: flex;
        flex-direction: row;
        transition: flex-basis 5s ease;
        overflow: hidden;
        
        .timepoints-panel {
            flex: 1;
            flex-basis: 0;
            /* Styles for the left column */
            // background-color:rgb(99, 186, 236);
            margin-right: 10px;
            overflow-y: scroll;
            &::-webkit-scrollbar{
                display: none;
            }
            &.condensed {
                // background-color: rgb(41, 220, 157) !important;
                flex: .8 !important;
                margin-right: 2px;
            }
        

        }

        .reqs-panel {
            flex: 1;
            flex-basis: 0;
            /* Styles for the left column */
            // background-color:rgb(204, 67, 225);
            margin-left: 10px;
            overflow-y: scroll;
            &::-webkit-scrollbar{
                display: none;
            }
            &.condensed {
                // background-color: rgb(240, 240, 81) !important;
                margin-left: 2px;
                flex: 1 !important;
            }

            .timepoints-control-panel {
                padding: .75em;
                // border-radius: 2px !important;
                margin: 0.25em 0.25em;
                box-shadow: $shadow-light;
                // background-color: rgb(9, 255, 0) ;

                display: flex;
                flex-direction: column;
                justify-content:space-between;

                display: flex;
            }
        }

    }
    .footer {
        flex-basis: 0;
    }
}