@import '../../styles/index.scss';

.add-patient {

    &__spinner {
        height: 200px;
        width: 100%;
        display: grid;
        place-content: center;
    }

    &__list-item {
        padding: 10px;
        margin-bottom: 10px;
        background-color: #fff;
        box-shadow: $shadow-light;
        align-items: flex-start !important;
        display: flex;
        flex-direction: column;
    }

}



.csv-input {
    color: $gray-80;
    font-size: $text-00;
    border-radius: $radius-sm;
    border-color: $gray-20;
    border-style: solid;
    cursor: text;
    transition: background-color 300ms ease;
    padding: $space-03 $space-02;
    margin: $space-03 0;
    border-width: 0.5px;
    letter-spacing: 0.8px;
    width: 100%;
    background-color: white;
    box-shadow: $shadow;
    color: $gray-60;
    font-family: $font-secondary;
    font-weight: 300;
    letter-spacing: 0.8px;

    &--disabled {
        background-color: $disabled-background !important;
    }

    &::placeholder {
        color: $gray-60;
        font-family: $font-secondary;
        font-weight: 300;
        letter-spacing: 0.8px;
    }

    &:focus {
        border-color: $primary !important;
        background-color: $primary-10 !important;
    }

    &:hover {
        border-color: darken($gray-20, 30%);
    }
}