@import '../../styles/index.scss';

.clinic-visits {

    padding: 10px 0.75rem 1rem;
    // width: 100%;
    // height: 100%;

    width: 100%;
    box-shadow: 0 5px 15px rgb(0 0 0 / 8%);
    color: hsla(0, 0%, 9%, .8);
    background: #fff;
    height: 100%;
    border-radius: $radius;
    overflow-y: hidden;
    overflow: scroll;
    padding: 10px;

    &::-webkit-scrollbar {
        display: none;
    }


    &__filter-wrapper {
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }

    &__tile {
        box-shadow: 0 5px 15px rgb(0 0 0 / 8%);
        color: hsla(0, 0%, 9%, .8);
        background: #fff;
        height: 100%;
        border-radius: $radius;
        overflow-y: hidden;
        overflow: scroll;
        // overflow: hidden;
    }

    &__sticky {
        position: sticky;
        /* make the table heads sticky */
        top: 0px;
        /* table head will be placed from the top of the table and sticks to it */
        background: #fff;
    }

    &__empty {
        width: 100%;
        height: 100%;
        // padding-top: 150px;
        display: grid;
        place-items: center;
    }

    &__header {
        display: flex;
        align-items: center;
        padding: $space-02 $space-03 0 $space-03;
    }

    &__active {
        border: 1.5px solid $gray-10;
        background-color: $primary-20;
        cursor: pointer;
        transition: background-color 300ms ease;
        padding: $space-02 $space-03;
        color: $primary;
        font-size: $text-xs;
        letter-spacing: 1px;
        font-family: $font-secondary;
        text-transform: uppercase;
        font-weight: 300;
    }

    &__toggle {
        border: 1.5px solid $gray-10;
        background-color: $gray-10;
        cursor: pointer;
        transition: background-color 300ms ease;
        padding: $space-02 $space-03;
        font-size: $text-xs;
        letter-spacing: 1px;
        font-family: $font-secondary;
        text-transform: uppercase;
        font-weight: 300;
        // color: $gray-40;
    }

    &__th {
        border-bottom: 2px solid $gray-10;
    }

}

.visits-table {
    // overflow: scroll;
    background: white;
    border-spacing: 0px;
    // width: max-content;
    table-layout: fixed;
    min-width: 100%;
    padding: 0 $space-03;
    padding-bottom: 10px;

    tbody {
        overflow-y: auto;
        /* make the table scrollable if height is more than 200 px  */
        min-height: 200px;
        // overflow: scroll;
        // position: relative;
        // height: 100%;
        // // min-height: 500px;
        // max-height: 60vh;
        // overflow-y: auto;
        // overflow-x: hidden;
        // overflow-wrap: break-word;
    }

    td,
    th {
        // width: 7rem;
        // border: 2px solid white;
        text-align: center;
        overflow: hidden;
        padding: .75rem .5rem;
        text-align: left;
    }

    &__row:hover {
        background-color: $primary-20;
    }

    th {
        height: 100%;
        font-family: $font-primary;
        font-size: $text-00;
        text-transform: uppercase !important;
        font-weight: 400;
        letter-spacing: 1px;
        border-bottom: 3px solid $primary-20;

    }

    td {
        // font-family: $font-secondary;
        // font-size: .75em;
        // text-transform: uppercase !important;
        // font-weight: 300;
        // letter-spacing: 1px;
        cursor: pointer;
        border-bottom: 1px solid $primary-20;
    }


}

.toggle-btn-group {
    display: inline-block;
    position: relative;
}

.toggle-btn-group .toggle-btn {
    float: left;
    padding: 10px;
    margin: 0;
    height: 40px;
    min-width: 65px;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    background: $primary-20;
    background: -moz-linear-gradient(top, $gray-40 0%, $gray-20 100%);
    /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, $gray-40), color-stop(100%, $gray-20));
    /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top, $gray-40 0%, $gray-20 100%);
    /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top, $gray-40 0%, $gray-20 100%);
    /* Opera 11.10+ */
    background: -ms-linear-gradient(top, $gray-40 0%, $gray-20 100%);
    /* IE10+ */
    background: linear-gradient(to bottom, $gray-40 0%, $gray-20 100%);
    /* W3C */
    font-size: 13px;
    font-weight: bold;
    color: rgba(0, 0, 0, .8);
    border: none;
    // text-shadow: 0 1px 1px rgba(255, 255, 255, .2);
    -moz-box-shadow: inset 1px 1px 1px rgba(255, 255, 255, 0.2), 1px 1px 3px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: inset 1px 1px 1px rgba(255, 255, 255, 0.2), 1px 1px 3px rgba(0, 0, 0, 0.3);
    box-shadow: inset 1px 1px 1px rgba(255, 255, 255, 0.2), 1px 1px 3px rgba(0, 0, 0, 0.3);
}

.clinic-visits__toggle:first-child {
    -moz-border-radius: 4px 0 0 4px;
    -webkit-border-radius: 4px 0 0 4px;
    border-radius: 4px 0 0 4px;
}

.clinic-visits__toggle:last-child {
    -moz-border-radius: 0 4px 4px 0;
    -webkit-border-radius: 0 4px 4px 0;
    border-radius: 0 4px 4px 0;
}


.clinic-visits__toggle:only-of-type {
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    border-radius: 5px;
}

.clinic-visits__active:first-child {
    -moz-border-radius: 4px 0 0 4px;
    -webkit-border-radius: 4px 0 0 4px;
    border-radius: 4px 0 0 4px;
}

.clinic-visits__active:last-child {
    -moz-border-radius: 0 4px 4px 0;
    -webkit-border-radius: 0 4px 4px 0;
    border-radius: 0 4px 4px 0;
}



.toggle-btn-group>button:hover {
    color: $secondary;
    // text-shadow: 0 -1px 1px rgba(0, 0, 0, .5);
    -moz-transition: all 0.1s linear;
    -o-transition: all 0.1s linear;
    -webkit-transition: all 0.1s linear;
    transition: all 0.1s linear;
    cursor: pointer;
}

.toggle-btn-group>button:active {
    background: $primary-20;
}

.toggle-btn-group>.selected:hover {
    background: $primary-20;
}

.toggle-btn.selected {
    border-color: #141414;
    border-bottom: 1px solid rgba(0, 0, 0, .2);
    -moz-box-shadow: inset 1px 1px 3px rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: inset 1px 1px 3px rgba(0, 0, 0, 0.5);
    box-shadow: inset 1px 1px 3px rgba(0, 0, 0, 0.5);
    background: #404040;
    color: #d1d3d4;
}


.clinic-visits__tile::-webkit-scrollbar {
    display: none;
}