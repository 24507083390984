@import '../../../styles/index.scss';

.registry {
    padding: 1em;
    flex: 1;
    display: flex;
    flex-direction: column;



    &__header {
        // background-color: rebeccapurple;
        display: flex;
        // justify-content: space-between;
        // align-items: center;
        // margin: 0 15px;
    }

    &__tab {
        background-color: transparent;
        border: none;
        font-size: $text-00;
        letter-spacing: 2px;
        font-family: $font-primary;
        text-transform: uppercase;
        font-weight: 500;
        color: $gray-40;
        margin: 0 5px;
        display: flex;
        align-items: center;
        cursor: pointer;
        &:hover{
            color: $primary;
        }
    }

    &__tab-active {
        background-color: transparent;
        border: none;
        font-size: $text-00;
        letter-spacing: 2px;
        font-family: $font-primary;
        text-transform: uppercase;
        font-weight: 500;
        color: $primary;
        margin: 0 5px;
        display: flex;
        align-items: center;
    }

    &__icon {
        color: $gray-40 !important;
        margin: 5px;
    }

    &__icon-active {
        color: $primary !important;
        margin: 5px;
    }


    &__body {
        height: 100%;
        flex-grow: 1;
        overflow-y: scroll;
        overflow-x: hidden;
        display: flex;

        &-title {
            display: flex;
            justify-content: space-between;
            margin: 0 15px;
        }

        &-heading {
            // border-bottom: 1px solid $gray-10;
            // margin: 0 15px !important;
            border-bottom: 3px solid $primary-20;
        }

        &-er {
            display: grid;
            place-content: center;
            height: 100%;
        }

        &::-webkit-scrollbar {
            display: none;
        }

    }

    &__panel1 {
        width: 60%;
        flex-direction: column;
        display: flex;
    }

    &__panel2 {
        width: 40%;
        flex-direction: column;
        display: flex;
    }

    &__patients {
        flex-grow: 1;
        height: 100%;
        padding: 10px 0.75rem 1rem;

        &-tile {
            width: 100%;
            box-shadow: 0 5px 15px rgb(0 0 0 / 8%);
            color: hsla(0, 0%, 9%, .8);
            background: #fff;
            height: 100%;
            border-radius: $radius;
            overflow-y: hidden;
            overflow: scroll;
            // padding: 10px;

            &::-webkit-scrollbar{
                display: none;
            }
            
        }
    }

    &__stats {
        display: grid;
        // grid-gap: 1rem;
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    }

    &__urgent {
        // height: 95%;
        flex-grow: 1;
        padding: 10px 0.75rem 1rem;
        height: 100%;

        &-tile {
            width: 100%;
            box-shadow: 0 5px 15px rgb(0 0 0 / 8%);
            color: hsla(0, 0%, 9%, .8);
            background: #fff;
            height: 100%;
            border-radius: $radius;
            overflow-y: hidden;
            overflow: scroll;
            padding: 10px;

            &::-webkit-scrollbar{
                display: none;
            }
            
        }

        &-tile-body {
            height: 100%;
            width: 100%;
            // display: flex;
            // align-items: center;
            // justify-content: center;
        }

        &-tile-body-empty {
            height: 100%;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }

    }

    &__links {
        height: 52%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    &__link-tile {
        margin: 5px 15px !important;
    }


}


.registry-box-orange {
    background-color: $primary-20;
    color: $primary !important;
    border-radius: $radius-sm;
    padding: 1rem;
    box-shadow: $shadow;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


.registry-box-blue {
    background-color: $blue-20;
    border-radius: $radius-sm;
    padding: 1rem;
    box-shadow: $shadow;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.registry-box-green {
    background-color: $green-20;
    border-radius: $radius-sm;
    padding: 1rem;
    box-shadow: $shadow;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.registry-box-red {
    background-color: $orange-20;
    border-radius: $radius-sm;
    padding: 1rem;
    box-shadow: $shadow;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.registry-action-tile {
    padding: 10px;

    &__row {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}

.r-link-tile {
    display: flex;
    padding: .5rem;
    align-items: center;
    min-height: fit-content;

    &__avatar {
        color: $primary;
        border: 1px solid $primary;
        border-radius: 50px;
        align-items: center;
        width: 50px;
        height: 50px;
        display: flex;
        justify-content: center;
        margin: 0 0.75rem;

        &-icon {
            font-size: 1.25rem !important;
            color: $primary !important;
        }
    }

    &:hover {
        &__avatar {
            background-color: $primary;

            &-icon {
                color: $white !important;
            }
        }
    }

}


.registry-box {
    padding: 1rem;
    display: flex;
    align-items: center;
    // margin: 5px !important;

    &__stat {
        margin-right: 5px;
        background-color: $primary;
        height: 30px;
        width: 30px;
        border-radius: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__stat-primary {
        margin-right: 5px;
        background-color: $primary;
        height: 50px;
        width: 50px;
        border-radius: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__stat-blue {
        margin-right: 5px;
        background-color: $blue;
        height: 50px;
        width: 50px;
        border-radius: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__stat-orange {
        margin-right: 5px;
        background-color: $orange;
        height: 50px;
        width: 50px;
        border-radius: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__stat-green {
        margin-right: 5px;
        background-color: $green;
        height: 50px;
        width: 50px;
        border-radius: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}



// .registry {
//     padding: 1em 7%;
//     flex: 1;
//     display: flex;
//     flex-direction: column;



//     &__header {
//         // background-color: rebeccapurple;
//         display: flex;
//         justify-content: space-between;
//         align-items: center;
//         margin: 0 15px;

//     }

//     &__stats {
//         display: grid;
//         grid-gap: 1rem;
//         grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
//     }

//     &__body {
//         height: 100%;
//         flex-grow: 1;
//         overflow-y: scroll;
//         overflow-x: hidden;
//         justify-content: flex-start;
//         display: flex;
//         flex-direction: row;
//         transition: flex-basis 5s ease;
//         overflow: hidden;

//         .panel1 {
//             flex: 1;
//             flex-basis: 0;
//             /* Styles for the left column */
//             // background-color:bisque;
//         }

//         .panel2 {
//             display: none;
//             flex: 0;
//             flex-basis: 0;
//         }

//         &.show-panel2 {
//             .panel1 {
//                 flex: 2;
//                 margin-right: 1em;
//                 overflow: scroll;

//                 &::-webkit-scrollbar{
//                     display: none;
//                 }
//             }

//             .panel2 {
//                 flex: 1;
//                 display: flex;
//                 overflow: scroll;

//                 &::-webkit-scrollbar{
//                     display: none;
//                 }
//             }

//         }


//         &-title {
//             display: flex;
//             justify-content: space-between;
//             margin: 0 15px;
//         }

//         &-heading {
//             border-bottom: 1px solid $gray-10;
//             margin: 0 15px !important;
//         }

//         &-er {
//             display: grid;
//             place-content: center;
//             height: 100%;
//         }

//         &::-webkit-scrollbar {
//             display: none;
//         }
//     }



// }


// .registry-box-orange {
//     background-color: $primary-20;
//     color: $primary !important;
//     border-radius: $radius-sm;
//     padding: 1rem;
//     box-shadow: $shadow;
//     display: flex;
//     flex-direction: column;
//     justify-content: center;
//     align-items: center;
// }


// .registry-box-blue {
//     background-color: $blue-20;
//     border-radius: $radius-sm;
//     padding: 1rem;
//     box-shadow: $shadow;
//     display: flex;
//     flex-direction: column;
//     justify-content: center;
//     align-items: center;
// }

// .registry-box-green {
//     background-color: $green-20;
//     border-radius: $radius-sm;
//     padding: 1rem;
//     box-shadow: $shadow;
//     display: flex;
//     flex-direction: column;
//     justify-content: center;
//     align-items: center;
// }

// .registry-box-red {
//     background-color: $orange-20;
//     border-radius: $radius-sm;
//     padding: 1rem;
//     box-shadow: $shadow;
//     display: flex;
//     flex-direction: column;
//     justify-content: center;
//     align-items: center;
// }

// .registry-action-tile{
//     padding: 10px;

//     &__row{
//         width: 100%;
//         display: flex;
//         align-items: center;
//         justify-content: space-between;
//     }
// }