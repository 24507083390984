@import '../../styles/index.scss';

.conference-ready-patient {

    padding: 10px;
    margin-bottom: 10px;
    background-color: #fff;
    box-shadow: $shadow-light;


    &__header{
        display: flex;
        justify-content: space-between;
    }
}