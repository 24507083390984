@import '../../../styles/index.scss';

.icon-text-input {

    font-family: $font-primary;
    position: relative;
    background-color: $white !important;

    &__input {
        border: 2px solid $gray-10;
        border-radius: 5px;
        display: flex;
        align-items: center;
        padding: 5px;
        font-family: $font-primary;
        // color: $text-col;
        // box-shadow: $shadow;

        &--focused {
            box-shadow: $shadow-light;
        }

        &-field {
            width: 100%;
            border: 0px;
            padding-left: 5px;

            &:focus {
                outline: none;
            }

            &::-webkit-input-placeholder {
                font-family: $font-primary;
            }
        }

        &-adornment {
            padding-left: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &:focus {
            outline: none;
            box-shadow: $shadow;

            +.textfield__adornment {
                &-placeholder {
                    display: none;
                }
            }
        }
    }

    &__adornment {
        position: absolute;
        top: 10px;
        left: 12px;

        &-text {
            color: $text-col;
        }

        &-placeholder {
            margin-left: 5px;
            color: $gray;
        }
    }

}