@import '../../styles/index.scss';

.patient-session-survey {

    overflow: scroll;

    &::-webkit-scrollbar{
        display: none;
    }

}