@import '../../../styles/index.scss';


.cohort {

    display: flex;
    flex-direction: column;
    flex: 1;

    &__body {
        display: grid;
        grid-template-columns: minmax(250px, 15%) 1fr;
        padding: 10px 5%;
    }

    &__header {
        border-bottom: 1px solid $gray-10;
        padding: 1em 5%;
    }

    &__side-panel {
        padding: 8px;
    }

    &__right-panel {
        background-color: #F8F8F8;
        padding: 8px;
        border-radius: 10px;
        flex-grow: 1;
        height: calc(100vh - 168px);
        overflow-y: scroll;
        padding-bottom: 4em;
    }

    &__button {
        border: none;
        background-color: transparent;
        font-size: $text-02;
        font-family: $font-primary;
        border-style: none;
        cursor: pointer;
        font-weight: 400;
        width: 100%;
        text-align: left;
        margin: .25em 0px;
        padding: 5px 12px;
        border-radius: $radius;

        &:hover {
            background-color: $primary-20;
        }
    }

    &__button-active {
        border: none;
        width: 100%;
        background-color: transparent;
        color: $primary;
        font-size: $text-02;
        font-family: $font-primary;
        border-style: none;
        cursor: pointer;
        font-weight: 700;
        width: 100%;
        text-align: left;
        margin: .25em 0px;
        padding: 5px 12px;
        // border-radius: $radius;
        border-right: 3px solid $primary;
    }
}