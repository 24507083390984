@import '../../styles/index.scss';

.multiple-select-dropdown {
    width: 100%;
}

.WithStyles\(ForwardRef\(InputBase\)\)-input-2 {
    margin-left: 0px !important;
    margin-right: 0px !important;
}
