@import '../../../styles/index.scss';

.studies {
    // padding: 1em 8%;
    padding: 1em var(--studies-padding-horizontal);
    display: flex;
    flex:1;
    flex-direction: column;


    &__icon-btn {
        color: $secondary;
        background-color: transparent !important;
        padding: .25em;
        border-radius: 50px;
        cursor: pointer !important;
        height: fit-content;
        border: none !important;
        z-index: 100;
        display: grid;
        pointer-events: auto;
        align-items: center;

        &-icon {
            color: $secondary !important;
        }

        &:hover {
            color: $primary !important;
        }
    }

    &__wrapper {
        // background-color: aquamarine;
        display: flex;
        flex-direction: column;
        height: 80vh;
        justify-content: space-between;
    }

    &__body {
        // background-color: magenta;
        flex-grow: 1;
        overflow-y: auto;
        padding-right: 10px;
        justify-content: flex-start;
    }

    &__header {
        // background-color: rebeccapurple;
        display: flex;
        justify-content: space-between;
        margin: 0px 15px;
        align-items: center;
    }

    &__title_wrapper {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
    }

    &__search {
        width: 40%;
        position: relative;
        top: 20px;
    }
    
    &__footer {
        // background-color: rebeccapurple;
    }

    &__btn {
        padding: 1em;
        background-color: $white ;
        box-shadow: $shadow;
        margin-bottom: .5em;
        width: 100%;
        cursor: pointer;
    }
}

.studies-table {
    &__header {
        display: flex;
        flex-direction: row;
    }
}


.pr-12 {
    padding-right: 12px;
}

.add-study-treatment-header {
    display: flex;
    align-items: center;
}


@media screen and (min-width: 768px) {

    /* Adjust the breakpoint (768px) to your desired screen size */
    .studies {
        padding: 1em calc(var(--studies-padding-horizontal) * 2);

        &__body {
            padding-right: 10px;
        }
    }

}

@media screen and (min-width: 1024px) {

    /* Adjust the breakpoint (1024px) to your desired screen size */
    .studies {
        padding: 1em calc(var(--studies-padding-horizontal) * 3);

        &__body {
            padding-right: 10px;
        }
    }
}

@media screen and (max-width: 480px) {

    /* Adjust the breakpoint (480px) to target mobile devices like iPhones */
    .studies {
        padding: 1em var(--studies-padding-horizontal-mobile);

        &__body {
            padding-right: 2px;
        }
    }
}

:root {
    --studies-padding-horizontal: 2%;
    --studies-padding-horizontal-mobile: 1%;
}