@import '../../styles/index.scss';

.tabs {

    &__headers {
        display: flex;
        padding: 0;
    }

}

.tab-titles {
    list-style-type: none;
    margin: 0 5px;
}


.tab-title {
    display: block;
    color: $gray;
    text-align: center;
    padding: $space-01 $space-03;
    cursor: pointer;
    background-color: transparent;
    border: none;
    transition: all 300ms ease;
    font-weight: 400;
    letter-spacing: 2px;
    font-size: x-small;
    text-transform: uppercase;
    font-family: $font-primary;

    &:hover {
        background-color: $background;
    }

    &__wrapper {
        display: inline-block;
        list-style-type: none;
        margin: 0;

        &:first-of-type {
            margin-left: 0;
        }

        &:last-of-type {
            margin-right: 0;
        }
    }

    &__selected {
        color: $primary !important;
        border: 1px solid;
        border-color: transparent;
        border-bottom-color: $primary;
    }

}