@import '../../styles/index.scss';

.blackhole-list-item {
  // border: .25px solid #ccc;
  // border-radius: 2px;
  padding: 10px;
  margin-bottom: 10px;
  background-color: #fff;
  box-shadow: $shadow-light;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  &__indicator {
    padding: 10px;
    color: black;
  }

  &__header{
    display: flex;
    align-items: center;
  }

  &__badge {
    // display: flex;
    // align-items: center;
    // justify-content: center;
    // width: 40px;
    // /* Adjust as needed */
    // height: 40px;
    // /* Adjust as needed */
    // border-radius: 50%;
    // background-color: #ccc;
    // /* Add your desired background color here */
    // margin-left: auto;
    // /* Push the badge to the right */
    border-radius: 16px;
    display: grid;
    place-content: center;
    padding: 5px 16px;
    width: 100%;
    // min-width: fit-content;
    // max-width: fit-content;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__badge-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

}

.low-background-color {
  background-color: #ffc107;
}

.medium-background-color {
  background-color: $orange;
}

.high-background-color {
  background-color: $red;
}

.low-icon-color {
  color: #ffc107 !important;
}

.medium-icon-color {
  color: $orange !important;
}

.high-icon-color {
  color: $red !important;
}