@import '../../styles/index.scss';

.create-profile-form {

    width: 50vw;
}

.li {
    display: flex;
}

@media (max-width: 768px) {
    .create-profile-form {
        width: 99vw;
        padding: 10px;
    }
}