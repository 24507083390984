@import '../../../styles/index.scss';

.logo {
  display: flex;
  align-items: center;
  justify-content: center;

  &-text {
    text-transform: uppercase !important;
    letter-spacing: 2px !important;
    color: $gray;
    font-family: $font-secondary;
    font-weight: 400;
    font-size: 20px;
    margin: 0 6px;
  }
}