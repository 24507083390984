@import '../../styles/index.scss';

.care-item-factory {

    &__body {
        margin: 1em;
    }

    &__dates {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-evenly;
    }

}

.crf {
    &-header {
        width: 100%;
        border-bottom: 1px solid $primary;
    }

    &-wrapper {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 10px;
    }

    &-trigger {
        border: none;
        background-color: transparent;
        padding: 0;
        margin: 0;
        cursor: pointer;

        &:hover {
            border-bottom: 1px solid $primary;
        }
    }
}

.row__apart {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.conference-controls {
    width: 100%;
    border-bottom: 1px solid $primary;
    display: flex;
    align-items: center;

    &__btn {
        background: transparent;
        border: none;
        margin: 2px;
        padding: 0;
        cursor: pointer;
    }

    &__icon-green {
        color: $green !important;
    }

    &__icon-red {
        color: $red !important;
    }

    &__icon-grey {
        color: $gray-20 !important;
    }
}

.status-buttons {
    display: flex;
    gap: 10px; /* Adjust the gap between buttons as needed */
  }
  
  /* Style for the circular status button */
  .status-button {
    border: none;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ccc; /* Default background color for the button */
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
  }
  
  /* Style for the active status button */
  .status-button.active {
    background-color: $primary !important; /* Set the background color for active button */
    // color: white; /* Set the text color for active button */
  }
  
  /* Style for the disabled status button */
  .status-button.disabled {
    pointer-events: none; /* Disable clicking on the button */
    opacity: 0.5; /* Reduce opacity to indicate the button is disabled */
  }
  
  /* Add additional styles for icons inside the buttons if needed */
  .status-button svg {
    font-size: 20px; /* Adjust icon size as needed */
  }
  

  .no-btn{
    background-color: $red-20;
    border: 1px solid $red;
    padding: .5em 1em;
    display: flex;
    margin: 5px;
    cursor: pointer;

    &:hover{
        background-color: $red-40;
    }

}

.yes-btn{
    background-color: $green-20;
    border: 1px solid $green;
    padding: .5em 1em;
    display: flex;
    margin: 5px;
    cursor: pointer;

    &:hover{
        background-color: $green-40;
    }

}