@import '../../styles/index.scss';

.protocol-print-form {

    padding: 30px;
    width: 100%;

    &__header {
        // background-color: $gray-10;
        background-color: $primary;
        padding: 10px 0;
    }

    &__table {
        width: 100%;
        border-spacing: 0px;
        background: white;

        thead {
            // background: $gray-10;
            background: $primary;
        }

        th {
            text-align: left;
            overflow: hidden;
            padding: .75rem .5rem;
            text-align: left;
        }

        td {
            // border-bottom: 1px solid $gray-10;
            border-bottom: 1px solid $primary;
            padding: 10px;
        }
    }

    &__signature-wrapper {
        display: grid;
        place-content: center;
    }

    &__signature {
        border: 1px solid gray;
        margin-top: $space-03;
    }

    &__sig-input{
        color: $gray-80;
        font-size: 12px;
        // border-radius: $radius-sm;
        cursor: text;
        transition: background-color 300ms ease;
        padding: 8px 5px;
        margin: 5px 0;
        letter-spacing: 0.8px;
        width: 100%;
        min-height: fit-content;
        background-color: white;
        // box-shadow: $shadow;
        border: 1px solid gray !important;
        border: none;
        height: 40px;
    }

}


.sig-clear{
    border: none;
    background: none;
    cursor: pointer;
    margin: 5px;

    &:hover{
        color: $primary !important
    }

}