@import '../../../styles/index.scss';

.study-audit {

    &__pat-item{
        background-color: white;
        box-shadow: $shadow;
        padding: 1em;
        margin: 1em;
    }

}