@import '../../styles/index.scss';

.audit-action-item {

    &__active{
        background-color: $secondary-20 !important;
        border: 1px dotted $primary-20;
        box-shadow: none;
    }

    &__actions{
        display: flex;
        flex-direction: row;
        // background-color: $primary-20 !important;
        justify-content: flex-start;
    }

    &__body{
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
    }

    &__btn{
        background-color: transparent;
        border: none;
        font-size: $text-01;
        font-family: $font-primary;
        cursor: pointer;
        transition: background-color 300ms ease;
        font-weight: 300;
        text-transform: capitalize;
        margin: 0;
        padding: 0;

        &:hover{
            font-weight: 600;
        }
    }

}