@import '../../../styles/index.scss';

.patient-item {

    margin: 10px 0;

    &__body{
        padding: 10px 0;
    }

    &__cohorts-wrapper{
        display: flex;
    }
}