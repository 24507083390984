@import '../../../styles/index.scss';

.patient-scans {

    width: 100%;
    display: flex;
    flex-direction: column;
    transition: flex-basis 5s ease;
    padding: 0px 12px;
    overflow: hidden;
    // height: $workspace_vh;
    flex: 1;

    &__body {
        display: flex;
        flex-direction: row;
        transition: flex-basis 5s ease;
        overflow: hidden;
        padding: 0px 12px;
        width: 100%;

        .panel1 {
            flex: 1;
            flex-basis: 0;
            height: 77vh;
            padding: 0px .5em;
            // overflow: hidden;
        }

        .panel2 {
            flex: 1;
            flex-basis: 0;
            height: 77vh;
            padding: 0px .5em;
            // overflow: hidden;
        }

    }

    &__search-wrapper {
        width: 100%;
    }

    &__search-row {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    &__empty{
        width: 100vw;
        height: 50vh;
        display: flex;
        justify-content: center;
        align-items: center;

    }


    &__scans-wrapper {
        display: grid;
        grid-gap: 1em;
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    }

}