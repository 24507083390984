@import '../../../styles/index.scss';

.patients-table {
    width: 100%;
    border-collapse:separate; 
    border-spacing: 0 .75em;
  
    &__row{
        background-color: #fff;
        box-shadow: $shadow;
        margin: 10px 0px;
        padding: 30px 0px;
    }
  
    &__td{
        padding: 10px 0px;
    }

}