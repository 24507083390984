@import '../../../styles/index.scss';

.dicom-uploader {

    &-progressLabel {
        position: absolute;
        // width: 100;
        min-width: fit-content;
        margin-left: 5px;
        // display: grid;
        // place-content: center;
        // color: white;
        font-weight: 600;
        width: -webkit-fill-available;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &-barWrapper{
        position: relative;
    }

    &-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    &-splitwrapper {
        // background-color:red; // $dicomdrop-active-col;
        display: flex;
        flex-direction: row;
    }

    &-tagline-wrapper {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        height: 100%;
    }

    &-bridge {
        float: right;
        position: relative;
        top: 250px;
        left: 0px;
        background-color: $secondary-60;
        border-top: 2px dashed rgba($secondary, 0.5);
        border-bottom: 2px dashed rgba($secondary, 0.5);
        border-left: 0px dashed $filedrop-disabled-col;
        border-right: 0px dashed $filedrop-disabled-col;
        height: 50px;
        width: 100px;
        z-index: 10;
    }

    &-dropzone {
        background-color: $dicomdrop-active-col;
        // justify-content: center;
        // align-content: center;
    }

    &--dropzone-active {
        background-color: $background !important;
        // justify-content: center;
        // align-content: center;
    }

    &-uploadingActive {
        // background-color: $secondary-20 !important;
        // border: 0;
        margin: 5px;
        width: 50%;
    }

    &-dropzoneLoaded {
        background-color: $secondary-20 !important;
        margin: 5px;
        width: 50%;
    }

    &-tagline {
        // background-color: $red-40;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &-filelist {
        background-color: 'red' !important;
        align-self: center;
    }

    &-status {
        padding-left: 10px;
        display: flex;
        flex-direction: row;
    }

}

.css-1t7rv7f-MuiLinearProgress-root.MuiLinearProgress-colorPrimary{
    background-color: $secondary-20 !important;
}

.css-1uqe0vy.MuiLinearProgress-colorPrimary{
    background-color: $secondary-20 !important;

}

.css-1t7rv7f-MuiLinearProgress-root .MuiLinearProgress-bar {
    background-color: $secondary !important;
}

.css-1t7rv7f-MuiLinearProgress-root .MuiLinearProgress-bar{
    background-color: $secondary !important;
}