@import '../../../styles/index.scss';

.faq {

}


@media (max-width: $tablet) {
    .faq {
        margin: 3em !important;
    }    
}

@media (max-width: $phone) {
    .faq {
        margin: 15px !important;

        .custom-label--h1{
            font-size: 1.25em;

        }
        .custom-label--h4{
            font-size: 1em;
        }
        .custom-label--h6{
            font-size: .75em;
        }
        
    }    
}