@import '../../../styles/index.scss';



.userSurvey {

    &__body {
        margin: 2em 20% !important;
    }

    &__tile {
        padding-left: 2em
    }

    &__survey-icon {
        margin-right: .5em;
    }

    &__skip {
        width: fit-content;
        margin-top: 1em;
        cursor: pointer;
        margin-left: .25em;
        font-weight: 400 !important;
        background-color: transparent;
        border: none;

        &:hover {
            color: $primary !important;
        }

    }

    &__list-container {
        height: fit-content;
        margin-bottom: $space-09;
        width: 100%;
        min-width: 50vw;
    }
}

@media (max-width: $tablet) {
    .userSurvey {
        &__body {
            margin: 10px !important;
        }

    }
}