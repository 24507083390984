@import '../../styles/index.scss';

.edit-medication-form {}

.row {
    display: flex;
    width: 100%;
}

.col-30 {
    width: 33.33%;
}

.chechbox-wrapper {
    display: flex;
    align-items: center;
    margin-right: 5px;
}