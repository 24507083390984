@import '../../../styles/index.scss';


.cohorts {
    padding: 1em 8%;
    flex-direction: column;
    display: flex;
    flex: 1;

    &__header {
        // background-color: rebeccapurple;
        display: flex;
        justify-content: space-between;
        margin: 0px 15px;
        align-items: center;

        &-title {
            display: flex;
            align-items: flex-end;
            justify-content: center;
        }
    }

    &__body {
        // background-color: green;
        flex-grow: 1;
        overflow-y: scroll;
        justify-content: flex-start;
    }

    &__er {
        // background-color: magenta;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-grow: 1;
        height: 100%;
    }

}

.cohorts__body::-webkit-scrollbar {
    display: none;
}