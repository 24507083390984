@import '../../../styles/index.scss';

.hsg-login {
    flex-grow: 1;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    &__form-wrapper {
        margin: 15px;
        padding: 15px;
        box-shadow: $shadow;
        width: 40vw;
    }

    &__check-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__text-btn {
        // display: inline;
        background: transparent;
        border: none;

        font-weight: 600 !important;
        color: $primary !important;
        font-size: inherit;
        letter-spacing: inherit;
        padding: 0px;
        padding-left: 5px;


        &:hover {
            color: $secondary !important
        }
    }

    &__txt-btn {
        cursor: pointer;

        &:hover {
            color: $secondary !important
        }
    }

}

@media (max-width: 768px) {

    .hsg-login {

        &__form-wrapper {
            width: 90vw;
        }
    }

}