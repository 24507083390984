@import '../../../styles/index.scss';

.study {

    padding: 2em 20% !important;

    &__tile {
        border-radius: $radius/2;
        padding: $space-02 $space-03;
        overflow: scroll;
        width: 100%;
        background: $light;
        box-shadow: $shadow;

        &::-webkit-scrollbar {
            display: none;
        }
    }

}


@media (max-width: $tablet) {
    .study {
        padding: 10px !important;

        &__tile {
            border-radius: 0px;
            padding: 10px
        }
    }

    .study-choice-item{
        flex-direction: column;
        align-items: flex-start !important;
    }
}