@import '../../styles/index.scss';

.conference-crf {

    &__section-header {
        width: 100%;
        border-bottom: 2px solid $primary;
    }

    &__body {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    &__no-btn {
        background-color: $red-20;
        border: 1px solid $red;
        padding: .5em 1em;
        display: flex;
        margin: 5px;
        cursor: pointer;

        &-selected {
            background-color: $red-60;
            border: 1px solid $red;
            padding: .5em 1em;
            display: flex;
            margin: 5px;
            cursor: pointer;
        }

        &:hover {
            background-color: $red-40;
        }

    }

    &__yes-btn {
        background-color: $green-20;
        border: 1px solid $green;
        padding: .5em 1em;
        display: flex;
        margin: 5px;
        cursor: pointer;

        &-selected {
            background-color: $green-60;
            border: 1px solid $green;
            padding: .5em 1em;
            display: flex;
            margin: 5px;
            cursor: pointer;
    
        }

        &:hover {
            background-color: $green-40;
        }

    }

    &__pause-btn {
        background-color: $yellow-20;
        border: 1px solid $yellow;
        padding: .5em 1em;
        display: flex;
        margin: 5px;
        cursor: pointer;

        &-selected {
            background-color: $yellow-60;
            border: 1px solid $yellow;
            padding: .5em 1em;
            display: flex;
            margin: 5px;
            cursor: pointer;
        }

        &:hover {
            background-color: $yellow-40;
        }

    }

}

.red {
    color: $red !important;
}