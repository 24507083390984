@import '../../styles/index.scss';

.compliance {

    margin: 1em 20%
}

.compliance__header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}