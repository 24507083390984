@import '../../styles/index.scss';

.participant-scans {
    height: 50vh;
    // overflow: hidden;

    min-width: 300px;
    width: 100%;
    padding: 0 $space-01 ;

    &__header{
        // background-color: red;
    }
    
    &__body{
        // background-color: gold;
        overflow: scroll;
        height: 45vh;
    }

    &__search-row {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    &__item {
        padding: .75em;
        border-radius: $radius-sm;
        box-shadow: $shadow-light;
        cursor: pointer;
        list-style-type: none;
        margin: 0;
    }

    &__list{
        list-style-type: none;
        margin: 0;
        padding: 0;
    }

    &__title{
        display: flex;
        align-items: center;
    }

}

.participant-scans::-webkit-scrollbar {
    display: none;
  }
