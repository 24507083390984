@import '../../../styles/index.scss';

.user-item {
    margin: 5px 0px;
    padding: .5em;

    &__grid{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        overflow: hidden;
    }
}