@import '../../styles/index.scss';

.text {

    // margin:$space-02;
    letter-spacing: .5px;
    font-family: $font-primary;
    font-weight: 300;
    line-height: $text-02;

    &--sm{
        font-size: $text-00;
    }

    &--md{
        font-size: $text-01;
    }
    &--lg{
        font-size: $text-02;
    }

    &--primary {
        color: $primary;
    }

    &--secondary {
        color: $secondary;
    }

    &--tertiary {
        color: $tertiary;
    }

    &--defualt {
        color: $text-col;
    }

    &--ghost {
        color: $black-60;
    }

    &--danger {
        color: $danger;
    }

    &--success {
        color: $success;
    }

    &--warning {
        color: $warning;
    }

    &--white {
        color: $white;
    }

    &--uppercase {
        text-transform: uppercase !important;
        letter-spacing: 2px !important;
    }
}