@import '../../styles/index.scss';

.snapshot-browser {
    position: fixed;
    bottom: 10px;
    left: 25%;
    max-width: 60%;
    height: 8%;
    z-index: 9;
    overflow-y: hidden;
    background-color: $secondary-40;

    &__thumbnails {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: flex-start;
        max-height: 100%;
        height: 100%;
        overflow-y: hidden;
        overflow-x: auto;

        .thumbnail {
          height: 100%;
        //   background-color: red;
            position: relative;
          img {
            width: 100%;
            height: 100%;
            padding: 4px;
            object-fit: cover;
            cursor: pointer;
            position: relative;
            z-index: 10;
          }
          &:hover .thumbnail__overlay {
            display: flex;
          }
    
          &__overlay {
            display: flex;

            // display: none;
            position: absolute;
            top: 4px;
            right: 4px;
            z-index: 20;
            background-color: white;
            color: $secondary;
            margin:2px;
            width: 20px;
            height: 20px;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            border-radius: 2px;
    
            svg {
              width: 12px;
              height: 12px;
            }
          }
        }
      }

}