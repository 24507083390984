@import '../../styles/index.scss';

.patient-router {

    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    padding-right: 15px;

    &__nav {
        display: flex;
    }

    &__tab {
        background-color: transparent;
        border: none;
        font-size: $text-00;
        letter-spacing: 2px;
        font-family: $font-primary;
        text-transform: uppercase;
        font-weight: 500;
        color: $gray-40;
        margin: 0 5px;
        display: flex;
        align-items: center;
        cursor: pointer;

        &:hover {
            color: $primary;
        }

        .active {
            color: $primary !important;
        }
    }

    &__icon {
        color: $gray-40 !important;
        margin: 5px;

        .active {
            color: $primary !important;
        }
    }


}