@import '../../../styles/index.scss';

.tab-view {
    &-hidden {
        // display: none;
    }

    &-wrapper {
        border: 1px solid #ddd;
        // border-radius: 2px;
        height: auto;
        margin: 0;
        padding: 10px;
        margin: 0 auto;
        display: block;
        background-color: #fafafa;
    }

    &-header {
        display: flex;
        flex-direction: row;
        // background-color: $secondary-05;
        border-bottom: 1px solid #ddd;
    }
    &-content {
        width: 100%;
        min-height: 70vh;
        max-height: 950vh;
        background-color: #fff;
    }

    &-tab {
        margin: 0 0 5px  0;
        padding: 10px 10px 10px 10px;
        // text-align: center;
        min-width: 150px;

        &:focus {
            outline: none;
        }

        &:hover {
            outline: none;
            background-color: $secondary-20;
        }
    }

    &-vl {
        padding: 5px 0px 2px 0px;
        border-right: 1px solid #ddd;        
      }
}


// ul {
//     display: flex;
//     flex-direction: row;
//     margin: 0;
//     padding: 0;
//     list-style-type: none;
// }
// ul li {
//     display: inline;
//     margin: 0;
//     padding: 0;
// }

// a {
//     text-decoration: none;
//     // padding: 0.5em;
//     color: #b7b7b7 !important;
// }

// .selected a {
//     color: $primary !important;
// }