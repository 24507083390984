@import '../../../styles/index.scss';

.research {

    // padding: 0.75rem 10%;
    // display: flex;

    &__empty {
        height: 100vh;
        width: 100vw;
        display: grid;
        place-content: center;
    }

    &__header {
        // background-color: white;
        // border: 2px solid $primary-10;
        background-color: $background;
        box-shadow: $shadow-light;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 5em 20%;
        margin-top: -5px;
        gap: 1em;

        .aside {
            flex-grow: 1;
            white-space: nowrap;
            display: flex;
            justify-content: flex-end;
        }
    }

    &__body {
        padding: 0.75rem 20%;
    }

    &__icon {
        margin-left: 3px;
    }

    .panel1 {
        width: 30%;
    }

    .panel2 {
        width: 70%;
    }

    &__surveyheader {
        padding: 0px 15px;
    }

}

@media (max-width: $tablet) {
    .research {
        &__header {
            padding: 5em;
            flex-direction: column;
            align-items: flex-start;
        }

        &__body {
            padding: 10px;
        }

    }
}