@import '../../../styles/index.scss';

.insightec-target {

    &__img {
        // height: 50vw;
        max-width: 100%;
        max-height: 90vh;
    }

    &__h1img {
        // height: 50vw;
        max-width: 100%;
        max-height: 40vh;
    }
    &__h2img {
        // height: 50vw;
        max-width: 100%;
        max-height: 40vh;
    }
}