@import '../../styles/index.scss';

.page-layout {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: $background;
    flex-grow: 1;
    width: 100vw;
    min-height: 100vh;
    overflow-x: hidden;
    // margin-top: 10px;

    &__modal-header{
      display: flex;
      align-items: center;  
    }

    &__modal-header-text{
      margin-left: $space-03;
    }

    &__navbar {
      flex-shrink: 0;
    }

    &__body {
      flex:1;
      justify-content: flex-start;
      overflow-y: auto;
      // height: 85vh;
      // max-height: 85vh;
      // background-color: red;
      display: flex;
      flex-direction: column;
    }

    .page-layout__scrollable-content {
      padding: 10px;
      // background-color: lavender;
    }

    &__footer {
      flex-shrink: 0;
    }

    main {
      flex-grow: 1;
      background-color: $background;
      // background-image: linear-gradient(176deg, #fdfbfb 0%, $background 100%);      
      padding-top: -10px;
      width: 100vw;
      min-height: 100vh;
      // overflow: hidden;
      overflow-x: hidden;
      margin-top: 10px;
    }
}

html::-webkit-scrollbar {
  display: none;
}