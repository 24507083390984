@import '../../../styles/index.scss';

.help {

    margin: 1em 10%;

    &__flex{
        display: flex;


        .panel1 {
            flex: 40%;
            margin: 0px 1em;
        }

        .panel2{
            flex: 60%;
            margin: 0px 1em;

        }
    }

}

@media (max-width: $phablet) {
    .help {
        margin: 15px !important;

        &__title{
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &__flex{
            display: flex;
            flex-direction: column-reverse !important;
    
    
            .panel1 {
                flex: 100% !important;
                margin: 15px;
            }
    
            .panel2{
                flex: 100% !important;
                margin: 0px 15px;
    
            }
        }    }    
}