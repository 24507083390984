@import '../../styles/index.scss';

.close-audit {

    &__section-header{
        background-color: $gray-20;
        padding: 10px 0;
        width: 100%;
    }

    &__signature-wrapper{
        display: grid;
        place-content: center;
    }

    &__signature{
        border: 1px solid gray;
        margin-top: $space-03;
    }


}