@import '../../styles/index.scss';

.crf-forms {

    display: flex;
    flex-direction: column;
    width: 100%;

}
.attachments-row {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    flex-direction: row;
    
    .attachement {
        border-radius: $radius-sm;
        background: $light;
        box-shadow: $shadow-light;
        margin: 5px;
        border-color: $secondary;
        border-width: 1px;
        border-style: dotted;
        width: 150px;
        height: 200px;
        margin-right: 10px;
        padding: $space-02 $space-03;


        &.filled {
            border-style:groove;
        }
        
        &.print {
            border-style:none;
            width: auto;
            height: auto;
            max-height: 20vmax;
        }

        .attachement-image {
            max-width: 100%;
            max-height: 100%;
        }
        
        .img-full{
            width: 100%;
            height: auto;
            max-width: 700px !important
        }
        
        

        &.drag-over {
            background-color: $secondary;
            border-color: $primary;
        
            .drag-text {
              display: none;
            }
        
            .drop-text {
              display: block;
            }
          }
        
          .drag-text {
            display: block;
          }
        
          .drop-text {
            display: none;
          }
    }
}
.attachement {
    border-radius: $radius-sm;
    background: $light;
    box-shadow: $shadow-light;
    margin: 5px;
    border-color: $secondary;
    border-width: 1px;
    border-style: dotted;
    width: 150px;
    height: 200px;
    margin-right: 10px;
    padding: $space-02 $space-03;
    
    &.filled {
        border-style:groove;
    }
    .attachement-image {
        max-width: 100%;
        max-height: 100%;
    }
    &.drag-over {
        background-color: $secondary;
        border-color: $primary;
    
        .drag-text {
          display: none;
        }
    
        .drop-text {
          display: block;
        }
      }
    
      .drag-text {
        display: block;
      }
    
      .drop-text {
        display: none;
      }
}
.printStyles{
    padding: 30px;
}

.print-form{
    
    &__signature-wrapper{
        display: grid;
        place-content: center;
    }

    &__signature{
        border: 1px solid gray;
        margin-top: $space-03;
    }

}

.crf-print {

    // margin: 20px;
    padding: 30px;
    width: 100%;

    &__header {
        background-color: $primary;
        padding: 10px 0;
    }

    &__table{
        width: 100%;
        border-spacing: 2px;

        thead{
            background-color: $primary;
        }

        th{
            text-align: left;
            padding: 10px;
        }

        td {
            border: 1px solid $primary;
            padding: 10px;
        }
    }



}