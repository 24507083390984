@import '../../styles/index.scss';

.user-study-item {

    border-radius: 10px;
    padding: $space-02 $space-03;
    overflow: scroll;
    width: 100%;
    background: $primary-gradient;
    margin: 10px 0px;
    cursor: pointer;

    &::-webkit-scrollbar{
        display: none;
    }
}