@import '../../styles/index.scss';

.checkbox {

    display: flex;
    align-items: center;

    &__icon{
        color: $primary !important;
    }

}

.css-j204z7-MuiFormControlLabel-root{
    margin-left: 0 !important;
    margin-right: 0 !important;
}