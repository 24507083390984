@import '../../../styles/index.scss';

.study-drive-by-site {

    &__item{
        margin: 5px 0;
    }

    &__indent1{
        margin: 5px 0px 5px 20px;
    }

}