.main {

    display: flex;
    align-items: center;
    justify-content: center;
    &__img {
        // height: 50vw;
        max-width: 100%;
        max-height: 90vh;
    }

}
