@import '../../../styles/index.scss';

.data-table {
  width: 100%;
  border-collapse: collapse;


  &__arrow-btn{
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    margin: 0 .5rem;

    :disabled{
      color: lightgray
    }

    &__searchbox{
      min-width: 200px;
    }

  }

  tbody {
    tr {
      transition: all 300ms ease;

      &:hover {
        background-color: $primary-10;
      }
    }
  }


  th {
    text-align: left;
    padding: $space-01 $space-03;

    &:first-child {
      padding-left: $space-06;
    }

    &:last-child {
      padding-right: $space-06;
    }
  }

  td {
    padding: $space-03;

    &:first-of-type {
      padding-left: $space-06;
    }
    &:last-child {
      padding-right: $space-06;
    }
  }
}

.data-table-container{
  height: 60vh;
  min-height: 60vh;
  max-height: 70vh;
  &__tile {
    border-radius: $radius;
    padding: $space-02 $space-03;
    overflow: scroll;
    box-shadow: $shadow;
    color: $text-col;
    background: $light;
    min-height: 70vh;
  }

  &__tile::-webkit-scrollbar {
    display: none;
  }
}

.align-item {
  height: 56vh;
  display: flex;
  align-items: center;
}

.table-header {

    border-bottom: 2px solid $gray-20;

    &__cirlce-btn{
      display: grid;	
      place-items: center;
    }
    th {
    text-transform: uppercase !important;
    letter-spacing: 2px !important;
    color: $gray;
    font-size: $text-01;
    font-weight: 400;
    letter-spacing: 1.2px;
    font-size: $text-00;
    font-family: $font-primary;
    }
}

.table-cell{
  font-size: $text-00;
  letter-spacing: .5px;
  font-family: $font-secondary;
  font-weight: 300;
}

.table {

  &__e404{
    width: 100%;
    padding: $space-04 $space-11;
    display: grid;
    place-items: center;
  }
  &__button-row{
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
  }


}

.table-row{
  border-bottom: 1px solid $gray-10;
}

.__actions-header{
  display: grid;
  place-items: center;
}
.__action-btn {
  // width:fit-content;
  display: grid;
  place-items: center;
  justify-content: center;
}