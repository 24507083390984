@import '../../styles/index.scss';

.tile {
    // border-width: 1px;
    // border-style: solid;
    border-radius: $radius-sm;
    padding: $space-02 $space-03;
    overflow: scroll;
    // ::-webkit-scrollbar {
    //   display: none;
    // }
    margin: 15px;
    background: $light;
    &--shadow {
      box-shadow: $shadow;
      margin: 15px;
      &--none {
        box-shadow: none;
        margin: 0px;
      }
      &--light {
        box-shadow: $shadow-light;
        margin: 15px;
      }
      &--default {
        box-shadow: $shadow;
      }
      &--strong {
        box-shadow: $shadow-strong;
        margin: 25px;
      }
     
    }
    
    &:hover {
      cursor: pointer;
      box-shadow: $shadow-hover;
      transition: 0.2s;
    }
    
    &--default {
      color: $text-col;
      background: $light;
    }

    &--primary {
      // border-color: $primary;
      color: $white;
      background: $primary;
      // background: linear-gradient(90deg, $primary-20 0%, $primary-40 100%);
  
    }
  
    &--secondary {
      // border-color: $secondary;
      color: $white;
      background: $secondary;
    }
  
    &--tertiary {
      // border-color: $tertiary;
      // color: $tertiary;
      color: $white;
      background: $tertiary;
    }
    
    &--success {
      // border-color: $success;
      // color: $success;
      color: $white;
      background: $green;
    }
  
    &--danger {
      // border-color: $danger;
      // color: $danger;
      color: $white;
      background: $red;
    }
  }
  
  .tile::-webkit-scrollbar {
      display: none;
    }
  