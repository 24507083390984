@import '../../styles/index.scss';

.spinner {

}

.css-3odkk4-MuiCircularProgress-root{
    position: relative !important;
}

.css-1itxsv6{
    position: relative !important;
}
