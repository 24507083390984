@import '../../styles/index.scss';

.audit-list-item {

    // display: flex;
    width: 100%;
    // justify-content: space-between;
    display: grid;
    grid-template-columns: 1fr minmax(150px, 15%);
    padding: $space-03;

    &__status{
        height: 100%;
        margin: auto 1em;
        display: flex;
        align-items: center;
    }

}
