@import '../../../styles/index.scss';

.participant-analysis {
    margin: 0 1em;
    // background-color: red;

    &__header {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
    }
    &__body {
        // background-color: rgb(140, 255, 87);
        height: 85vh;
    }
    &__progress {
        max-width: 25vh;
    }

}