@import '../../../styles/index.scss';

.cohort-user-item {

    margin: 10px 0;

    &__body{
        padding: 10px 0;
    }

    &__cohorts-wrapper{
        display: flex;
    }

}