$phone: 37.438em; // 599px
$phablet: 37.5em; //600px
$tablet: 56.25em; // 900px
$laptop: 75em; // 1200px
$desktop: 90em; // 1440px

$landscape: '(orientation: landscape)';

$phone-only: '(max-width: #{$phone})';
$phablet-only: '(min-width: #{$phablet}) and (max-width: #{$tablet})';
$tablet-only: '(min-width: #{$tablet}) and (max-width: #{$laptop})';
$laptop-only: '(min-width: #{$laptop}) and (max-width: #{$desktop})';

$up-to-phablet: '(max-width: #{$phablet})';
$up-to-tablet: 'max-width: #{$tablet}';
$up-to-laptop: '(max-width: #{$laptop})';
$up-to-desktop: '(max-width: #{$desktop})';

$phablet-up: '(min-width: #{$phablet})';
$tablet-up: '(min-width: #{$tablet})';
$laptop-up: 'min-width: #{$laptop}';
$desktop-up: '(min-width: #{$desktop})';

// fonts
$font-primary: 'Inter', sans-serif !important;
$font-secondary: "Inter", sans-serif !important;


/*
 *  Font Sizes
 */
$text-07: 3.375em;
$text-06: 2.625em;
$text-05: 2em;
$text-04: 1.5em;
$text-03: 1.25em;
$text-02: 1em;
$text-01: 0.875em;
$text-00: 0.75em;
$text-005: 0.65em;
$text-xs: 0.5em;

/*
 *  SPACING
 */
$space-16: 10rem;
$space-15: 4rem;
$space-14: 3.75rem;
$space-13: 3.5rem;
$space-12: 3.25rem;
$space-11: 3rem;
$space-10: 2.75rem;
$space-09: 2.5rem;
$space-08: 2.25rem;
$space-07: 2rem;
$space-06: 1.75rem;
$space-05: 1.5rem;
$space-04: 1.25rem;
$space-03: 1rem;
$space-02: 0.75rem;
$space-01: 0.5rem;
$space-00: 0.25rem;

/*
 *  COLORS
 */
$black: #171717;
$white: #ffffff;
$gray: #5a5a5a;
$light-gray: #f3f6f8;
$blue: #0052cc;
$light-blue: #4e8fef;
$yellow: #ff991f;
$purple: #5243aa;
$dark-purple: #6a3880;
$green: #94c337;
$light-green: #c0ee65;
$red: #ea1616;
$orange: #d27827;


// Black
$black-100: $black;
$black-80: rgba($black, 0.8);
$black-60: rgba($black, 0.6);
$black-40: rgba($black, 0.4);
$black-20: rgba($black, 0.2);
$black-10: rgba($black, 0.1);
$black-05: rgba($black, 0.05);

// Gray
$gray-100: $gray;
$gray-80: rgba($gray, 0.8);
$gray-60: rgba($gray, 0.6);
$gray-40: rgba($gray, 0.4);
$gray-20: rgba($gray, 0.2);
$gray-10: rgba($gray, 0.1);
$gray-05: rgba($gray, 0.05);

// Gray
$light-gray-100: $light-gray;
$light-gray-80: rgba($light-gray, 0.8);
$light-gray-60: rgba($light-gray, 0.6);
$light-gray-40: rgba($light-gray, 0.4);
$light-gray-20: rgba($light-gray, 0.2);
$light-gray-10: rgba($light-gray, 0.1);
$light-gray-05: rgba($light-gray, 0.05);

// Blue
$blue-100: $blue;
$blue-80: rgba($blue, 0.8);
$blue-60: rgba($blue, 0.6);
$blue-40: rgba($blue, 0.4);
$blue-20: rgba($blue, 0.2);
$blue-10: rgba($blue, 0.1);
$blue-05: rgba($blue, 0.05);

// Yellow
$yellow-100: $yellow;
$yellow-80: rgba($yellow, 0.8);
$yellow-60: rgba($yellow, 0.6);
$yellow-40: rgba($yellow, 0.4);
$yellow-20: rgba($yellow, 0.2);
$yellow-10: rgba($yellow, 0.1);
$yellow-05: rgba($yellow, 0.05);

// Purple
$purple-100: $purple;
$purple-80: rgba($purple, 0.8);
$purple-60: rgba($purple, 0.6);
$purple-40: rgba($purple, 0.4);
$purple-20: rgba($purple, 0.2);
$purple-10: rgba($purple, 0.1);
$purple-05: rgba($purple, 0.05);

// Green
$green-100: $green;
$green-80: rgba($green, 0.8);
$green-60: rgba($green, 0.6);
$green-40: rgba($green, 0.4);
$green-20: rgba($green, 0.2);
$green-10: rgba($green, 0.1);
$green-05: rgba($green, 0.05);

// Red
$red-100: $red;
$red-80: rgba($red, 0.8);
$red-60: rgba($red, 0.6);
$red-40: rgba($red, 0.4);
$red-20: rgba($red, 0.2);
$red-10: rgba($red, 0.1);
$red-05: rgba($red, 0.05);


// Main colors
$primary: #dd985b;
$secondary: #00fd33;
$tertiary: #c97ab1;
$invalid: $red;
$danger: $invalid;
$warning: $yellow;
$info: $light-blue;
$success: $light-green;
$disabled: #6C7181;
// $background: #F5F6FA;
$background: #fdfdff;
$foreground: white;
$light: #FFFFFF;
$disabled-background: $light-gray;
$disabled-background-01: #e0e0e0;
$text-col: $black-80;
$alt-text-col: $light-gray;

$primary-100: $primary;
$primary-80: rgba($primary, 0.8);
$primary-60: rgba($primary, 0.6);
$primary-40: rgba($primary, 0.4);
$primary-20: rgba($primary, 0.2);
$primary-10: rgba($primary, 0.1);
$primary-05: rgba($primary, 0.05);

$secondary-100: $secondary;
$secondary-80: rgba($secondary, 0.8);
$secondary-60: rgba($secondary, 0.6);
$secondary-40: rgba($secondary, 0.4);
$secondary-20: rgba($secondary, 0.2);
$secondary-10: rgba($secondary, 0.1);
$secondary-05: rgba($secondary, 0.05);

$tertiary-100: $tertiary;
$tertiary-80: rgba($tertiary, 0.8);
$tertiary-60: rgba($tertiary, 0.6);
$tertiary-40: rgba($tertiary, 0.4);
$tertiary-20: rgba($tertiary, 0.2);
$tertiary-10: rgba($tertiary, 0.1);
$tertiary-05: rgba($tertiary, 0.05);

$invalid-100: $invalid;
$invalid-80: rgba($invalid, 0.8);
$invalid-60: rgba($invalid, 0.6);
$invalid-40: rgba($invalid, 0.4);
$invalid-20: rgba($invalid, 0.2);
$invalid-10: rgba($invalid, 0.1);
$invalid-05: rgba($invalid, 0.05);

/*
 *  UI
 */
$radius: 1px;
$radius-sm: 2px;
$radius-pill: 1px;
$shadow: 0 3px 15px rgba(0,0,0,.08);
$shadow-light: 0 3px 10px rgba(0,0,0,.08);
$shadow-strong: 0 8px 20px rgba(0,0,0,.48);
$shadow-hover: 0 15px 25px rgba(0,0,0,0.09);
$shadow-full: 15px 15px 15px rgba(0,0,0,0.09);
$card-shadow: 0 5px 15px rgba(0,0,0,.08);
$inset-shadow: 0 5px 15px rgba(0,0,0,.08) inset;
$inset-shadow-light: 0 5px 15px rgba(0,0,0,.05) inset;
$inset-shadow-focus: 0 5px 15px rgba(0, 0, 0, 0.137) inset;
