@import '../../styles/index.scss';

.modal-md .MuiDialog-paperWidthSm {
    padding: $space-05 $space-07;
    // border-radius: $radius;
    min-width: 40vw;
    // min-height: 30vh;
}
.modal-lg .MuiDialog-paperWidthSm {
    padding: $space-05 $space-07;
    // border-radius: $radius;
    min-width: 816px;
    // min-height: 20vh;
}
.modal-sm .MuiDialog-paperWidthSm {
    padding: $space-05 $space-07;
    // border-radius: $radius;
    min-width: 30vw;
    // min-height: 30vh;
    // display: flex;
    // flex-direction: row;
    // align-items: center;
    // justify-content: center;
}



.modal {
    &__header{
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }
    &__header2{
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
    }

    // &__body{
    //   height: 100%;
    //   width: 100%;
    // }
}