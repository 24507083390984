@import '../../styles/index.scss';

.smart-crf {
  display: flex;
//   height: 100%;
  min-height: 60vh;
//   max-height: 62vh;
  overflow: hidden;
  
  .left-panel {
    flex: 1;
    align-items: flex-start;
    overflow-y: auto;
    /* Add styles for the left panel */
  }

  .right-panel {
    align-items: flex-start;
    margin-left: 5px;
    flex: 0 0 25px;
    display: flex;
    flex-direction: row;
    align-items: center;
    // background-color: $secondary-10;
    transition: flex 0.3s ease;

    &.active {
      flex: 0 0 50%;
      align-items: flex-start;
    }

    .toggle-button {
      background-color: transparent;
      border: none;
      padding: 0;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #333;
      font-size: 24px;
      transition: transform 0.3s, opacity 0.3s;

      &:hover {
        opacity: 0.8;
      }

      &.active {
        transform: rotate(180deg);
      }
    }

    .right-panel-content {
      flex: 1;
      display:flex;
      flex-direction: column;
      overflow-y:hidden;
    //   max-height: 65vh;
      background-color: transparent;
      /* Add styles for the right panel content */

      &.active {
        display: flex;
        // background-color: rgb(179, 255, 0);
      }

      &.hidden {
        display: none;
      }
    }
  }
}
