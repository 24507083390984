@import '../../styles/index.scss';

.registry-patient {

    padding: 10px 0px;

    &__cell {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__tracker-label{
        margin: 0 1em;
    }

    &__col{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

}

.registry-patient-status-tracker {
    display: flex;
    // align-items: center;
    // justify-content: center;
}

.status-tracker-item {
    display: flex;
    align-items: center;
    justify-content: center;
}

.status-tracker-divider {
    height: 5px;
    width: 20px;
    border-top: 2px solid $gray-40;
}

.status-tracker-divider-success {
    height: 5px;
    width: 20px;
    border-top: 2px solid $primary;
}

.status-tracker-success {

    &__icon {
        color: $primary !important;
        margin: 2px;
    }

    &__wrapper {
        border: 2px solid $primary;
        // border: none;
        background-color: white;
        border-radius: 50%;
        height: 35px;
        width: 35px;
        margin: 3px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }

}

.status-tracker-inprogress {

    &__icon {
        color: $primary !important;
        margin: 2px;
    }

    &__wrapper {
        border: 2px solid $primary;
        // border: none;
        background-color: white;
        border-radius: 50%;
        height: 35px;
        width: 35px;
        margin: 3px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }

}

.status-tracker-pending {

    &__icon {
        color: $gray-40 !important;
        margin: 2px;
    }

    &__wrapper {
        border: none;
        // border: none;
        background-color: white;
        border-radius: 50%;
        height: 35px;
        width: 35px;
        margin: 3px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }

}