@import '../../styles/index.scss';

$dicom-viewer-overlay-spacing: 3px;

.dicom-viewer--wrapper{
  display: flex;
  flex: 1;
  // max-height: 45vh;
}
.dicom-viewer3-d_loading-wrapper{
  display: flex;
  flex: 1;
  flex-direction: row;
  background-color: aquamarine;
}
.viewer3d{
  display: flex;
  // min-height: 200px;
  // min-width: 200px;
  height: 500px;
  width: 500px;
  // flex: 1;
  align-items: center;
  justify-content: center;

}

.dicom-viewer3-d {
  position: relative;
  background-color: none;
  // min-height: 65vh;
  // height: 80%;
  flex: 1;
  // max-height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &__loading-overlay {
    max-width: 100px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 20;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999; // Adjust the z-index value to ensure the overlay appears above the content
  }

  &__body {
    position: relative;
    height: 100%;
    overflow: hidden;
    width: 100%;
    background-color: $black; //secondary-60
    
    &__overlay  {
      position: absolute;
      color: white;
      font-size: x-small;
      // font-weight: bold;
      z-index: 10;

      &-top-left {
        top: $dicom-viewer-overlay-spacing;
        left: $dicom-viewer-overlay-spacing;
      }

      &-top-right {
        top: $dicom-viewer-overlay-spacing;
        right: $dicom-viewer-overlay-spacing;
      }

      &-bottom-left {
        bottom: $dicom-viewer-overlay-spacing;
        left: $dicom-viewer-overlay-spacing;
      }

      &-bottom-right {
        bottom: $dicom-viewer-overlay-spacing;
        right: $dicom-viewer-overlay-spacing;
      }
    }

    &__viewer {
      background-color: $black;
      height: 99%;
      max-height: 99%;
      
      & canvas {
        position: unset;
        background-color: $black;
        // min-height: 65vh;
        // max-height: 65vh;
        width: 100%;
        height: 100%;
      }
    }
  }

  &__toolbar {
    flex: 0;
    // width: 100%;
    min-height: 40px;
    margin-top: 1%;
    // overflow: scroll;
    // overflow-y: hidden;
    border: 1px solid #ddd;
  }

  &__toolbar::-webkit-scrollbar {
    display: none;
  }

  &__slider_wrapper {
    display: flex;
    justify-content: center;
    flex: 0;
    // width: 100%;
    margin-top: 2%;
    margin-bottom: 2%;
    // background-color: red;
    .slider {
      accent-color: $secondary;
    }
  }
}
