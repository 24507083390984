@import '../../styles/index.scss';

.care-pathway-visit-attachement {

    border-radius: 5px;
    background: $gray-10;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.08);
    border-color: #CAD9DE;
    border-width: 1px;
    border-style: dotted;
    width: 100%;
    height: 300px;
    margin-right: 10px;
    padding: 0.25rem 0.5rem;
    overflow: scroll;

    &::-webkit-scrollbar{
        display: none;
    }

    &__empty {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__item {
        // height: 100%;
        // width: 100%;
        margin: 10px;
        padding: 10px;
        background-color: white;
        overflow: hidden;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        &::-webkit-scrollbar {
            display: none;
        }
    }

    &__icon {
        color: $gray-60;
    }

}