@import '../../../styles/index.scss';

.visit-item__wrapper {
    
    display: flex;
    flex-direction: row;
    justify-content:space-between;
    align-items: center;
    align-content: center;
    
    .indicator{
        flex:0;
        align-items: center;
    }

    .patient-indicator {
        flex:0;
        align-items: center;
        margin-left: 0.25em 1px;
    }
}

.visit-item {
    flex:1;
    margin: 0.5em 1px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: space-between;

    &.condensed {
        margin: 0.5em 1px;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: space-between;
        // border-radius: 50px;
        // text-align: center;
    }
    

    &__check {
        color: $success !important;
        display: grid;
        align-content: center;
    }

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        &.condensed {
            // text-align: center;
            justify-content: space-between;
        }

    }

    &__flag {
        color: $danger !important;
        display: grid;
        align-content: center;
        // width: 100%;
    }

    &__date-list {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }

    &__check-wrapper {
        margin-top: 3px;
        // margin-right: .5em;
    }

    &__body {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    &__date {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }

    &__card {
        // padding: $space-02;
        padding: .75em;
        border-radius: $radius-sm;
        box-shadow: $shadow-light;
        cursor: pointer;
        // overflow: scroll;
    }

    &__card:hover {
        color: $primary !important
    }

    &__card-selected {
        background-color: $primary-10;
        border: 1px dotted $primary-20;
        box-shadow: none;
    }

    .status {
        // background-color:rgb(0, 115, 255);
    }

    .condensed {

        // background-color:rgb(146, 219, 64);
        &.status {
            display: none;
        }

        
    }
}


.visit-item__card-selected::-webkit-scrollbar {
    display: none;
}

.visit-item__card ::-webkit-scrollbar {
    display: none;
}

.visit-item:hover {
    background-color: $primary-10;
}