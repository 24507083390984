@import '../../../styles/index.scss';

.patient {

    padding: $page-padding;
    width: 100%;
    overflow: scroll;

    &::-webkit-scrollbar{
        display: none;
    }
    
}