@import '../../../styles/index.scss';

.patient-assessments {

    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    transition: flex-basis 5s ease;
    padding: 0 12px;
    overflow: hidden;
    // height: $workspace_vh;
    flex: 1;

    &__body {
        display: flex;
        flex-direction: row;
        transition: flex-basis 5s ease;
        overflow: hidden;
        width: 100%;

        .panel1 {
            flex: 1;
            flex-basis: 0;
            height: 70vh;
            // overflow: hidden;
        }

        .panel2 {
            flex: 1;
            flex-basis: 0;
            height: 70vh;
            overflow: scroll;

            &::-webkit-scrollbar{
                display: none;
            }
        }

    }

    &__sessions {
        display: flex;

        &-add-btn {
            // background-color: $gray-10;
            // color: $primary;
            // padding: 5px 10px;
            // margin: 0 5px;
            // display: flex;
            // align-items: center;
            // cursor: pointer;
            // border: 2px dashed $gray-20;
            // border-radius: 5px;

            &:hover {
                color: $primary;
            }
        }

        &-icon {
            color: $primary !important;
        }
    }

}

.patient-sessions {
    &__header {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__body {
        display: flex;
        flex-direction: row;
        transition: flex-basis 5s ease;
        overflow: hidden;
        width: 100%;

        .panel1 {
            flex: 1;
            flex-basis: 0;
            padding: 0 5px;
        }

        .panel2 {
            flex: 1;
            flex-basis: 0;
            padding: 0 5px;
        }

    }

    &__icon {
        color: $primary !important;
    }
}