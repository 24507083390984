body {
  font-family: 'Lato', sans-serif;
  color: $black;
  font-size: 16px;
  margin: 0;
}

a {
  color: $secondary;
  text-decoration: none;
  transition: color 300ms ease;

  &:hover {
    color: darken($secondary, 10%);
  }
}

footer{
  background-color: $background;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}
.text-left {
  text-align: left;
}

.h-100 {
  height: 100%;
}

.w-100 {
  width: 100%;
}

.icon-btn{
  color: $primary;
  cursor: pointer !important;
  margin-left: $space-00;
  margin-right: $space-00;
  background-color: none !important;
  border: none !important;
  // z-index: 100;
  pointer-events: auto;
}

.icon-btn:hover {
  color: $secondary;
  z-index: 100;
}


.margin-auto {
  margin-left: auto;
  margin-right: auto;
}

.d-flex{
  display: flex;
}

.flex-center{
  justify-content: center;
}

.d-flex-row{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.d-flex-col{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.d-flex-row-center{
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.no-overflow{
  overflow: hidden;
}

.grow{
  flex-grow: 1;
}

.super-centered{
  width: 100%;
  display: grid;
  place-content: center;
}

.indent {
  margin-left: $space-04;
}

._center{
  display: flex;
  justify-content: center;
}
._left{
  display: flex;
  justify-content: flex-start;
}
._right{
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
._make-row{
  display:flex;
  flex-direction: row;
  align-items: center;
}
._apart {
  display: flex;
  justify-content: space-between;
}
._align-item {
  display: flex;
  align-items: center;
}

.btn-row{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.d-flex{
  display: flex;
}

.flex-row{
  flex-direction: row;
}

.align_right{
  display: flex;
  align-items: center;
  justify-content: flex-end;
}