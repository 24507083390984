@import '../../styles/index.scss';

.seizure-frequency-form {}

.my-form {
    transition: max-height 0.3s ease-in-out, opacity 0.3s ease-in-out;
    max-height: 500px;
    /* Adjust to your content's height */
    overflow: hidden;
    opacity: 1;
}

.my-form.collapsed {
    max-height: 0;
    opacity: 0;
}

.sz-info-toggle{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.checkbox-wrapper{
    display: flex;
    align-items: center;
    
}