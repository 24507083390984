@import '../../styles/index.scss';

.care-list-item {
    border: 1px dotted $gray-60;
    box-shadow: none;
    padding: 0.75em;
    border-radius: 5px;
    flex: 1 1;
    margin: 0.5em 1px;
    cursor: pointer;
    pointer-events: auto;

    &__header{
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
    }

    &:hover{
        box-shadow: $shadow;
    }

    &-selected {
        border: 1px dotted $gray-60;
        background-color: $primary-10;
        box-shadow: none;
        padding: 0.75em;
        border-radius: 5px;
        flex: 1 1;
        margin: 0.5em 1px;
        cursor: pointer;
    }


    // &__body{
    //     display: flex;
    //     align-items: center;
    // }

    // &__avatar{
    //     background-color: $primary;
    //     padding: 10px;
    //     border-radius: 50px;
    // }
}