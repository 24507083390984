@import '../../../styles/index.scss';

.conferences {
    // padding: 1em 4%;
    flex-direction: column;
    display: flex;
    flex: 1;
    padding: 10px 15px;


    // .header {
    //     flex: 1;

    //     &-title {
    //         display: flex;
    //         min-width: fit-content;
    //     }

    //     display: flex;
    //     width: 100%;
    //     align-items: center;
    //     justify-content: space-between;
    //     padding: 0 1rem;
    // }

    &__empty {
        height: 50vh;
        display: grid;
        place-content: center;
        width: 100%;
    }

    &--body {
        flex: 3;
        display: flex;
        flex-direction: row;
        overflow-y: hidden;
        gap: 20px;

        .conferences-mgr {
            flex: 1;
            display: flex;

        }

        .ready {
            flex: .25;
            display: flex;
            // background-color: red;
            flex-direction: column;
            overflow-y: hidden;
        }
    }

    .footer {
        flex: 0;
        display: flex;
    }
}