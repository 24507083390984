@import '../../../styles/index.scss';

.registries {
    padding: 1em 8%;
    flex-direction: column;
    display: flex;
    flex: 1;

    &__header {
        // background-color: rebeccapurple;
        display: flex;
        justify-content: space-between;
        margin: 0px 15px;
        align-items: center;

        &-title {
            display: flex;
            align-items: flex-end;
            justify-content: center;
        }
    }

    &__body {
        // background-color: green;
        flex-grow: 1;
        overflow-y: scroll;
        justify-content: flex-start;

        &::-webkit-scrollbar {
            display: none;
        }
    }


}