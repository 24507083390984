@import '../../styles/index.scss';

.question {

    &__wrapper{
        // border-bottom: 1px solid #e5e7eb;
        // padding-bottom: 2em;
    }

    .label-container {
        // display: flex;
        // flex-direction: column;
        // align-items: center;
        color: $text-col;
        text-align: center;
    }

    .options-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

    .matrix-container {
        display: flex;
        justify-content: space-around;
        width: 100%;
        flex-wrap: wrap;
    }

    .matrix-item {
        width: 32%;
        margin: 8px 0;
    }
}