@import '../../../styles/index.scss';

.met-review {

}

.report-img{
    width: 100%;
    padding: 1em 2em;
    background-color: $gray-10;
}