@import '../../styles/index.scss';

.form-item__list {
    width: 100%;
    display: flex;
    align-items: center;
    background: inherit;
    // border: 1px solid $black-20;
    color: $black-60;
    margin-bottom: 0.6em;
    min-height: 1em;
    height: 100%;
    padding: 0px 1em;
    border-radius: $radius;
    cursor: pointer;
    transition: 0.2s;
    word-break: normal;
    margin-bottom: 10px;

    &-label {
        margin-left: 10px;
    }


    &--no-check {
        min-height: 5em;

        .question__list-label {
            width: 100%;
            text-align: center;
        }
    }

    &--with-check {
        .question__list-label {
            margin-left: 15px;
        }
    }

    &--true {
        // background: $primary-40;
        // border-color: $primary-60;
        color: darken($primary, 50%);
        // color: $white
    }

    &:hover {
        color: $primary !important;
        // box-shadow: $shadow;
    }

    .selected-icon {
        transform: scale(1.2);
    }
}

.form-item__options-container {
    margin-top: 5px;
}


.span-one {
    grid-column: span 1;
}

.span-two {
    grid-column: span 2;
}

.form-item__checklist{
    display: flex;
    align-items: flex-start;
}
