@import '../../../styles/index.scss';

.chat {

    &__wrapper {
        padding: 5px;
        display: grid;
        grid-template-rows: 1fr auto;
    }

    &__body {
        min-height: 200px;
        min-width: 300px;
        max-width: 400px;
        max-height: 300px;
        overflow: scroll;
        border: .5px solid lightgray;
    }

    &__footer {
        min-height: 20px;
        // background-color: aqua;
    }

    &__prev-messages-wrapper{
        display: flex;
        flex-direction: column;
        // align-items: flex-start;
        padding: 2px 9px;
    }

    &__prev-message-body{
        background-color: $secondary-20;
        border-radius: 5px;
        padding: 10px;
        box-shadow: $shadow;
        margin-bottom: 5px;
    }
    &__prev-message-item{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    &__prev-message-mine-body{
        background-color: white;
        border-radius: 5px;
        padding: 10px;
        box-shadow: $shadow;
        margin-bottom: 5px;
        text-align: right;
    }

    &__prev-message-mine-item{
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }

    &__button{
        border: 2px solid $secondary;
        // border: none;
        background-color: white;
        border-radius: 50%;
        height: 35px;
        width: 35px;
        margin: 3px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__icon{
        color: $secondary;
    }
}

.chat__body::-webkit-scrollbar{
    display: none;
  }