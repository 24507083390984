@import '../../../styles/index.scss';

.upload-zone {
    width: 100%;
    // margin: 0 0 0 10px ;
    &-wrapper{
		// display: grid;
        // grid-template-rows: 1fr auto;
        background-color: rgba($success, 0.15) !important;
        position: relative;
        border: 0px dashed $secondary-40;
        padding: 1rem;
        border-radius: 16px;
        max-height: 350px;        
        min-height: 350px;
        overflow: scroll;
        overflow-y: auto;
        overflow-x: hidden;
        overflow-wrap: break-word;

        &:hover {
            outline: none;
            box-shadow: $inset-shadow-light;
        }
	}

    // &-active{
    //     background-color: $filedrop-active-col;
    // }

   

    &:focus {
		outline: none;
        
	}

    &:hover {
		outline: none;
        
	}
}