@import '../../../styles/index.scss';

.add-met {
    &__paste {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        width: 200px;
        align-items: center;
        margin-bottom: 5px;
    }
}