@import '../../../styles/index.scss';

.switch {

    display: flex;
    align-items: center;

}

// .css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked{
//     color: $primary !important;
// }