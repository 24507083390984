@import '../../../styles/index.scss';

.clinic-files {
    padding: 1em;
    flex: 1;
    flex-direction: column;
    display: flex;

    &__header {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__body{
        margin: 0px 15px;
    }

    &__icon {
        margin-left: 5px;
    }

    &__empty {
        height: 50vh;
        display: grid;
        place-content: center;
        width: 100%;
    }


    &__table {
        background: white;
        border-spacing: 0px;
        // width: max-content;
        table-layout: fixed;
        min-width: 100%;
        padding: 0 $space-03;
        padding-bottom: 10px;

        tbody {
            overflow-y: auto;
            min-height: 200px;
        }

        td,
        th {
            text-align: center;
            overflow: hidden;
            padding: .75rem .5rem;
            text-align: left;
        }

        &__row:hover {
            background-color: $primary-20;
        }

        th {
            height: 100%;
            font-family: $font-primary;
            font-size: $text-00;
            text-transform: uppercase !important;
            font-weight: 400;
            letter-spacing: 1px;
            border-bottom: 3px solid $primary-20;

        }

        td {
            cursor: pointer;
            border-bottom: 1px solid $primary-20;
        }
    }

}