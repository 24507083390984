@import '../../../styles/index.scss';

.met-table {
  &__image-prev {
    display: grid;
    place-content: center;
    width: 100%;
  }

  &__image-prev {
    .img-preview {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
    }

    .image-container {
      position: relative;

      img {
        width: 100%;
        // max-width: 200px; // Adjust as needed
        height: auto;
        border: 1px solid #ddd;
        border-radius: 4px;
      }

      .overlay {
        position: absolute;
        top: 0;
        right: 0;
        padding: 5px;
        border-radius: 0 0 0 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        // button {
        //   background: transparent;
        //   border: none;
        //   font-size: 1.2rem;
        //   color: red;
        //   cursor: pointer;
        // }
      }
    }

  }

}

.imagetd {

  &__td {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  vertical-align: middle !important;
  /* Vertical centering */
  text-align: center !important;

  &__upload {
    height: 30px;
    width: 30px;
    border-radius: $radius-sm;
    background: $light;
    box-shadow: $shadow-light;
    margin: 5px;
    border-color: $secondary;
    border-width: 1px;
    border-style: dotted;
    display: grid;
    place-content: center;
  }

  &__drag-over {
    background-color: $secondary;
    border-color: $primary;

    .drag-text {
      display: none;
    }

    .drop-text {
      display: block;
    }
  }
}