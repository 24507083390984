@import '../../../styles/index.scss';

.aemrs {

    &__input {
        color: $gray-80;
        font-size: $text-00;
        border-radius: 2px;
        border-color: $gray-10;
        border-style: solid;
        cursor: text;
        transition: background-color 300ms ease;
        // padding: $space-03 $space-02;
        padding: 9px 32px 9px 12px;
        margin: $space-03 0;
        border-width: 1px;
        letter-spacing: 0.8px;
        width: 100%;
        background-color: white;

        &--disabled {
            background-color: $disabled-background !important;
        }

        &::placeholder {
            color: $gray-60;
            font-family: $font-secondary;
            font-weight: 300;
            letter-spacing: 0.8px;
        }

        &:focus {
            border-color: $primary-20 !important;
            background-color: $primary-10 !important;
        }

        &:hover {
            border-color: darken($gray-20, 30%);
        }

    }

    &__reset{
        color: white !important;
    }

}

.textfield {
  font-family: $font-primary;
  position: relative;
  background-color: $white !important;

  &__select {
    border: none;
    padding: 10px;
    font-family: $font-primary;
    // border-right: 2px solid $primary-60;
  }

  &__input {
    border: 1px solid $gray-10;
    display: flex;
    align-items: center;
    padding: 10px;
    // box-shadow: $shadow;

    &--focused {
      box-shadow: $shadow;
    }

    &-field {
      width: 100%;
      border: 0px;
      padding-left: 10px;

      &:focus {
        outline: none;
      }

      &::-webkit-input-placeholder {
        font-family: $font-primary;
      }
    }

    &-adornment {
      padding-left: 10px;
    }

    &:focus {
      outline: none;
      box-shadow: $shadow;

      +.textfield__adornment {
        &-placeholder {
          display: none;
        }
      }
    }
  }

  &__adornment {
    position: absolute;
    top: 8px;
    left: 12px;

    &-text {
      color: $text-col;
    }

    &-placeholder {
      margin-left: 5px;
      color: $gray;
    }
  }
}