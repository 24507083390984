@import '../../styles/index.scss';

.care-step {

    height: 80vh;
    overflow: scroll;

    &__btn {
        margin: 0;
        padding: 0;
        background-color: transparent;
        border: none;
        font-family: $font-secondary;
        font-size: small;
        text-align: left;
        cursor: pointer;
    }

    &__btn-active {
        margin: 0;
        padding: 0;
        background-color: transparent;
        border: none;
        font-family: $font-secondary;
        font-size: small;
        color: $primary;
        text-align: left;
        cursor: pointer;
    }

    &__scan-card {
        height: 100%;
        background-color: white;
        border-radius: 3px;
        padding: .25em;
        margin: .5em;

        &__header {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
        }


    }

    &__dnd {
        position: relative;
        border: 2px dashed $secondary-20;
        padding: $space-01;
        border-radius: 16px;
        overflow-y: auto;
        overflow-x: hidden;
        background-color: $secondary-05;
        min-height: 200px;
        // height: 200px;
        margin-bottom: .5em;
        background-color: $secondary-10 !important;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;


        &__bottom {
            display: flex;
            align-items: center;
            flex-direction: column;
            padding-bottom: 10px;
            // height: 300px;
            // justify-content: flex-end;
        }

        &__dd-hover {
            background-color: $secondary-10;
        }
    }

    &__body {
        height: 100%;
        width: 100%;
    }

    &__header {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        margin-bottom: 1em;

        &-icon-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &-divider {
            height: 5px;
            width: 20px;
            border-top: 2px solid $gray-40;
        }

        &-divider-success {
            height: 5px;
            width: 20px;
            border-top: 2px solid $primary;
        }
    }
}

.icon-pending {
    color: $gray-40;
    margin: 2px;

    &__wrapper {
        border: 2px solid $gray-40;
        // border: none;
        background-color: white;
        border-radius: 50%;
        height: 35px;
        width: 35px;
        margin: 3px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }

}

.icon-success {
    color: $primary !important;

    &__wrapper {
        border: 2px solid $primary;
        // border: none;
        color: $primary !important;
        background-color: white;
        border-radius: 50%;
        height: 35px;
        width: 35px;
        margin: 3px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }

}

.icon-inProgress {
    color: $gray-40 !important;

    &__wrapper {
        border: 2px solid $primary;
        // border: none;
        background-color: white;
        border-radius: 50%;
        height: 35px;
        width: 35px;
        margin: 3px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }

}

.icon-error {
    color: $red !important;

    &__wrapper {
        border: 2px solid $red;
        // border: none;
        background-color: white;
        border-radius: 50%;
        height: 35px;
        width: 35px;
        margin: 3px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }

}

.cloud {
    height: 150px;
    display: grid;
    place-content: center;
}

.care-step::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.care-step {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}


.inputs {
    display: flex;
    width: 100%;
    align-items: flex-start;
    justify-content: space-evenly;
}

.files {
    &__wrapper {
        display: grid;
    }

    &__folder{
        display: flex;
        flex-direction: row;
        align-items: center;
        // justify-content: space-between;
        // margin-top: 4px;
        // padding: 1px 5px 1px 5px;
        font-weight: 600;
        cursor: pointer;
        border-radius: 5px;
        border: 1px solid $gray-40;
        padding: 6px 12px;
        cursor: pointer;
        background-color: $background;
        margin-top: 4px;
    }

    &__file{
        border-radius: 5px;
        border: 1px dashed $gray-40;
        padding: 6px 12px;
        cursor: pointer;
        background-color: $background;
        // padding: $space-03;
        // border-top: 1px solid $light-gray;
        // border-bottom: 1px solid $light-gray;
        margin-top: 4px;
        // padding: $space-01 $space-03 $space-03 $space-01;
        // box-shadow: $shadow;
        display: flex;
        align-items: center;
        // justify-content: space-between;
        width: 100%;
    }
}