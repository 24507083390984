@import '../../styles/index.scss';

.crf-form-attachement {

}

.attachment-wrapper-fullWidth{
    width: fit-content !important;
    height: auto !important;
    max-width: 700px !important;
}


.img-full{
    width: 100%;
    height: auto;
    max-width: 700px !important
}
