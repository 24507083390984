@import '../../../styles/index.scss';

.protocol-item {
    padding: .75em;
    border-radius: 2px !important;
    margin: 0.5em 0.5em;
    box-shadow: $shadow-light;
    // width: 100%;

    &__ref {
        width: 250px;
        display: flex;
        align-items: center;
        margin-top: 5px;
        margin-bottom: 10px;

        .icon{
            // color: $primary !important;
            // margin: 0px 5px 10px 0px;
            margin-right: 5px;
        }
    }

    &__active {
        padding: .5em;
        border-radius: 2px !important;
        margin: 0.5em 0.5em;
        box-shadow: none;
        background-color: $primary-10;
        // width: 100%;
        border: 1px dotted $primary-20;
    }

    &__dragging {
        padding: .5em;
        border-radius: 2px !important;
        margin: 0.5em 1px;
        box-shadow:  $shadow-light;
        background-color: $primary-20 !important;
        width: 100%;
        // border: 2px solid rgba(0, 82, 204, 0.2);
    }

    &__flag{
        color: $danger !important;
        margin-top: 3px;
        min-width: 15px !important;
    }

    &__grid {
        padding: 0 !important;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }

    &__grid-stacked {
        padding: 0 !important;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
    }
    &__grid-small {
        padding: 0 !important;
        display: flex;
        align-items: flex-start;
        // justify-content: space-between;
    }

    &__body {
        // margin-top: 1em;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__status {
        display: flex;
        align-items: center;
        flex-direction: row;
        // justify-content: flex-end;
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding: $space-02 0 ;
    }

    &__tiny {
        padding: 6px !important;
    }
}

.protocol-item:hover {
    background-color: $primary-10;
}

.flex-end{
    justify-content: flex-end;
}