@import '../../styles/index.scss';

.care-path-list-item-active {

    // background-color: $primary-20;
    border: 1px dotted $gray-60;
    box-shadow: none;
    padding: 0.75em;
    border-radius: 5px;
    flex: 1 1;
    margin: 0.5em 1px;
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    align-items: center;
    // justify-content: space-between;
    width: 100%;

    &__indicator {
        flex: 0 1;
        padding-right: 0.75em;
    }

    &__icon {
        color: $primary !important;
        // margin: 2px;
    }

    &__wrapper {
        border: 2px solid $primary;
        // border: none;
        background-color: white;
        border-radius: 50%;
        height: 20px;
        width: 20px;
        // margin: 3px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }

    &:hover {
        box-shadow: $shadow;
    }

}

.care-path-list-item-disabled {
    // background-color: $gray-10;
    // border: 1px dotted $primary-20;
    // background-color: $background;
    border: 1px dotted $gray-60;
    box-shadow: none;
    padding: 0.75em;
    border-radius: 5px;
    flex: 1 1;
    margin: 0.5em 1px;
    // display: flex;
    // align-items: flex-start;
    // flex-direction: column;
    // justify-content: space-between;
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    align-items: center;
    width: 100%;

    &__indicator {
        flex: 0 1;
        padding-right: 0.75em;
    }

    &__icon {
        color: $gray !important;
        // margin: 2px;
    }

    &__wrapper {
        // border: 2px solid $gray-20;
        // border: none;
        background-color: white;
        border-radius: 50%;
        height: 20px;
        width: 20px;
        // margin: 3px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }

    &:hover {
        box-shadow: $shadow;
    }

}


.care-path-list-item-completed {
    display: flex;
    flex-direction: row;
    // justify-content: space-between;
    align-items: center;
    // background-color: $background;
    // background-color: $primary-20;
    border: 1px solid $primary-20;
    box-shadow: none;
    padding: 0.75em;
    border-radius: 5px;
    margin: 0.5em 1px;
    width: 100%;

    &:hover {
        box-shadow: $shadow;
    }

    &__indicator {
        flex: 0 1;
        padding-right: 0.75em;
    }
}

.care-path-list-item {

    // background-color: aquamarine;
    // margin: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    &__card {
        flex: 1 1;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        justify-content: space-between;
    }

    &__indicator {
        flex: 0 1;
        padding-right: 0.75em;
    }


}

.active-path-item {
    background-color: $primary-10 !important;
}