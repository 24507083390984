@import '../../../styles/index.scss';

.recent {
    flex:1;
    display: flex;
    flex-direction: column;

    &__header{
        padding: 2em 15%;
        display: flex;
        align-items: center;
        // border-bottom: 1px solid $gray-10;
    }
    &__header-sm{
        margin: 2em 5%;
        display: flex;
        align-items: center;
        // border-bottom: 1px solid $gray-10;
    }
    &__body{
        padding: 0 15%;
        height: 62vh;
        overflow: scroll; 
    }
    &__body-sm{
        // margin: 2em 5% 0 5%;
        padding: 0 5%;
        height: 57vh;
        overflow: scroll;    
    }

    &__theader{
        border-bottom: 1px solid $gray-10;
        margin-bottom: 5px;
    }


}


.recent__body-sm::-webkit-scrollbar {
    display: none;
  }
.recent__body::-webkit-scrollbar {
    display: none;
  }
