@import '../../../styles/index.scss';

.participant-list-item {

    width: 100%;
    margin-top: .5em;

    &__wrapper {
        width: 100%;
        justify-content: center;
        display: flex;
    }

    &__tile {
        width: 100%;
        border-radius: $radius-sm;
        padding: $space-04 $space-04;
        overflow: scroll;
        &::-webkit-scrollbar {
          display: none;
        }
        margin: 5px 0px;
        background: $light;
        box-shadow: $shadow-light;
    }

    &__col{
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__action-wrapper{
        display: flex;
        align-items: center;
        justify-content: flex-end;
        // width: 100%;
    }

    &__grid {
        // display: flex;
        // align-items: center;
        // justify-content: space-between;
        display: grid;
        grid-template-columns: auto 1fr auto;
        grid-gap: 1rem;

        &-left {
            grid-column: 1 / 2;
        }
    
        &-main {
            grid-column: 2 / 3;
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            // display: grid;
            // grid-template-columns: 1fr 1fr;
            // padding: 0 1em;
            // grid-gap: 1rem;
        }
    
        &-right {
            grid-column: 3 / 4;
            display: flex;
            align-items: center;
            justify-content: flex-end;
        }
    }


    &__grid-item {
        display: flex;
        align-items: center;
    }

    &__popover-body{
        padding: .5em 1em;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        justify-content: flex-start;
    }

}