@import '../../../../styles/index.scss';

.menu-items {
    &:hover {
        color: darken($secondary, 10%);
      }

}

.menu-items > NavLink, .menu-items button {
    text-align: left;
    padding: 0.7rem 1rem;
  }