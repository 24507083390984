@import '../../styles/index.scss';

.protocol-logs {

    &__btn{
        background-color: transparent;
        border: none !important;
        display: flex;
        padding: 0;
        margin: 0;
        cursor: pointer;
    }


    &__icon{
        color: $secondary;
        margin-right: 5px;
    }

}