/*
 *  COLORS
 */
$black: #171717;
$white: #ffffff;
$gray: #5a5a5a;
$light-gray: #f3f6f8;
$blue: #0052cc;
$light-blue: #4e8fef;
$yellow: #ff991f;
$purple: #5243aa;
$dark-purple: #6a3880;
$green: #94c337;
$light-green: #c0ee65;
$red: #ea1616;
$orange: #d27827;

// Black
$black-100: $black;
$black-80: rgba($black, 0.8);
$black-60: rgba($black, 0.6);
$black-40: rgba($black, 0.4);
$black-20: rgba($black, 0.2);
$black-10: rgba($black, 0.1);
$black-05: rgba($black, 0.05);

// Gray
$gray-100: $gray;
$gray-80: rgba($gray, 0.8);
$gray-60: rgba($gray, 0.6);
$gray-40: rgba($gray, 0.4);
$gray-20: rgba($gray, 0.2);
$gray-10: rgba($gray, 0.1);
$gray-05: rgba($gray, 0.05);

// Gray
$light-gray-100: $light-gray;
$light-gray-80: rgba($light-gray, 0.8);
$light-gray-60: rgba($light-gray, 0.6);
$light-gray-40: rgba($light-gray, 0.4);
$light-gray-20: rgba($light-gray, 0.2);
$light-gray-10: rgba($light-gray, 0.1);
$light-gray-05: rgba($light-gray, 0.05);

// Blue
$blue-100: $blue;
$blue-80: rgba($blue, 0.8);
$blue-60: rgba($blue, 0.6);
$blue-40: rgba($blue, 0.4);
$blue-20: rgba($blue, 0.2);
$blue-10: rgba($blue, 0.1);
$blue-05: rgba($blue, 0.05);

// Yellow
$yellow-100: $yellow;
$yellow-80: rgba($yellow, 0.8);
$yellow-60: rgba($yellow, 0.6);
$yellow-40: rgba($yellow, 0.4);
$yellow-20: rgba($yellow, 0.2);
$yellow-10: rgba($yellow, 0.1);
$yellow-05: rgba($yellow, 0.05);


$orange-100: $orange;
$orange-80: rgba($orange, 0.8);
$orange-60: rgba($orange, 0.6);
$orange-40: rgba($orange, 0.4);
$orange-20: rgba($orange, 0.2);
$orange-10: rgba($orange, 0.1);
$orange-05: rgba($orange, 0.05);

// Purple
$purple-100: $purple;
$purple-80: rgba($purple, 0.8);
$purple-60: rgba($purple, 0.6);
$purple-40: rgba($purple, 0.4);
$purple-20: rgba($purple, 0.2);
$purple-10: rgba($purple, 0.1);
$purple-05: rgba($purple, 0.05);

// Green
$green-100: $green;
$green-80: rgba($green, 0.8);
$green-60: rgba($green, 0.6);
$green-40: rgba($green, 0.4);
$green-20: rgba($green, 0.2);
$green-10: rgba($green, 0.1);
$green-05: rgba($green, 0.05);

// Red
$red-100: $red;
$red-80: rgba($red, 0.8);
$red-60: rgba($red, 0.6);
$red-40: rgba($red, 0.4);
$red-20: rgba($red, 0.2);
$red-10: rgba($red, 0.1);
$red-05: rgba($red, 0.05);
