@import '../../styles/index.scss';

.button {
	// font-size: $text-005;
	// border-radius: $radius-pill;
	// font-family: $font-secondary;
	// border-style: none;
	// cursor: pointer;
	// transition: background-color 300ms ease;
    // text-transform: uppercase;
	// font-weight: 300;

	font-size: $text-01;
	border-radius: $radius-pill;
	font-family: $font-primary;
	border-style: none;
	cursor: pointer;
	transition: background-color 300ms ease;
	font-weight: 400;
	text-transform: uppercase;
	margin: 5px;


	&:focus {
		outline: none;
	}

	&:focus {
		outline: none;
	}

	&--shadow{
		box-shadow: $shadow-strong;
	}

	&__content {
		display: flex;
		align-items: center;
		width: inherit;
		min-width: fit-content;
		min-height: fit-content;
	}
	&__text {
		display: flex;
		align-items: center;
	}

	&--with-text {
		padding: $space-02 $space-05;
		border-radius: $radius-pill;
		letter-spacing: 1px;
		.button__icon {
			margin-right: $space-01;
		}
		margin: $space-01;
	}

	&--with-icon:not(.button--with-text) {
		padding: $space-02;
		// border-radius: 100px;
		margin: 2px !important;

	}

	&--primary {
		background-color: $primary;
		color: $alt-text-col;

		&:hover {
			background-color: $secondary;
		}

		&.button--outline {
			border-color: $primary;
			color: $primary;

			&:hover {
				background-color: $secondary;
				border-color: $secondary;
			}
		}
	}

	&--secondary {
		background-color: $secondary;
		color: $alt-text-col;

		&:hover {
			background-color: $primary;
		}

		&.button--outline {
			border-color: $secondary;
			color: $secondary;

			&:hover {
				background-color: $secondary;
			}
		}
	}

	&--ghost {
		color: $gray;
		font-weight: 300;
		background-color: unset;
		padding: 0 !important;
		margin: 0 !important;


		&:hover {
			color: $secondary;
		}

		&.button--disabled {
			background-color: unset;

			&:hover {
				background-color: unset;
			}
		}
	}

	&--ghost-white {
		color: $white;
		background-color: unset;
		border-radius: 0 !important;
		padding: 0 !important;

		&:hover {
			// background-color: $light-gray;
			text-shadow: $shadow;
			font-weight: 600;
		}

		&.button--disabled {
			background-color: unset;

			&:hover {
				background-color: unset;
			}
		}
	}

	&--danger {
		background-color: $danger;
		color: $white;

		&:hover {
			background-color: darken($danger, 10%);
		}

		&.button--outline {
			border-color: $danger;
			color: $danger;

			&:hover {
				background-color: $danger;
			}
		}
	}

	&--info {
		background-color: $info;
		color: $alt-text-col;

		&:hover {
			background-color: $secondary;
		}

		&.button--outline {
			border-color: $info;
			color: $info;

			&:hover {
				background-color: $info;
			}
		}
	}

	&--warning {
		background-color: $warning;
		color: $alt-text-col;

		&:hover {
			background-color: darken($warning, 10%);
		}

		&.button--outline {
			border-color: $warning;
			color: $warning;

			&:hover {
				background-color: $warning;
			}
		}
	}

	&--success {
		background-color: $success;
		color: $alt-text-col;

		&:hover {
			background-color: darken($success, 10%);
		}

		&.button--outline {
			border-color: $success;
			color: $success;

			&:hover {
				background-color: $success;
			}
		}
	}

	&--disabled {
		background-color: $disabled-background !important;

		&:hover {
			background-color: $disabled-background !important;
			color: $disabled !important;
		}
	}

	&--outline {
		background-color: $white;
		color: $secondary;
		border: 1px solid $secondary;
		border-style: solid;
		border-width: 1.5px;

		&:hover {
			color: $alt-text-col;
		}
	}

	&--large.button--with-text {
		padding: $space-02 $space-08;
		font-size: $text-01;
		border-radius: $radius-pill;

		svg {
			font-size: $text-02;
		}
	}

	&--large.button--with-icon:not(.button--with-text) {
		padding: $space-03;
		font-size: $text-02;

		svg {
			font-size: $text-02;
		}
	}

	&--small.button--with-text {
		font-size: $text-xs;
		border-radius: $radius-pill;
		padding: 5px !important;
		// border-radius: 0 !important;
		// padding: 5px !important;
		margin: 0 !important;

		&:hover {
			font-weight: 400;
			background-color: transparent !important;
		}

		svg {
			font-size: $text-00;
		}
	}

	&--small.button--with-icon:not(.button--with-text) {
		padding: $space-01;
		font-size: $text-xs;

		svg {
			font-size: $text-00;
		}
	}

	&--wide {
		padding: .5rem $space-12;
		border-radius: $radius-pill;
		height: fit-content;
	}
}


.button-wrapper {
    display: flex;
    justify-content: center;
	width: fit-content;
}



.button-hsg {
	font-size: $text-xs;
	border-radius: $radius-pill !important;
	font-family: $font-primary;
	border-style: none;
	cursor: pointer;
	transition: background-color 300ms ease;
	font-weight: 200;
    text-transform: uppercase;
	margin: 5px;
	text-transform: uppercase;

	&:focus {
		outline: none;
	}

	&:focus {
		outline: none;
	}

	&--shadow{
		box-shadow: $shadow-strong;
	}

	&__content {
		display: flex;
		align-items: center;
		padding-top: 2px;
	}

	&--with-text {
		// padding: $space-01 $space-05;
		padding: 0.5rem 1.5rem 0.7rem 1.5rem;
		border-radius: $radius;
		letter-spacing: 2px;
		.button__icon {
			margin-right: $space-01;
		}
	}

	&--with-icon:not(.button--with-text) {
		padding: $space-02;
		border-radius: 50%;
	}

	&--primary {
		background: $primary;
		color: $alt-text-col;

		&:hover {
			background-color: darken($primary, 20%);
		}

		&.button--outline {
			border-color: $primary;
			color: $primary;

			&:hover {
				background-color: $primary;
			}
		}
	}

	&--secondary {
		background-color: $secondary;
		color: $alt-text-col;

		&:hover {
			background-color: darken($secondary, 10%);
		}

		&.button--outline {
			border-color: $secondary;
			color: $secondary;

			&:hover {
				background-color: $secondary;
			}
		}
	}

	&--ghost {
		color: $secondary;
		background-color: unset;
		border-radius: 0 !important;
		padding: 0 !important;
		margin:  0 !important;

		&:hover {
			background-color: $light-gray;
			color: $primary;
		}

		&.button--disabled {
			background-color: unset;

			&:hover {
				background-color: unset;
			}
		}
	}

	&--ghost-white {
		color: $white;
		background-color: unset;
		border-radius: 0 !important;
		padding: 0 !important;

		&:hover {
			// background-color: $light-gray;
			text-shadow: $shadow;
			font-weight: 600;
		}

		&.button--disabled {
			background-color: unset;

			&:hover {
				background-color: unset;
			}
		}
	}

	&--danger {
		background-color: $danger;
		color: $alt-text-col;

		&:hover {
			background-color: darken($danger, 10%);
		}

		&.button--outline {
			border-color: $danger;
			color: $danger;

			&:hover {
				background-color: $danger;
			}
		}
	}

	&--info {
		background-color: $info;
		color: $alt-text-col;

		&:hover {
			background-color: darken($info, 10%);
		}

		&.button--outline {
			border-color: $info;
			color: $info;

			&:hover {
				background-color: $info;
			}
		}
	}

	&--warning {
		background-color: $warning;
		color: $alt-text-col;

		&:hover {
			background-color: darken($warning, 10%);
		}

		&.button--outline {
			border-color: $warning;
			color: $warning;

			&:hover {
				background-color: $warning;
			}
		}
	}

	&--success {
		background-color: $success;
		color: $alt-text-col;

		&:hover {
			background-color: darken($success, 10%);
		}

		&.button--outline {
			border-color: $success;
			color: $success;

			&:hover {
				background-color: $success;
			}
		}
	}

	&--disabled {
		background-color: $disabled-background !important;
		color: $disabled !important;
		border-color: transparent !important;
		box-shadow: none !important;

		&:hover {
			background-color: $disabled-background !important;
			color: $disabled !important;
		}
	}

	&--outline {
		background-color: $white;
		box-shadow: $shadow;
		border-style: solid;
		border-width: 1.5px;
		border-color: $secondary;
		color: $secondary;

		&:hover {
			color: $alt-text-col;
			background-color: $secondary;
		}
	}

	&--large.button--with-text {
		padding: $space-02 $space-08;
		font-size: $text-01;
		border-radius: 35px;

		svg {
			font-size: $text-02;
		}
	}

	&--large.button--with-icon:not(.button--with-text) {
		padding: $space-03;
		font-size: $text-02;

		svg {
			font-size: $text-02;
		}
	}

	&--small.button--with-text {
		padding: $space-00 $space-04 $space-01 $space-04;
		font-size: .6rem;

		svg {
			font-size: $text-00;
		}
	}

	&--small.button--with-icon:not(.button--with-text) {
		padding: $space-01;
		font-size: $text-00;

		svg {
			font-size: $text-00;
		}
	}

	&--wide {
		padding: $space-01 $space-12;
		border-radius: 35px;
	}
}