@import '../../../styles/index.scss';

.patient-care-pathway {
    width: 100%;
    display: flex;
    flex-direction: column;
    transition: flex-basis 5s ease;
    padding: 0 12px;
    overflow: hidden;
    // height: $workspace_vh;
    flex: 1;

    &__body{
        display: flex;
        flex-direction: row;
        transition: flex-basis 5s ease;
        overflow: hidden;
    }

    &__header {
        display: flex;

        &-tab {
            background-color: transparent;
            border: none;
            font-size: $text-00;
            letter-spacing: 2px;
            font-family: $font-primary;
            text-transform: uppercase;
            font-weight: 500;
            color: $gray-40;
            margin: 0 5px;
            display: flex;
            align-items: center;
            cursor: pointer;

            &:hover {
                color: $primary;
            }
        }

        &-tab-active {
            background-color: transparent;
            border: none;
            font-size: $text-00;
            letter-spacing: 2px;
            font-family: $font-primary;
            text-transform: uppercase;
            font-weight: 500;
            color: $primary;
            margin: 0 5px;
            display: flex;
            align-items: center;
        }

        &-icon {
            color: $gray-40 !important;
            margin: 5px;
        }
    
        &-icon-active {
            color: $primary !important;
            margin: 5px;
        }
    

    }


    &__panel1 {
        flex: 1;
        flex-basis: 0;

    }

    &__panel2 {
        flex: 2;
        flex-basis: 0;
    }

    &__panel3 {
        flex: 1;
        flex-basis: 0;
    }

    &__tile {
        height: 96%;
    }
}

.care-path-list {
    position: relative;
    /* Add other styles for the list item */

    &-wrapper {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
}

.connecting-line {
    position: absolute;
    left: 50%;
    /* Position the line in the center */
    top: -7px;
    /* Move the line 15px above the item */
    transform: translateX(-50%);
    /* Center the line horizontally */
    width: 1px;
    height: 15px;
    /* Set the height to 15px */
    background-color: #ccc;
    /* Adjust the style of the connecting line as needed */
}

// .spacer{
//     margin: 10px;
// }