@import '../../../styles/index.scss';

.study__wrapper {
    flex: 1;
    display: flex;

}

.study {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;


    &__header {
        padding: 2em 15%;
        border-bottom: 1px solid $gray-10;
    }

    &__body {
        // padding: 1em 5%;
        overflow: scroll;
        padding: 2em var(--study-padding-horizontal);
        flex: 1 1 auto;
        overflow-y: auto;

        &::-webkit-scrollbar{
            display: none;
        }
    }

    // &__smallscreen{
    //     padding: 1em 5%;
    // }

    &__heading {
        border-bottom: 1px solid $gray-10;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__row-item {
        display: flex;
        align-items: flex-start;
    }

    &__icon-btn {
        // background: $gray-10;
        // border: none;
        // font-family: $font-primary;
        color: $white !important;
        // padding: 0 .5em ;
        // font-size: 20px;
        // font-weight: 600;
        // margin-bottom: 2px;
    }

    @media (max-width: 786px) {
        &__body {
            padding: 1em 5%;
            // background-color: lightblue;
        }

        &__header {
            padding: 1em 5%;
            // border-bottom: 1px solid $gray-10;
        }
    }
}

.txt-blue {
    color: $primary !important;
    margin-left: 3px;
}


@media screen and (min-width: 768px) {

    /* Adjust the breakpoint (768px) to your desired screen size */
    .study {

        &__body {
            padding: 1em calc(var(--study-padding-horizontal) * 2);

        }
    }

}

@media screen and (min-width: 1024px) {

    /* Adjust the breakpoint (1024px) to your desired screen size */
    .study {

        &__body {
            padding: 1em calc(var(--study-padding-horizontal) * 3);

        }
    }
}

@media screen and (max-width: 480px) {

    /* Adjust the breakpoint (480px) to target mobile devices like iPhones */
    .study {


        &__body {
            padding: 1em var(--study-padding-horizontal-mobile);
        }
    }
}

:root {
    --study-padding-horizontal: 4%;
    --study-padding-horizontal-mobile: 1%;
}