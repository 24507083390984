@import '../../../styles/index.scss';

.site {
    padding: 1em var(--site-padding-horizontal);
    // background-color: gold;
    // height: 85vh;
    flex:1;
    
    &__btn {
        margin: 0;
        padding: 0;
        background-color: transparent;
        border: none;
        font-size: 0.5em;
        letter-spacing: 1px;
        font-family: "Raleway", sans-serif !important;
        text-transform: uppercase;
        font-weight: 300;
        cursor: pointer;
        text-align: center;
    }

    &__center {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__btn {
        margin: 0;
        padding: 0;
        background-color: transparent;
        border: none;
        font-size: 0.5em;
        letter-spacing: 1px;
        font-family: "Raleway", sans-serif !important;
        text-transform: uppercase;
        font-weight: 300;
        cursor: pointer;
        text-align: center;
    }

    &__center{
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__header {
        padding: 2em var(--site-padding-horizontal);
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        // border-bottom: 1px solid $gray-10;
    }

    &__title_wrapper {
        display: flex;
        justify-content: space-between;
        width: 100%;
        // min-width: 100vw;
    }

    &__search {
        width: 40%;
        position: relative;
        top: 20px;
    }

    &__footer {
        // background-color: rebeccapurple;
    }

    &__body {
        padding: 0 5%;
        flex-grow: 1;
        overflow-y: auto;
        padding-right: 10px;
        justify-content: flex-start;
    }

   &__scrollable {
        padding: 1px;
        height: 60vh;
        overflow: scroll;
        padding-bottom: 20px;
    }

    &__action-wrapper {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 100%;
        margin-right: .25em;
    }
}

.site__scrollable-sm::-webkit-scrollbar {
    display: none;
}

.site__scrollable::-webkit-scrollbar {
    display: none;
}

@media screen and (min-width: 768px) {

    /* Adjust the breakpoint (768px) to your desired screen size */
    .site {
        padding: 1em calc(var(--site-padding-horizontal) * 2);

        &__header {}
    }

}

@media screen and (min-width: 1024px) {

    /* Adjust the breakpoint (1024px) to your desired screen size */
    .site {
        padding: 2em calc(var(--site-padding-horizontal) * 3);

        &__header {}
    }
}

@media screen and (max-width: 480px) {

    /* Adjust the breakpoint (480px) to target mobile devices like iPhones */
    .site {
        padding: 1em var(--site-padding-horizontal-mobile);
        background-color: gold;
        &__header {}
    }
}
@media screen and (max-width: 400px) {

    /* Adjust the breakpoint (480px) to target mobile devices like iPhones */
    .site {
        padding: 1em var(--site-padding-horizontal-mobile);
        background-color: orchid;
        &__header {}
    }
}
:root {
    --site-padding-horizontal: 2%;
    --site-padding-horizontal-mobile: 1%;
}