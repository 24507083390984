@import 'colors';

// Main color palette
// $secondary: #CAD9DE;
// $primary: #574AE2;
// $tertiary: #CAD9DE;

$primary: #7f3f98;
$primary-gradient: linear-gradient(207deg, rgba(127,63,152,1) 0%, rgba(52,31,103,1) 100%);
$secondary: #fbaf3f;
$secondary-gradient: linear-gradient(75deg, $secondary 0%, rgba($secondary, 0.8) 100%);
$tertiary: #8dc540;
$tertiary-gradient: linear-gradient(75deg, $tertiary 0%, rgba($tertiary, 0.8) 90%);
$gradient: linear-gradient(200deg, $secondary  0%, $primary 100%, $tertiary 100%);


$invalid: $red;
$danger: $invalid;
$warning: $yellow;
$info: $light-blue;
$success: $light-green;
$disabled: #6C7181;
$background: #ebedee;
// $background: #fdfdff;
$foreground: white;
$light: #FFFFFF;
$disabled-background: $light-gray;
$disabled-background-01: #e0e0e0;
$text-col: $black-80;
$alt-text-col: $light-gray;
// Shadows
$shadow: 0 5px 15px rgba(0,0,0,.08);
$shadow-light: 0 3px 10px rgba(0,0,0,.08);
$shadow-strong: 0 8px 20px rgba(0,0,0,.48);
$shadow-hover: 0 15px 25px rgba(0,0,0,0.09);
$shadow-full: 15px 15px 15px rgba(0,0,0,0.09);

$card-shadow: 0 5px 15px rgba(0,0,0,.08);
$inset-shadow: 0 5px 15px rgba(0,0,0,.08) inset;
$inset-shadow-light: 0 5px 15px rgba(0,0,0,.05) inset;
$inset-shadow-focus: 0 5px 15px rgba(0, 0, 0, 0.137) inset;

// Primary
$primary-100: $primary;
$primary-80: rgba($primary, 0.8);
$primary-60: rgba($primary, 0.6);
$primary-40: rgba($primary, 0.4);
$primary-20: rgba($primary, 0.2);
$primary-10: rgba($primary, 0.1);
$primary-05: rgba($primary, 0.05);

// Secondary
$secondary-100: $secondary;
$secondary-80: rgba($secondary, 0.8);
$secondary-60: rgba($secondary, 0.6);
$secondary-40: rgba($secondary, 0.4);
$secondary-20: rgba($secondary, 0.2);
$secondary-10: rgba($secondary, 0.1);
$secondary-05: rgba($secondary, 0.05);

// Tertiary
$tertiary-100: $tertiary;
$tertiary-80: rgba($tertiary, 0.8);
$tertiary-60: rgba($tertiary, 0.6);
$tertiary-40: rgba($tertiary, 0.4);
$tertiary-20: rgba($tertiary, 0.2);
$tertiary-10: rgba($tertiary, 0.1);
$tertiary-05: rgba($tertiary, 0.05);

// Invalid
$invalid-100: $invalid;
$invalid-80: rgba($invalid, 0.8);
$invalid-60: rgba($invalid, 0.6);
$invalid-40: rgba($invalid, 0.4);
$invalid-20: rgba($invalid, 0.2);
$invalid-10: rgba($invalid, 0.1);
$invalid-05: rgba($invalid, 0.05);

// Invalid
$disabled-100: $disabled;
$disabled-80: rgba($disabled, 0.8);
$disabled-60: rgba($disabled, 0.6);
$disabled-40: rgba($disabled, 0.4);
$disabled-20: rgba($disabled, 0.2);
$disabled-10: rgba($disabled, 0.1);
$disabled-05: rgba($disabled, 0.05);

// Colors for elements
$background: #fdfdff;
$foreground: $white;
$light: $white;
$disabled-background: $light-gray;
$disabled-background-01: #e0e0e0;
$text-col: $black-80;
$alt-text-col: $light-gray;
$filedrop-active-col: $light-gray;
$filedrop-disabled-col: $disabled-background-01;

$dicomdrop-active-col: $primary-10;
$dicomdrop-disabled-col: $gray;
