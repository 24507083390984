@import '../../styles/index.scss';

.custom-animation {
// max-width: 150px;
flex: 1;
align-self: center;
// background-color: rgb(aqua,0.4);
display: flex;
justify-content: center;
}

.custom-animation > .anim { 
// flex: 1;
align-self: center;
align-content: center;
// background-color: red;
width: 100%;
// box-shadow: rgba(0, 0, 0, 0.1) 0px 11.3115px 40px 0px;
border-radius: 150px;
// background-color: transparent;
// position: relative;
// top: 10px;
// align-self: center;
}

.anim2 {
// align-self: center;
position:relative;
top: 7px;
left: 5px;
// width: 100%;
// height: 60%;
}