@import '../../../styles/index.scss';

.consent-manager {

    &__file-input{
        color: $gray-80;
        font-size: $text-00;
        border-radius: $radius-sm;
        border-color: $gray-20;
        border-style: solid;
        cursor: text;
        transition: background-color 300ms ease;
        padding: $space-03 $space-02;
        margin: $space-03 0;
        border-width: 0.5px;
        letter-spacing: 0.8px;
        width: 100%;
        background-color: white;
        box-shadow: $shadow;
      
        &--disabled {
          background-color: $disabled-background !important;
        }
      
        &::placeholder {
          color: $gray-60;
          font-family: $font-secondary;
          font-weight: 300;
          letter-spacing: 0.8px;
        }
        &:focus {
          border-color: $primary !important;
          background-color: $primary-10 !important;
        }
      
        &:hover {
          border-color: darken($gray-20, 30%);
        }
      
        &--primary {
          // background-color: $primary-10;
          // color: $alt-text-col;
          &:hover {
            // background-color: white;
          }
        }
        &--secondary {
          background-color: $secondary-10;
          color: $alt-text-col;
          &:hover {
            background-color: darken($secondary-10, 10%);
          }
        }
        &--invalid {
          background-color: $invalid-10 !important;
          border-color: $invalid !important;
          color: $text-col !important;
          &:hover {
            background-color: darken($invalid-10, 10%) !important;
            border-color: $invalid !important;
          }
        }
      
        &--ghost {
          color: $gray;
          background-color: unset;
      
          &:hover {
            background-color: $light-gray;
          }
      
          &.button--disabled {
            background-color: unset;
      
            &:hover {
              background-color: unset;
            }
          }
        }
    }

}