@import '../../../styles/index.scss';

.image-series-display {
    display: flex;
    flex: 1;
    width: 100%;
    
    .progress {
        flex:1;
        display: flex;
        align-items: center;
        justify-content: center;
        
        .custom {
            max-width: 450px;
        }
    }

    &__wrapper {
        flex:1;
        flex-grow: 1;
        // background-color: rgb(9, 255, 0) ;
        display: flex;
        flex-direction: row;
        transition: flex-basis 5s ease;
        overflow: hidden;

        &.preview {
            flex-direction: row;
        }
    }

    &__panel {
        flex: 1;
        flex-basis: 0;
        /* Styles for the left column */
        margin-right: 10px;
        overflow-y: scroll;
        // background-color: aqua;
        display: flex;
        flex-direction: column;

        &.preview {
            flex-direction: row;
            &.left {
                // background-color: rgb(25, 143, 102);
                flex: 1.5;
                flex-basis: 0;
                display: block;

                &.fullScreen {
                    flex: 3.5;
                }
            }
    
            &.right {
                // background-color: rgb(255, 0, 208);
                flex: 1;
                flex-basis: 0;
                display: block;

                &.fullScreen {
                    flex: 0;
                }
            }
    
        }

        &.left {
            // background-color: rgb(25, 143, 102);
            flex: 0;
            flex-basis: 0;
            display: none;
        }

        &.right {
            // background-color: rgb(255, 0, 208);
            // flex: 1;
            flex-basis: 0;
        }

    }

    &__button {
        // background-color: $primary-10;
        // border-width: 0;
    }
    
    &__dicom-wrapper{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        // flex:1;
        height: 100%;
        

        &__btn-wrapper{
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            // background-color: red;
        }

        &__viewer-wrapper{
            flex:1;
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
            // align-items: center;
            width: 100%;
        }

        &__viewer{
            // max-width: 500px;
            // width: 80%;
            display: flex;
        }
    }



    &__browser{
        border-radius: 5px;
        border: 1px dashed $gray-40;
        padding: 6px 12px;
        cursor: pointer;
        background-color: $background;
        // padding: $space-03;
        // border-top: 1px solid $light-gray;
        // border-bottom: 1px solid $light-gray;
        margin-top: 4px;
        // padding: $space-01 $space-03 $space-03 $space-01;
        // box-shadow: $shadow;
        display: flex;
        flex-direction: column;
        align-items: left;
        // justify-content: space-between;
        width: 100%;
        overflow: hidden;
    }
    &--header {
        flex:1;
        // background-color: blanchedalmond ;
        padding-left: 5px;
        display: flex;
        align-items: center;
        background-color: $primary-80;
        color: $white;
        font-size: $text-03;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;

    }
    &--study_title{
        display: flex;
        flex-direction: column;
    }
    &--series-wrapper {
        padding: 0px 3px 6px 15px;
        // background-color: rebeccapurple;

    }
    
    &--serie-wrapper {
        margin: 8px 0px 0px 0px;
        // background-color: $primary-05;
        border: 1px dashed $primary-20;
        padding: 3px 6px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 100%;
    }
    &--serie-header {
        flex:1.5;
        display: flex;
        justify-content: space-between;
        font-size: $text-03;
        inline-size: 800px;
        overflow-wrap: break-word;
        word-break: break-all;
        margin-right: 10px;
    }
    &--serie-info {
        flex:1.2;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        // padding: 1em;
    }
    &--serie-alignment{
        flex:.7;
    }

    &--serie-actions{
        flex:.7;
        // background-color: aquamarine;
        display: flex;
        justify-content: flex-end;
        align-content: center;
    }
}

.warning-icon{
    margin-right: 2px;
    padding-top: 5px;
    color: $warning !important;
}

.fullscreen{
    width: 100vh;
    height: 100vh;
    background-color: $background;
    position: absolute;
    left: 0;
    top: 0;
}