@import '../../styles/index.scss';

.advanced-flat-list {
    flex: 1;
    display: flex;
    // background-color: red;
    flex-direction: column;
    overflow-y: hidden;
    
    &-header {
        &-title {
            display: flex;
            min-width: fit-content;
        }

        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        padding: 0 0 !important;
        margin-bottom: 10px;
        // background-color: aqua;
    }
    
    .item {
        flex: 0;
        display: flex;
        flex-direction: column;
        box-shadow: $shadow-light;
        margin: 5px;
    }

    .body {
        overflow-y: scroll;

    }
}