@import '../../styles/index.scss';

.date-picker {

    // &__outer-wrapper{
    //     margin: 0.5em;
    // }
}

.css-1u3bzj6-MuiFormControl-root-MuiTextField-root{
    width: 100% !important;
}

.css-i44wyl{
    width: 100% !important;
}

.date-input-field{
    color: $gray-80;
    font-size: $text-00;
    border-radius: $radius-sm;
    border-color: $gray-20;
    border-style: solid;
    cursor: text;
    transition: background-color 300ms ease;
    padding: $space-03 $space-02;
    margin: $space-03 0;
    border-width: 0.5px;
    letter-spacing: 0.8px;
    width: 100%;
    background-color: white !important;
    box-shadow: $shadow !important;
  
    &--disabled {
      background-color: $disabled-background !important;
    }
  
    // &::placeholder {
    //   color: $gray-60;
    //   font-family: $font-secondary;
    //   font-weight: 300;
    //   letter-spacing: 0.8px;
    // }
    // &:focus {
    //   border-color: $primary !important;
    //   background-color: $primary-10 !important;
    // }
    &::placeholder {
      color: $gray-60;
      font-family: $font-secondary;
      font-weight: 300;
      letter-spacing: 0.8px;
    }
    &:focus {
      border-color: $primary !important;
      background-color: $primary-10 !important;
    }
  
    &:hover {
      border-color: darken($gray-20, 30%);
    }
}

// .date-input-field{
//   background-color: transparent !important;
//   border-radius: 0px !important;
//   box-shadow: none !important;
//   border: none !important;
// }

.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input{
  background-color: transparent !important;
  border-radius: 0px !important;
  box-shadow: none !important;
  border: none !important;
}

.css-1uvydh2 {
  box-shadow: none;
}