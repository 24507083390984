@import '../../styles/index.scss';

.user-survey-item {

    &__header{
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
    }

    &__subtext{
        font-size: $text-00;
        letter-spacing: 1px;
        font-family: $font-secondary;
        text-transform: uppercase;
        font-weight: 300;
        color: $text-col;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin: $space-00 0;
    }

    &__footer{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

}