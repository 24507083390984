@import '../../styles/index.scss';

.page-footer {
  // width: 100%;
  text-align: center;
  float: none !important;
  background-color: none;

  &__link{
    background: transparent;
    border: none;
    color: $text-col !important;
    text-decoration: none !important;
    transition: color 300ms ease !important;
    padding: 10px;
    margin: 0 !important;
    // font-size: 1em;
    font-size: 15px;
    font-family: $font-primary;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 2px;
    cursor: pointer;
    color: $gray !important;
    font-size: $text-xs !important;
  
    &__active{
      color: $primary !important;
    }
  
    &:hover {
      color: darken($primary, 10%) !important;
    }
  }


  &__container {
    margin-left: 10%;
    margin-right: 10%;
    border-top: 1px solid #e5e7eb;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: space-between;
    padding: 20px 0;
  }

  &__text {
    font-size: $text-xs;
    letter-spacing: 1px;
    font-family: $font-secondary;
    text-transform: uppercase;
    font-weight: 300;
    color: $gray;
    display: flex;
    align-items: center;
  }

  &__icon {
    font-size: $text-xs;
    letter-spacing: 1px;
    font-family: $font-secondary;
    text-transform: uppercase;
    font-weight: 300;
    color: $gray;
  }

  &__nav {

    a {
      display: inline-block;
      color: $gray !important;
      font-size: $text-xs !important;
    }

    ul {
      display: flex;
      flex-direction: column;
      padding-left: 0;
    }

    &-item {
      display: inline-block;

      a {
        display: inline-block;
        padding: $space-03 $space-05;
      }
    }

    // @media #{$phablet-up} {
    //   ul {
    //     flex-direction: row;
    //   }
    // }
  }

  // @media #{$tablet-up} {
  //   display: flex;
  //   align-items: center;
  //   justify-content: space-between;
  // }
}