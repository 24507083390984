@import '../../../styles/index.scss';

.private-layout {
    // flex-grow: 1;
    // display: flex;
    // flex-direction: column;
    // background-color:aquamarine;
    // margin-top: 100px;
    // margin-left: 100px;
    // margin-right: 100px;
    // padding: 24; 

    &__crumb-wrapper{
        display: flex;
        align-items: center;
        margin-left: 24px;
        overflow-y: auto;
        padding-top:0.5%;
    }

    //  &__body{
    //     flex:1;
    //     overflow-y: hidden;
    //     max-height: calc(100vh - 150px);
    //     display: flex;
    // }
    
    &__link {
        color: #171717CC !important;
        text-decoration: none !important;
        transition: color 300ms ease !important;
        padding: 2px;
        margin: 0 !important;
        // font-size: 1em;
        font-size: 15px;
        font-family: Raleway;
        text-transform: uppercase;
        font-weight: 600;
        letter-spacing: 2px;        
    }
    &__link-active {
        color: $primary !important;
        text-decoration: none !important;
        transition: color 300ms ease !important;
        padding: 2px;
        margin: 0 !important;
        // font-size: 1em;
        font-size: 15px;
        font-family: Raleway;
        text-transform: uppercase;
        font-weight: 600;
        letter-spacing: 2px;        
    }
    &__separator {
        color: black !important;
        text-decoration: none !important;
        transition: color 300ms ease !important;
        padding: 2px;
        margin: 0 !important;
        // font-size: 1em;
        font-size: 15px;
        font-family: Raleway;
        text-transform: uppercase;
        font-weight: 600;
        letter-spacing: 2px;        
    }
}


// .private-layout__crumb-wrapper::-webkit-scrollbar {
//     display: none;
//   }
