@import '../../styles/index.scss';

.conferences-manager {
    flex: 1;
    display: flex;
    flex-direction: column;

    &__header {
        // background-color: rebeccapurple;
        display: flex;
        justify-content: space-between;
        // margin: 0px 15px;
        align-items: center;
        flex: 0;

        &-title {
            display: flex;
            align-items: flex-end;
            justify-content: center;
        }
    }

    &__body {
        flex: 1;
        display: flex;
        overflow-y: hidden;

        &.show-panel2 {
            .panel1 {
                flex: 1;
                flex-direction: column;
            }

            .panel2 {
                flex: 1;
                display: flex;
            }

        }

    }

    &__calendar {
        width: 100%;
        // background-color: aquamarine;
    }

    &__list {
        width: 100%;
    }

    .panel1 {
        flex: 1;
        flex-basis: 0;
        display: flex;
        flex-direction: row;
        // background-color: aqua
    }

    .panel2 {
        display: none;
        flex: 0;
        flex-basis: 0;
        // background-color: bisque
            /* Styles for the right column */
            // background-color:rgb(255, 156, 230)
    }


}


.conference-item {
    list-style-type: none;
    background-color: white;
    border: 1px solid $gray-20;
    border-radius: 5px;
    margin: 15px;
    padding: 10px;

    &-active {
        background-color: $primary-20 !important;
        list-style-type: none;
        border: 1px solid $gray-20;
        border-radius: 5px;
        margin: 15px;
        padding: 10px;
    }

    &__list {
        list-style-type: none;
        margin: 0 !important;
        padding: 0 !important;
    }
}



.css-ew58n9-MuiButtonBase-root-MuiPickersDay-root {
    background-color: $primary !important;
}

.css-es6xc3-MuiButtonBase-root-MuiPickersDay-root {
    background-color: $primary !important;
}

.css-wc95jc-MuiButtonBase-root-MuiPickersDay-root {
    background-color: $primary !important;
}

.css-qtzvnr-MuiButtonBase-root-MuiPickersDay-root.Mui-selected {
    background-color: $primary !important;
}

.css-qtzvnr-MuiButtonBase-root-MuiPickersDay-root {
    background-color: $primary !important;
}

.css-12pcdd9 {
    background-color: $primary !important;
}

.css-1f02vpe {
    background-color: $primary !important;
}

.css-15k18m7 {
    background-color: $primary !important;
}