@import '../../styles/index.scss';

.alert {
    margin: $space-01;
	font-size: $text-00;
	text-transform: uppercase;
	letter-spacing: 1px;
	font-family: $font-secondary;
}

.css-cpgvjg-MuiSnackbar-root{
	left: 50% !important;
    right: auto !important;
    bottom: 24px !important;
    transform: translateX(-50%) !important;
}
.css-qwe366{
	left: 50% !important;
    right: auto !important;
    bottom: 24px !important;
    transform: translateX(-50%) !important;
}