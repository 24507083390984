@import '../../styles/index.scss';

.session-browser {

    &__add-btn{
        width: 100%;
        background-color: $primary-20;
        border: 2px dashed $gray-20;
        border-radius: 5px;
        cursor: pointer;

        &:hover{
            background-color: $primary-40;
            box-shadow: $shadow;
        }
    }

    &__icon{
        color: $gray-20;
    }

    &__survey{
        // border: 1px dotted $gray-60;
        box-shadow: $shadow;
        cursor: pointer;
        padding: 0.75em;
        border-radius: 5px;
        flex: 1 1;
        margin: 0.5em 1px;
        width: 100%;

        &:hover{
            background-color: $primary-40;
        }
    }

    &__survey-active{
        border: 1px sold $primary-40;
        // box-shadow: $shadow;
        background-color: $primary-20;
        padding: 0.75em;
        border-radius: 5px;
        flex: 1 1;
        margin: 0.5em 1px;
        width: 100%;
    }


    &__surv-list{
        height: 50vh;
        overflow: scroll;

        &::-webkit-scrollbar{
            display: none;
        }
    }

}