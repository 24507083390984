@import '../../../styles/index.scss';

.create-profile {

    width: 100%;
    display: grid;
    place-content: center;
    height: 80vh;

    &__tile {
        border-radius: $radius/2;
        padding: 2em;
        overflow: scroll;
        width: 100%;
        background: $light;
        box-shadow: $shadow;
        display: flex;
        align-items: center;

        &::-webkit-scrollbar {
            display: none;
        }
    }

}

@media (max-width: 768px) {

    .create-profile {
        place-content: unset;

        &__tile {
           align-self: flex-start; 
           box-shadow: none !important;
           border-radius: 0px;
        }
    }


}