@import '../../styles/index.scss';


.custom-label {
    &--h1 {
      font-weight: 600;
      font-family: $font-primary;
      font-size: $text-05;
      letter-spacing: 1px;
    }
  
    &--h2 {
      font-weight: 600;
      font-family: $font-primary;
      font-size: $text-04;
      letter-spacing: 2px;
    }
  
    &--h3 {
      font-weight: 600;
      letter-spacing: 1px;
      font-size: $text-03;
      font-family: $font-primary;
      margin: 0 !important;
    }
  
    &--h4 {
      font-size: $text-02;
      letter-spacing: 1px;
      font-family: $font-primary;
      font-weight: 600;
    }
  
    &--h5 {
      font-size: $text-01;
      font-weight: 400;
      letter-spacing: 1px;
      font-family: $font-primary;
    }
  
    &--h6 {
      font-size: $text-00;
      font-weight: 500;
      letter-spacing: 1px;
      font-family: $font-primary;
    }
  
    &--h7 {
      font-size: $text-005;
      font-weight: 400;
      letter-spacing: 1px;
      font-family: $font-primary;
    }
  
    &--h8 {
      font-size: $text-xs;
      font-weight: 400;
      letter-spacing: 1.2px;
      font-family: $font-primary;
    }
  
    &--text {
      color: $gray;
      font-size: $text-01;
      letter-spacing: 1px;
      font-family: $font-secondary;
    }
  
    &--subtext {
      font-size: $text-xs;
      letter-spacing: 1px;
      font-family: $font-secondary;
      text-transform: uppercase;
      font-weight: 300;
    }
  
    &--100 {
      font-weight: 100 !important;
    }
  
    &--200 {
      font-weight: 200 !important;
    }
  
    &--300 {
      font-weight: 300 !important;
    }
  
    &--400 {
      font-weight: 400 !important;
    }
  
    &--500 {
      font-weight: 500 !important;
    }
  
    &--600 {
      font-weight: 600 !important;
    }
  
    &--700 {
      font-weight: 700 !important;
    }
  
    &--800 {
      font-weight: 800 !important;
    }
  
    &--900 {
      font-weight: 900 !important;
    }
  
    &--primary {
      color: $primary;
    }
  
    &--secondary {
      color: $secondary;
    }
  
    &--tertiary {
      color: $tertiary;
    }
  
    &--defualt {
      color: $text-col;
    }
  
    &--ghost {
      color: $gray-60;
    }
  
    &--danger {
      color: $danger;
    }
  
    &--success {
      color: $green;
    }
  
    &--warning {
      color: $warning;
    }
  
    &--white {
      color: $white;
    }
  
    &--center {
      text-align: center;
    }
  
    &--right {
      text-align: end;
    }
  
    &--left {
      text-align: start;
    }
  
    &--uppercase {
      text-transform: uppercase !important;
      letter-spacing: 2px !important;
    }
  
    &--italic {
      font-style: oblique !important;
    }

    &--justify {
      text-align: justify !important;
      text-justify: inter-word !important;
    }

    &--underline{
      text-decoration: underline;
    }
  
  }