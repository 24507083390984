@import '../../../styles/index.scss';

.study-settings {
    flex-direction: column;
    display: flex;
    flex: 1;

    &__active-tab {
        width: 100%;
        min-width: fit-content;
        // padding: 2px 7px;
        padding: 2px 12px;
        background-color: $primary-20;
        border-radius: 2px;
        margin-bottom: 1em;
        cursor: pointer;
    }

    &__tab {
        width: 100%;
        min-width: fit-content;
        // padding: 2px 7px;
        padding: 2px 12px;
        // background-color: $gray-20;
        border-radius: 2px;
        margin-bottom: 1em;
        cursor: pointer;
    }

    &__treatment-header {
        display: flex;
        align-items: center;
    }

}