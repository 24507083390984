@import '../../styles/index.scss';

.report__thead-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.report {

  &__header {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: space-evenly;
    background-color: $primary-10;
    padding: 1em;
    border-radius: 5px;

    &__thead-wrapper {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &__wrapper {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      // background-color: red;
      width: 100%;
    }

    &__title {
      position: relative;
      top: -12px;
      left: -10px;
      // background-color: red;
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__body {
    // position: relative;
    // max-height: 200px;
    // overflow-y: auto;
  }

  &__table-container {}

  &__table-header {}

  &__table-content2 {
    max-height: 100px;
    /* Set the maximum height for the table container */
    overflow-y: auto;
    /* Enable vertical scrolling for overflowing content */
    background-color: cyan;
  }
}

.filter {

  &__wrapper {
    display: flex;
    align-items: center;
    min-width: fit-content;
  }

  &__active {
    background-color: transparent;
    border: none;
    padding: 0;
    margin: 0;
    font-size: $text-00;
    border-radius: $radius-pill;
    font-family: $font-primary;
    border-style: none;
    transition: background-color 300ms ease;
    font-weight: 600;
    text-transform: capitalize;
    color: $primary;
    letter-spacing: 1px;
    text-transform: uppercase;
  }

  &__btn {
    background-color: transparent;
    border: none;
    padding: 0;
    margin: 0;
    font-size: $text-00;
    border-radius: $radius-pill;
    font-family: $font-primary;
    border-style: none;
    cursor: pointer;
    transition: background-color 300ms ease;
    font-weight: 600;
    text-transform: capitalize;
    letter-spacing: 1px;
    text-transform: uppercase;
  }

  &__spacer {
    font-size: $text-01;
    font-family: $font-secondary;
    font-weight: 200;
    margin: 0 5px;
    letter-spacing: 1px;
  }

}

.report-table {
  background: white;
  width: 100%;
  border-collapse: collapse;
  overflow: hidden;
}

.report-table thead {
  position: sticky;
  top: 0;
  background-color: $background;
  z-index: 5;
}

.report-table tbody {
  display: block;
  overflow-y: auto;
  max-height: 586px;
}

.report-table tr {
  display: table;
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
}

.report-table td,
.report-table th {
  text-align: center;
  padding: .75rem .5rem;
  text-align: left;
}

.report-table__row:hover {
  background-color: $primary-20;
}

.report-table th {
  height: 100%;
  font-family: $font-primary;
  font-size: $text-00;
  text-transform: uppercase !important;
  font-weight: 400;
  letter-spacing: 1px;
  border-bottom: 3px solid $primary-20;
}

.report-table td {
  font-family: $font-secondary;
  font-size: .875em;
  font-weight: 300;
  letter-spacing: 1px;
  cursor: pointer;
  border-bottom: 1px solid $primary-20;
}


// .report-table {

//   // overflow: scroll;
//   background: white;
//   border-spacing: 0px;
//   table-layout: fixed;
//   min-width: 100%;
//   padding: 0 $space-03;
//   padding-bottom: 10px;
//   width: 100%;
//   border-collapse: collapse;


//   thead{
//     position: sticky;
//     top: 0;
//     background-color: $background;
//     z-index: 5;
//   }

//   tbody {
//     display: block;
//     width: 100%;
//   }

//   td,
//   th {
//       // width: 7rem;
//       // border: 2px solid white;
//       text-align: center;
//       overflow: hidden;
//       padding: .75rem .5rem;
//       text-align: left;
//   }

//   &__row:hover {
//       background-color: $primary-20;
//   }

//   th {
//       height: 100%;
//       font-family: $font-primary;
//       font-size: $text-00;
//       text-transform: uppercase !important;
//       font-weight: 400;
//       letter-spacing: 1px;
//       border-bottom: 3px solid $primary-20;

//   }

//   td {
//       font-family: $font-secondary;
//       font-size: .875em;
//       font-weight: 300;
//       letter-spacing: 1px;
//       cursor: pointer;
//       border-bottom: 1px solid $primary-20;
//   }


// }


.SUV {

  &__yellow {
    background-color: $yellow-40;
    padding: 3px;
    text-align: center;
    border-radius: $radius-pill;
  }

}



.report__table-content::-webkit-scrollbar {
  display: none;
}

