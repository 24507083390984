@import '../../../styles/index.scss';

.downloadable-file {

    &__wrapper {
        border-radius: 5px;
        // max-width: 200px;
        border: 1px dashed $gray-40;
        padding: 6px 12px;
        cursor: pointer;
        background-color: $background;
        // padding: $space-03;
        // border-top: 1px solid $light-gray;
        // border-bottom: 1px solid $light-gray;
        margin-top: 4px;
        // padding: $space-01 $space-03 $space-03 $space-01;
        // box-shadow: $shadow;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        overflow: hidden;
        // background-color: gold;

    }
}