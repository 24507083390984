@import '../../../styles/index.scss';

.add-participant {

    &__tile{
        padding: 1em ;
        background-color: $white ;
        box-shadow: $shadow;
        margin-bottom: .5em;
        width: 100%;
        cursor: pointer;
    }

    &__tile:hover{
        background-color: $primary-10 ;
    }

    // &__body{
    //     height: 100%;
    //     width: 100%;
    //     display: flex;
    //     align-items: center;
    //     flex-direction: column;
    //     justify-content: space-between;
    // }

    &__patient {
        padding: 10px;
    }

    &__patient-selected {
        padding: 10px;
        background-color: $primary-10 !important;
    }
}