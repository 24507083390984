@import '../../styles/index.scss';

.shield-icon {

}

.shield-link {
    pointer-events: auto;
}
  
.shield-image {
    pointer-events: none;
}