@import '../../../styles/index.scss';

.patient-dashboard {

    width: 100%;
    display: flex;
    flex-direction: column;
    transition: flex-basis 5s ease;
    padding: 0 12px;
    overflow: hidden;
    // height: $workspace_vh;
    flex: 1;

}

.default-dashboard {
    height: 90vh;
    width: 100%;
    display: grid;
    place-content: center;
}