$phone: 37.438em; // 599px
$phablet: 37.5em; //600px
$tablet: 60em; // 900px
$laptop: 75em; // 1200px
$desktop: 90em; // 1440px

$landscape: '(orientation: landscape)';

$phone-only: '(max-width: #{$phone})';
$phablet-only: '(min-width: #{$phablet}) and (max-width: #{$tablet})';
$tablet-only: '(min-width: #{$tablet}) and (max-width: #{$laptop})';
$laptop-only: '(min-width: #{$laptop}) and (max-width: #{$desktop})';

$up-to-phablet: '(max-width: #{$phablet})';
$up-to-tablet: 'max-width: #{$tablet}';
$up-to-laptop: '(max-width: #{$laptop})';
$up-to-desktop: '(max-width: #{$desktop})';

$phablet-up: '(min-width: #{$phablet})';
$tablet-up: '(min-width: #{$tablet})';
$laptop-up: 'min-width: #{$laptop}';
$desktop-up: '(min-width: #{$desktop})';

// fonts
$font-primary: 'Open-Sans', sans-serif !important;
$font-secondary: "Roboto", sans-serif !important;


/*
 *  Font Sizes
 */
$text-07: 3.375em;
$text-06: 2.5em;
$text-05: 2.1em;
$text-04: 1.8em;
$text-03: 1.6em;
$text-02: 1.3em;
$text-01: 1.15em;
$text-00: 0.95em;
$text-xs: 0.75em;
$text-005: 0.25em;

// $text-07: 3.375em;
// $text-06: 2.625em;
// $text-05: 2em;
// $text-04: 1.75em;
// $text-03: 1.5em;
// $text-02: 1.25em;
// $text-01: 1em;
// $text-00: 0.9em;
// $text-005: 0.8em;
// $text-xs: 0.7em;

// $text-07: 3.37vw;
// $text-06: 2.3vw;
// $text-05: 2vw;
// $text-04: 1.75vw;
// $text-03: 1.5vw;
// $text-02: 1.25vw;
// $text-01: 1vw;
// $text-00: 0.875vw;
// $text-xs: 0.65vw;

/*
 *  SPACING
 */
$space-15: 4rem;
$space-14: 3.75rem;
$space-13: 3.5rem;
$space-12: 3.25rem;
$space-11: 3rem;
$space-10: 2.75rem;
$space-09: 2.5rem;
$space-08: 2.25rem;
$space-07: 2rem;
$space-06: 1.75rem;
$space-05: 1.5rem;
$space-04: 1.25rem;
$space-03: 1rem;
$space-02: 0.75rem;
$space-01: 0.5rem;
$space-00: 0.25rem;


//
$page-padding: 1em 8%;


$thin       : 200;
$regular    : 300;
$semibold   : 400;
$bold       : 600;




$margin-02: $space-04;
$margin-01: $space-03;
$margin-00: $space-02;
/*
 *  UI
 */
$radius-sm: 5px;
$radius: 50px;
$radius-pill: 50px;

$workspace_vh: 82vh;
