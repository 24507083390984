@import '../../styles/index.scss';

.spacer {

    &--5{
        padding-right: 5px;
        padding-top: 5px;
    }
    
    &--10{
        padding-right: 10px;
        padding-top: 10px;
    }

    &--15{
        padding-right: 15px;
        padding-top: 15px;
    }

    &--20{
        padding-right: 20px;
        padding-top: 20px;
    }
    
    &--30{
        padding-right: 30px;
        padding-top: 30px;
    }

}