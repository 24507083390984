@import '../../../styles/index.scss';


.document-drive {

    &__header {
        display: flex;
        align-items: center;
    }

    &__icon-btn {
        color: #094ea5;
        cursor: pointer !important;
        background-color: transparent !important;
        border: none !important;
        z-index: 100;
        pointer-events: auto;
    }

}

.file {

    &__item {
        margin: 8px 0px 0px 0px;
        // background-color: $primary-05;
        border: 1px dashed $primary-20;
        padding: 3px 6px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        display: flex;
    }

    &__btn{
        width: 100%;
    }
}