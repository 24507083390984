@import '../../styles/index.scss';

.session-list-item {

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    &__body {
        border: 1px dotted $gray-60;
        box-shadow: none;
        padding: 0.75em;
        border-radius: 5px;
        flex: 1 1;
        margin: 0.5em 1px;
        display: flex;
        align-items: flex-start;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
    }
    &__body-active {
        border: 1px dotted $primary-60;
        box-shadow: none;
        padding: 0.75em;
        border-radius: 5px;
        flex: 1 1;
        margin: 0.5em 1px;
        display: flex;
        align-items: flex-start;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        box-shadow: $shadow;
        background-color: $primary-10;
    }


    &__indicator {
        flex: 0 1;
        padding-right: 0.75em;
    }

}