@import '../../../styles/index.scss';

.hives {

    padding: 1em 8%;
    flex-direction: column;
    display: flex;
    flex: 1;
    &__header {
        &-title {
            display: flex;
            min-width: fit-content;
        }

        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        padding: 0 1rem;
    }

    &__empty {
        height: 50vh;
        display: grid;
        place-content: center;
        width: 100%;
    }


}

.hives__empty {
    height: 50vh;
    display: grid;
    place-content: center;
    width: 100%;
}

.hive-item {
    display: grid;
    grid-template-columns: 1fr minmax(150px, 15%);
    padding: 0.5rem;

    &__badge {
        height: 100%;
        margin: auto 1em;
        display: flex;
        align-items: center;
    }
}