@import '../../../styles/index.scss';

.patient-overview {

    width: 100%;
    display: flex;
    flex-direction: column;
    transition: flex-basis 5s ease;
    padding: 0px 12px;
    overflow: hidden;
    // height: $workspace_vh;
    flex: 1;

    &__body {
        display: flex;
        flex-direction: row;
        transition: flex-basis 5s ease;
        overflow: hidden;
        padding: 0px 10%;
        width: 100%;
        // height: $workspace_vh;
        flex: 1;

        .panel1 {
            flex: 1;
            flex-basis: 0;
            padding: 5px;
        }

        .panel2 {
            flex: 2;
            flex-basis: 0;
            padding: 5px;
        }

    }

    &__info-header {
        display: flex;
        align-items: center;
    }

    &__ul {
        margin: 0px !important;
        padding: 0px 10px !important;
        list-style-type: none;
        letter-spacing: .5px;
        text-transform: uppercase;
        font-weight: 500;
        line-height: 20px;
        // font-size: $text-00;
        // color: $gray;

        li {
            margin: 10px 0;
        }
    }

    &__path-item {
        border: 1px dotted $gray-60;
        box-shadow: none;
        padding: 0.75em;
        border-radius: 5px;
        flex: 1 1;
        margin: 0.5em 1px;
        cursor: pointer;
        pointer-events: auto;

        &-header{
            display: flex;
            width: 100%;
            align-items: center;
            justify-content: space-between;
        }

        &:hover{
            box-shadow: $shadow;
        }

        &-selected {
            border: 1px dotted $gray-60;
            background-color: $primary-10;
            box-shadow: none;
            padding: 0.75em;
            border-radius: 5px;
            flex: 1 1;
            margin: 0.5em 1px;
            cursor: pointer;
        }
    }
}

.btn-actve {
    border: none;
    background-color: transparent;
    padding: 5px 10px;
    border-radius: $radius-pill;
    cursor: pointer;

    &:hover{
        background-color: $green-20;
        
    }
}
.btn-inactive {
    border: none;
    background-color: transparent;
    padding: 5px 10px;
    border-radius: $radius-pill;
    cursor: pointer;

    &:hover{
        background-color: $gray-20;
    }
}