@import '../../../styles/index.scss';

.audit {
    display: flex;
    flex-direction: column;
    flex: 1;
    max-width: 99vw;
    padding-top: 1px;
    overflow-y: hidden;

    &__add-btn {
        height: 70px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $secondary-20;
        border: 1px solid $secondary-40;
    }

    &__body {
        padding-left: 1em;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;
        transition: flex-basis 5s ease;
        overflow: hidden;
        flex: 1;

        &-panel1 {
            flex: 1;
            display: flex;
            flex-direction: column;

            
            width: 100%;

        }

        &-aside {
            // flex-basis: 0;
            flex-basis: 300px;
            overflow: scroll;
            margin-bottom: 15px;
        }

        // background-color: aqua;
        // display: grid;
        // grid-template-columns: minmax(150px, 25%) 1fr;
    }

    &__audit-action-item {
        background-color: $primary-60;
        align-items: center;
    }

    &__header {
        padding: 0px calc(1em + 15px);
        display: flex;
        align-items: center;
        // justify-content: space-between;
        width: 100%;

        &-badge {
            max-width: 300px;
            margin-left: 10px;
        }
    }



}

.audit__body-aside::-webkit-scrollbar {
    display: none;
}