@import '../../styles/index.scss';

.protocol-action-bar {
    // width: 100%;
    // display: grid;
    // place-content: center;
    // margin: 0 1em;
    // display: flex;
    // flex-direction: column;
    // align-items: center;
    // justify-content: flex-start;
    margin-left: -5px;
    display: flex;


    &__unlock {
        color: $gray !important;
    }


    &__tile {
        padding: 0 3em;
        // background-color: $primary-10;
        // border-radius: 5px;
    }

    &__item {
        display: flex;
        // justify-content: center;
        align-items: center;
        justify-content: flex-end;
    }

    &__btn {
        border: none;
        background-color: transparent;
        cursor: pointer;
        display: flex;
        align-items: center;
    }

    &__btn-blue {
        border: 2px solid $secondary;
        // border: none;
        background-color: white;
        border-radius: 50%;
        height: 35px;
        width: 35px;
        margin-right: 3px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }

    &__btn-red {
        border: 2px solid $danger;
        // border: none;
        background-color: white;
        border-radius: 50%;
        height: 35px;
        width: 35px;
        margin-right: 3px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }

    &__btn-or {
        border: 2px solid orange;
        // border: none;
        background-color: white;
        border-radius: 50%;
        height: 35px;
        width: 35px;
        margin-right: 3px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }
    &__btn-gr {
        border: 2px solid $gray;
        // border: none;
        background-color: white;
        border-radius: 50%;
        height: 35px;
        width: 35px;
        margin-right: 3px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        &:hover{
            background-color: $gray-40;
        }
    }

    &__btn-green {
        border: 2px solid $green;
        // border: none;
        background-color: white;
        border-radius: 50%;
        height: 35px;
        width: 35px;
        margin-right: 3px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }

    &__missing {
        color: orange !important;
        // margin-right: 5px;
    }

    &__reject {
        color: orange !important;
        // margin-right: 5px;
    }

    &__aprove {
        color: $green !important;
        // margin-right: 5px;
    }

    &__missing-active {
        color: orange !important;
        // margin-right: 5px;
    }

    &__deviation {
        color: $red !important;
        // margin-right: 5px;
    }

    &__deviation-active {
        color: $red !important;
        // margin-right: 5px;
    }
}