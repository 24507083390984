@import '../../../styles/index.scss';

.nav-bar {
    height: 20px;
    z-index: 10;

    .menu {
        display: flex;
        font-size: large;
        list-style: none;
        max-width: 1200px;
        margin: 0 auto;
        padding: 10px 20px;
    }
}