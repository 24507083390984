@import '../../../styles/index.scss';

.home {

    flex-grow: 1;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    padding: 10%;
    /* Add some space around the panels */

    .wrapper {
        display: flex;
        justify-content: center;
        width: 100%;
        height: 100%;
    }

    .panel1 {
        width: 50%;
        /* Take up 50% of the container width */
        box-sizing: border-box;
    }

    .panel2 {
        width: 50%;
        /* Take up 50% of the container width */
        box-sizing: border-box;
    }

}

@media (max-width: 768px) {

    .home {
        padding: 10px;
        align-items: normal;

        .wrapper {
            flex-direction: column;
            /* Stack panels vertically */
            width: 100%;
        }
    
        .panel1 {
            // display: none;
            width: 100% !important;

            /* Hide panel1 */
        }
    
        .panel2 {
            width: 100% !important;
            /* Take up full width */
        }
    }

}
