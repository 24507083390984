@import '../../../styles/index.scss';

.file-tree-item {
    overflow: hidden;

    &--wrapper{
        // background-color: $background ;
        // display: flex;
        // flex-direction: row;
        // justify-content: space-between;
        // margin-top: 4px;
        // padding: 1px 5px 1px 5px;
        
        // // box-shadow: $shadow;
        border-radius: 5px;
        border: 1px dashed $gray-40;
        padding: 6px 12px;
        cursor: pointer;
        background-color: $background;
        // padding: $space-03;
        // border-top: 1px solid $light-gray;
        // border-bottom: 1px solid $light-gray;
        margin-top: 4px;
        // padding: $space-01 $space-03 $space-03 $space-01;
        // box-shadow: $shadow;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }

    &--header{
        // background-color: blanchedalmond ;
        display: flex;
        align-items: center;
    }
    &-folder {
        // background-color: $background ;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        // margin-top: 4px;
        // padding: 1px 5px 1px 5px;
        font-weight: 600;
        cursor: pointer;
        border-radius: 5px;
        border: 1px solid $gray-40;
        padding: 6px 12px;
        cursor: pointer;
        background-color: $background;
        margin-top: 4px;
    }
    
    &-file {
        // background-color: $background ;
        // display: flex;
        // flex-direction: row;
        // justify-content: space-between;
        // margin-top: 4px;
        // padding: 1px 5px 1px 5px;
        // font-weight: 300;
        // cursor: default;
        border-radius: 5px;
        border: 1px dashed $gray-40;
        padding: 6px 12px;
        cursor: pointer;
        background-color: $background;
        // padding: $space-03;
        // border-top: 1px solid $light-gray;
        // border-bottom: 1px solid $light-gray;
        margin-top: 4px;
        // padding: $space-01 $space-03 $space-03 $space-01;
        // box-shadow: $shadow;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }

    &--filename{
        // background-color: blanchedalmond ;
        font-size: $text-00;
    }
    

    &--childrencount{
        // background-color: blanchedalmond ;
        // font-size: $text-00;
        font-weight: 600 !important;
    }
}