@import '../../styles/index.scss';

.participant-files {

    margin-right: 15px;
    flex: 1;
    &__header{
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: space-between;
    }

    &__title{
        display: flex;
        align-items: center;
    }

}

.spinner-wrapper{
    height: 300px;
    width: 100%;
    display: grid;
    place-content: center;
}