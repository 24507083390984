@import '../../../styles/index.scss';

.protocol-item {

    &-card__wrapper {
        flex: inherit;
        margin-right: 5px;
        display: flex;
        flex-direction: column;
        
    }
    &-card__header{
        display: flex;
        flex-direction: column;
        flex:0;
        
    }
    &-card__body{
        display: flex;
        flex-direction: column;
        flex:1;
        // background-color: aquamarine;
    }
    &__deviation {
        color: $danger !important;
        margin-right: 5px;
        
    }

    &__text-wrapper {
        margin-top: 20%;
    }

    &__check {
        color: $success !important;
        display: grid;
        align-content: center;
        width: 100%;
    }

    &__header_wrapper {
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;
        // background-color: aqua;
        // margin-bottom: 2em;
    }
    &__subheader_wrapper {
        display: flex;
        justify-content: space-between;
        width: 100%;
        // background-color: aqua;
        // margin-bottom: 2em;
        align-items: flex-end;

        .refalign{
            // background-color: aqua;
            display: flex;
            flex:0.35;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            .icon {
                color: $primary  !important;
                margin-right: 5px;
                // color: $danger !important;
            }
            
            .text{
                color: $primary  !important;
            }
        }
    }

    &__header_actionwrapper {
        display: flex;
        flex-direction: row;
        justify-content:space-between;
        width: 30%;
        // margin-bottom: 2em;
    }

    
    &__header_deviation {

        &__wrapper {
            display: flex;
            justify-content: space-between;
            width: 100%;
            align-items: center;
        }

        &__item {
            background-color: $red-20;
            border-radius: 5px;
            padding: .5em 1em;
            border-color: red;
            border-style: dashed;
            border-width: 1px;
        }
    }

    &__tile {
        height: 100%;
        width: 100%;
    }

    &__body {
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
    }
}

// .protocol-item-card {
//     height: 80vh;
//     overflow: scroll;

//     // &__tile{
//     //     display: grid;
//     //     grid-template-rows: auto 1fr auto;
//     //     width: 100%;
//     //     height: 100%;
//     // }
// }

.align-center {
    align-items: center;
}