@import '../../../styles/index.scss';

.met-protocol-status {
    padding: 2px;

    &__heading{
        border-bottom: .5px solid $gray;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}