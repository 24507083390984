@import '../../../styles/index.scss';

.user-profile {

    padding: 0.75rem 15%;
    display: flex;
    gap: 20px;

    .panel1{
        width: 30%;
    }

    .panel2{
        width: 70%;
    }

    &__user-tile{
        border-radius: 5px;
        padding: $space-02 $space-03;
        overflow: scroll;
        width: 100%;
        background: $light;
        box-shadow: $shadow;

        &::-webkit-scrollbar{
            display: none;
        }
    }

    &__document{
        border-radius: 5px;
        padding: $space-02 $space-03;
        overflow: scroll;
        width: 100%;
        background: $light;
        box-shadow: $shadow;
        display: flex;
        align-items: center;

        &::-webkit-scrollbar{
            display: none;
        }
    }

    &__avatar{
        background: $primary-gradient;
        height: 100px !important;
        width: 100px !important;
        font-size: 3em !important;
    }

    &__gender-wrapper{
        cursor: pointer !important;
        // background-color: aqua;
        z-index: 2;
    }

}

.user-profile__gender-wrapper {
    position: relative;
    display: inline-block;
    width: 100%;
  }
  
  .user-profile__gender-wrapper input[type="text"] {
    pointer-events: none;
  }
  
  .user-profile__gender-wrapper::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    cursor: pointer; /* Ensure the cursor changes on hover */
  }


@media (max-width: 768px) {

    .user-profile {
        padding: 10px;
        flex-direction: column;

        .panel1{
            width: 100%;
        }
    
        .panel2{
            width: 100%;
        }
    }

}
