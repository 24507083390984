@import '../../styles/index.scss';

.patient-scan-preview {

    &__center{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 50vw;
    }

}